namespace Shared


open Validation
open System

module Resorts = 

    type ResortStatus =
        | Open = 1
        | Closed = 2

    type ResortUsageInfo = 
        {
            Id                      : int
            Name                    : string
            StartingReservations    : int
            NewReservations         : int
            PrepDelayedReservations : int
            UnrepliedMessages       : int
        }

    type ResortSummary =
        {
            Id                  : int
            Name                : string
            HasPaymentMethod    : bool
            Address             : Address
            PendingReservations : int
            Rooms               : int
            Status              : ResortStatus
        }

    type AgeGroup =
        {
            AgeTo           : int
            PricePercentage : int
        }

    type ArrangementType = 
        {
            Id          : int
            Description : string
        }

    type ApplicationScope =
        {
            From    : DateTime
            To      : DateTime
        }

    type PriceListTemplate =
        {
            Id                      : int
            Name                    : string
            PriceChangePercentage   : int
            Scopes                  : ApplicationScope list
        }

    type Resort = 
        {
            Id                          : int
            OwnerId                     : int
            Name                        : string
            ShortTime                   : int
            EmptySpacePricePercentage   : int
            PrepaymentPercentage        : int
            PrepaymentWaitPeriod        : int
            AutoConfirm                 : bool
            MaxRentalTime               : int option // days
            MaxRentalAdvance            : int option // months
            Address                     : Address
            HasPaymentMethod            : bool
            Unavailabilities            : Unavailability list
            AgeGroups                   : AgeGroup list
            ArrangementTypes            : ArrangementType list
            PriceListTemplates          : PriceListTemplate list
        }
        static member Empty =
            {   Id                          = 0
                OwnerId                     = 0
                Name                        = ""
                ShortTime                   = 0
                EmptySpacePricePercentage   = 0
                PrepaymentPercentage        = 0
                PrepaymentWaitPeriod        = 0
                AutoConfirm                 = true
                MaxRentalTime               = None
                MaxRentalAdvance            = None
                HasPaymentMethod            = false
                Address                     = Address.Empty
                Unavailabilities            = []
                AgeGroups                   = []
                ArrangementTypes            = []
                PriceListTemplates          = []
            }

    type ReservationRef =
        {
            Id          : int
            Rooms       : string list
            FirstName   : string
            LastName    : string
            From        : DateTime
            To          : DateTime
            Adults      : int
        }

    type PartnerReference = 
        {
            ResortId    : int
            Name        : string
            Key         : string
        }

    let validateResort(resort: Resort) = 
        [
            yield! checkEmpty "Nazwa" "a" resort.Name |> forField "Name"
            yield! checkEmpty "Miejscowość" "a" resort.Address.City |> forField "City"
            yield! checkEmpty "Kod pocztowy" "y" resort.Address.ZipCode |> forField "ZipCode"
            yield! checkEmpty "Ulica" "a" resort.Address.Street |> forField "Street"
            yield! checkEmpty "Numer" "y" resort.Address.Number |> forField "Number"

            yield! checkPercentage "Zaliczka" resort.PrepaymentPercentage |> forField "PrepaymentPercentage"
            yield! checkPercentage "Niewykorzystane miejsce" resort.EmptySpacePricePercentage |> forField "EmptySpacePricePercentage"
            yield! checkInt "Czas wynajmu krótkoterminowego" (Some 0) None resort.ShortTime |> forField "ShortTime"

            yield!
                resort.ArrangementTypes 
                |> checkDuplicates (fun a -> a.Description) (sprintf "Element występuje wielokrotnie na liście dodatków: %s (%d)")
                |> forField "ArrangementTypes"

            yield!
                resort.Unavailabilities 
                |> checkDuplicates (fun a -> a.From.ToString("dd.MM.yyyy")) (sprintf "Kilka okresów niedostępności ma taką samą datę początkową: %s (%d)")
                |> forField "Unavailabilities"

            yield!
                resort.Unavailabilities 
                |> checkOverlaps
                    (fun u -> u.From) (fun u -> u.To) (fun u -> u.From.ToString("dd.MM.yyyy") + "-" + u.To.ToString("dd.MM.yyyy"))
                    (sprintf "Okresy niedostępności nakładają się: %s i %s")
                |> forField "Unavailabilities"

            yield! 
                resort.Unavailabilities 
                |> List.filter (fun a -> a.From >= a.To)
                |> List.map (fun a -> sprintf "Błędny okres niedostępności: %A - %A" a.From a.To)
                |> forField "Unavailabilities"

            yield!
                resort.AgeGroups
                |> checkDuplicates (fun pp -> pp.AgeTo) (sprintf "Grupa wiekowa pojawia się wielokrotnie: %A (%d)")
                |> forField "AgeGroupPolicies"

            yield!
                resort.PriceListTemplates |> List.collect (fun pt -> pt.Scopes |> List.map (fun s -> s, pt))
                |> checkOverlaps
                    (fun (s, _) -> s.From) (fun (s, _) -> s.To) (fun (s, t) -> sprintf "%s [%s - %s]" t.Name (s.From.ToString("dd.MM.yyyy")) (s.To.ToString("dd.MM.yyyy")))
                    (sprintf "Okresy obowiązywania cenników nakładają się: %s i %s")
                |> forField "PriceListTemplates"

            for pp in resort.AgeGroups do      
                yield! checkInt "Wiek dla grupy" (Some 0) None pp.AgeTo |> forField "AgeGroups"
                yield! checkPercentage (sprintf "Procent ceny dla grupy do %d lat" pp.AgeTo) pp.PricePercentage |> forField "AgeGroupPolicies"
        ]



    type IResortApi =
        {
            GetResortUsageInfos     : unit -> Async<ResortUsageInfo list>
            GetResortSummaries      : unit -> Async<ResortSummary list>
            GetResort               : int -> Async<Resort>
            ValidateReservations    : Resort -> Async<(ReservationRef * string list) list>
            SaveResort              : Resort -> Async<int>
            CanDeleteResort         : int -> Async<bool>
            DeleteResort            : int -> Async<unit>
            GetReferredResortId     : string * string -> Async<int option>
            UpdatePartnerReference  : string * string * int -> Async<unit>
            DeletePartnerReference  : string * string -> Async<unit>
        }