
namespace Shared

open System

module Calculations = 

    type Derived<'t> =
        | Uninitialised
        | Calculated of 't
        | Overwritten of 't
        | Failure of string list
    with
        member this.Value = 
            match this with 
            | Uninitialised -> failwith "Derived value was not initialised"
            | Failure msg -> failwith <| System.String.Join("\n", msg)
            | Calculated v 
            | Overwritten v -> v

        member this.HasValue =
            match this with
            | Calculated _ | Overwritten _ -> true
            | Uninitialised | Failure _ -> false

        member this.TryFailureMsg =
            match this with
            | Failure msg -> msg
            | _ -> []
        
    
    type Derived = 

        static member Map (f: 't -> 'u) (d: Derived<'t>): Derived<'u> =
            match d with
            | Uninitialised -> Uninitialised
            | Failure msg -> Failure msg
            | Calculated v -> Calculated (f v)
            | Overwritten v -> Overwritten (f v)

        static member Bind (f: 't -> Derived<'u>) (d: Derived<'t>): Derived<'u> =
            let wrap (v: Derived<'u>) (wrapper: 'u -> Derived<'u>) = 
                if v.HasValue then wrapper v.Value else v
            match d with
            | Uninitialised -> Uninitialised
            | Failure msg -> Failure msg
            | Calculated v -> wrap (f v) Calculated
            | Overwritten v -> wrap (f v) Overwritten

        static member Rewrap (f: 't -> Derived<'t>) (d: Derived<'t>): Derived<'t> =
            if d.HasValue then f d.Value else d

        static member Update (newVal: Derived<'t>) (oldVal: Derived<'t>): Derived<'t> =
            match oldVal with
            | Uninitialised
            | Failure _
            | Calculated _ -> newVal
            | Overwritten _ -> oldVal

        static member Calc(arg: Derived<'a>, f: 'a -> 't) =
            match arg with
            | Calculated a
            | Overwritten a -> Calculated (f a)
            | Uninitialised -> Uninitialised
            | Failure msg -> Failure msg

        static member Calc(arg1: Derived<'a1>, arg2: Derived<'a2>, f: 'a1 -> 'a2 -> 't) =
            if arg1.HasValue && arg2.HasValue then
                Calculated (f arg1.Value arg2.Value)
            else
                match arg1.TryFailureMsg  @ arg2.TryFailureMsg with
                | [] -> Uninitialised
                | msgs -> Failure msgs

        static member Calc(arg1: Derived<'a1>, arg2: Derived<'a2>, arg3: Derived<'a3>, f: 'a1 -> 'a2 -> 'a3 -> 't) =
            let f2 = Derived.Calc(arg1, arg2, f)
            Derived.Calc(arg3, f2, (|>))

        static member Calc(arg1: Derived<'a1>, arg2: Derived<'a2>, arg3: Derived<'a3>, arg4: Derived<'a4>, f: 'a1 -> 'a2 -> 'a3 -> 'a4 -> 't) =
            let f3 = Derived.Calc(arg1, arg2, arg3, f)
            Derived.Calc(arg4, f3, (|>))

        static member Calc(arg1: Derived<'a1>, arg2: Derived<'a2>, arg3: Derived<'a3>, arg4: Derived<'a4>, arg5: Derived<'a5>, f: 'a1 -> 'a2 -> 'a3 -> 'a4 -> 'a5 -> 't) =
            let f4 = Derived.Calc(arg1, arg2, arg3, arg4, f)
            Derived.Calc(arg5, f4, (|>))

        static member DefaultValue (dv: 't) (d: Derived<'t>): 't =
            if d.HasValue then d.Value else dv

        static member Recalc(arg: 't) =
            Derived.Update (Calculated arg)

        static member Recalc(arg: Derived<'a>, calc: 'a -> 't) =
            Derived.Update (Derived.Calc(arg, calc))

        static member Recalc(arg1: Derived<'a1>, arg2: Derived<'a2>, calc: 'a1 -> 'a2 -> 't) =
            Derived.Update (Derived.Calc(arg1, arg2, calc))

        static member Recalc(arg1: Derived<'a1>, arg2: Derived<'a2>, arg3: Derived<'a3>, calc: 'a1 -> 'a2 -> 'a3 -> 't) =
            Derived.Update (Derived.Calc(arg1, arg2, arg3, calc))

        static member Recalc(arg1: Derived<'a1>, arg2: Derived<'a2>, arg3: Derived<'a3>, arg4: Derived<'a4>, calc: 'a1 -> 'a2 -> 'a3 -> 'a4 -> 't) =
            Derived.Update (Derived.Calc(arg1, arg2, arg3, arg4, calc))

        static member Recalc(arg1: Derived<'a1>, arg2: Derived<'a2>, arg3: Derived<'a3>, arg4: Derived<'a4>, arg5: Derived<'a5>, calc: 'a1 -> 'a2 -> 'a3 -> 'a4 -> 'a5 -> 't) =
            Derived.Update (Derived.Calc(arg1, arg2, arg3, arg4, arg5, calc))

        static member IsOverwritten (value: Derived<_>) =
            match value with
            | Uninitialised
            | Failure _
            | Calculated _ -> false
            | Overwritten _ -> true

        static member ParseDecimal (s: string) =
            match Decimal.TryParse s with
            | true, value -> Overwritten value
            | false, _ -> Failure [("Nieprawidłowy format liczby: " + s)]

        static member ParseDecimal (sd: Derived<string>) =
            sd |> Derived.Bind Derived.ParseDecimal

        static member ParseInt (s: string)=
            match Int32.TryParse s with
            | true, value -> Overwritten value
            | false, _ -> Failure [ "Nieprawidłowy format liczby: " + s ]

        static member ParseInt (sd: Derived<string>)=
            sd |> Derived.Bind Derived.ParseInt

        static member ParseDateTime (s: string) =
            match DateTime.TryParse s with
            | true, value -> Overwritten value
            | false, _ -> Failure [ "Nieprawidłowy format daty: " + s ]

        static member ParseDateTime (sd: Derived<string>) =
            sd |> Derived.Bind Derived.ParseDateTime

        static member ofList (items: Derived<'t> list): Derived<'t list> =
            match items with
            | [] -> Calculated []
            | h :: t -> Derived.Calc (h, Derived.ofList t, fun h t -> h :: t)

#if !FABLE_COMPILER
    module Config = 

        open SqlFun.GeneratorConfig

        let addDerivedConversions<'t> config = 
            config
            |> addParameterConversion (function Overwritten (value: 't) -> Some value | Calculated _ | Failure _ | Uninitialised -> None)   
            |> addColumnConversion (function Some (value: 't) -> Overwritten value | None -> Uninitialised)
#endif

