namespace Commons

open System
open Fable.React
open Fable.React.Props
open Fable.Core
open Shared.Reservations
open Fable.Remoting.Client
open Thoth.Json
open Shared.ServerError
open Shared.Calculations


module Event = 

    let str disp msg (ev: Browser.Types.Event) = disp (msg ev.Value)

    let int disp msg (ev: Browser.Types.Event) = disp (msg (if ev.Value <> "" then Some (Int32.Parse ev.Value) else None))

    let dec disp msg (ev: Browser.Types.Event) = disp (msg (if ev.Value <> "" then Some (Decimal.Parse ev.Value) else None))

    let check disp msg (ev: Browser.Types.Event) = disp (msg ev.Checked)

    let date disp msg (ev: Browser.Types.Event) =
        let [| d; m; y |] = ev.Value.Split(".") |> Array.map System.Int32.Parse
        let date = System.DateTime(y, m, d)
        disp (msg date)
         
    let none disp msg _ = disp msg

    [<Emit("$('#' + $0).on($1, $2)")>]
    let addListener (id: string, eventName: string, listener: Browser.Types.Event -> unit): unit = jsNative

    let addChangeDateListener (id: string, msgBuilder: System.DateTime -> 'Msg) dispatch: unit =
        addListener (id, "changeDate", date dispatch msgBuilder)

    let addIfChangedListener (id: string, msgBuilder: bool -> 'Msg) dispatch: unit =
        addListener (id, "ifChanged", check dispatch msgBuilder)

    [<Emit("$('#' + $0).on('apply.daterangepicker', function(ev, picker) { $1(picker.startDate, picker.endDate); })")>]
    let addDateRangePickerApplyListener (id: string, listener: (DateTime * DateTime) -> unit): unit = jsNative

module Controls =

    let actionButton attrs icon dispatch msg =
        a (attrs @ [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch msg) ]) [ i [ Class icon ] [] ]

    let progressBar color percentage =
        div [ Class "progress progress-xs" ] [
            div [
                Class (sprintf "progress-bar progress-bar-%s" color)
                Style [ Width (sprintf "%d%%" percentage) ]
            ] []
        ]

    let label color text =
        span [ Class (sprintf "label label-%s" color) ] [ str text ]

    let simpleButton attrs style label icon dispatch msg =
        a (attrs @ [ Class ("btn " + style); Type "button"; OnClick (Event.none dispatch msg) ]) [
            i [ Class icon ] [ ]
            str label 
        ]

    let searchBox dispatch change submit =
        div [ Class "input-group input-group-sm hidden-xs"; Style [Width "150px"]] [
            input [
                Type "text"
                Class "form-control pull-right"
                Placeholder "Szukaj..."
                OnChange (Event.str dispatch change)
            ]
            div [ Class "input-group-btn" ] [
                button [
                    Type "submit";
                    Class "btn btn-default"
                    OnClick (Event.none dispatch submit)
                ] [
                    i [ Class "fa fa-search" ] []
                ]
            ]
        ]

    [<Emit("setTimeout(function () {
                if($('#' + $0).data('daterangepicker') == null)
                    $('#' + $0).daterangepicker({
                        //'drops': 'up',
                        'maxSpan': moment.duration(10, 'years'),
                        'singleDatePicker': $1,
                        'locale': {
                            'format': 'DD.MM.YYYY',
                            'separator': ' - ',
                            'applyLabel': 'Dodaj',
                            'cancelLabel': 'Anuluj',
                            'fromLabel': 'Od',
                            'toLabel': 'Do',
                            'customRangeLabel': 'Custom',
                            'daysOfWeek': [ 'Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob' ],
                            'monthNames': [ 'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 
                                            'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień' ],
                            'firstDay': 1
                        }
                    });                    
        });")>]
    let makeDateRangePicker (_: string, _: bool): unit = jsNative

    [<Emit("setTimeout(function () {
                if($('#' + $0).data('daterangepicker-applyinitialized') == null) {
                    $('#' + $0).on('apply.daterangepicker', function(ev, picker) {                
                        $1([picker.startDate.format('MM/DD/YYYY'), picker.endDate.format('MM/DD/YYYY')]);
                    });
                    $('#' + $0).data('daterangepicker-applyinitialized', true);
                }
            });")>]
    let addApplyDateRangePickerListener (_: string) (_: string array -> unit): unit = jsNative

    [<Emit("setTimeout(function () { $('#' + $0).on('apply.daterangepicker', function(ev, picker) {
                    $1(picker.startDate.format('MM/DD/YYYY'));
                });
            });")>]
    let addApplyDatePickerListener (_: string) (_: string -> unit): unit = jsNative

    let stringsToDates (dates: string array) =
        System.DateTime.Parse(dates.[0]), System.DateTime.Parse(dates.[1])

    let dateRangePickerButton id label dispatch msg =
        makeDateRangePicker (id, false)
        addApplyDateRangePickerListener id (stringsToDates >> msg >> dispatch)
        button [ Type "button"; Class "btn btn-default"; Id id ] [
            span [] [ i [ Class "fa fa-calendar" ] [ str <| sprintf " %s " label ] ]
            i [ Class "fa fa-caret-down"] []
        ]

    let dateRangePickerInput id (startDate: DateTime, endDate: DateTime) dispatch msg =
        makeDateRangePicker (id, false)
        addApplyDateRangePickerListener id (stringsToDates >> msg >> dispatch)
        div [ Class "input-group" ] [
            div [ Class "input-group-addon"] [
                i [ Class "fa fa-calendar" ] []
            ]
            input [
                Type "text"
                Class "form-control"
                Id id;
                Value (sprintf "%s - %s" (startDate.ToString("dd.MM.yyyy")) (endDate.ToString("dd.MM.yyyy")))
            ]
        ]

    let datePickerInput id (date: DateTime) dispatch msg =
        makeDateRangePicker (id, true)
        addApplyDatePickerListener id (System.DateTime.Parse >> msg >> dispatch)
        div [ Class "input-group" ] [
            div [ Class "input-group-addon"] [
                i [ Class "fa fa-calendar" ] []
            ]
            input [ Type "text"; Class "form-control"; Id id; Value (date.ToString("dd.MM.yyyy")) ]
        ]

    [<Emit("setTimeout(function () { $('#' + $0).timepicker({
                    showInputs: false,
                    showMeridian: false
                });
            });")>]
    let makeTimePicker (_: string): unit = jsNative

    [<Emit("setTimeout(function () { $('#' + $0).on('changeTime.timepicker', function(ev) {
            $1(ev.time.value);
                });
            });")>]
    let addChangeTimeTimePickerListener (_: string) (_: string -> unit): unit = jsNative

    [<Emit("setTimeout(function () { $('#' + $0).timepicker('setTime', $1); });")>]
    let timePickerSetTime (_: string) (_: string) = jsNative

    let timePickerInput id (time: string) dispatch msg =
        makeTimePicker id
        addChangeTimeTimePickerListener id (msg >> dispatch)
        
        div [ Class "input-group" ] [
            div [ Class "input-group-addon"] [
                i [ Class "fa fa-clock-o" ] []
            ]
            input [
                Type "text"
                Class "form-control"
                Id id
                Value time
            ]
        ]

    [<Emit("setTimeout(function () { $('#' + $0).inputmask('h:s', { 'placeholder': 'hh/mm' }); });")>]
    let makeTimeMaskedInput (_: string) = jsNative

    [<Emit("setTimeout(function () { $('#' + $0).on('change', function() {
                    $1($(this).val());
                });
            });")>]
    let addMaskedInputChangeEventHandler (_: string, _: string -> unit) = jsNative

    let timeMaskedInput id time dispatch msg =
        makeTimeMaskedInput id
        addMaskedInputChangeEventHandler (id, msg >> dispatch)
        div [ Class "input-group" ] [
            div [ Class "input-group-addon"] [
                i [ Class "fa fa-clock-o" ] []
            ]
            input [
                Type "text"
                Class "form-control"
                Data("mask", "")
                Id id
                Value time
                OnChange (Event.str dispatch msg)
                MaxLength 5.0
            ]
        ]

    [<Emit("setTimeout(function () {
        $('#' + $0).iCheck({
            checkboxClass: 'icheckbox_square-blue',
            increaseArea: '20%' 
        });
    });")>]
    let makeICheck id = jsNative

    [<Emit("setTimeout(function () { $('#' + $0).on('ifChanged', function (ev) { $1(ev.target.checked); }); })")>]
    let addIfChangedListener (_: string) (_: bool -> unit): unit = jsNative

    let checkbox id attrs label value dispatch msg =
        makeICheck id
        addIfChangedListener id (msg >> dispatch) 
        div [ Class "checkbox" ] [
            input (attrs @ [ Id id; Type "checkbox"; Checked value ])
            span [] [ str (" " + label) ]            
        ]

    [<Emit("setTimeout(function () {
        $('#' + $0).iCheck({
            radioClass: 'iradio_square-green',
            increaseArea: '20%' 
        });
    });")>]
    let makeIRadio id = jsNative

    let radioButton id label name value check dispatch msg =
        makeIRadio id
        addIfChangedListener id (fun _ -> dispatch msg)
        div [ Class "radio" ] [
            input [ Id id; Name name; Type "radio"; Value value; Checked check ]
            str label
        ]

    [<Emit("setTimeout(function () {
        if ($('#' + $0).data('wysihtml5') == null) {
            $('#' + $0).wysihtml5({
                locale: 'pl-PL',
                fa: true,
                events: {
                    change: function () {
                        $1($('.wysihtml5-sandbox').contents().find('body').html());
                    }
                }
            });            
        }
    });")>]
    let makeHtmlEditor id (handler: string -> unit) = jsNative

    [<Emit("$('.wysihtml5-sandbox').contents().find('body').html()")>]
    let getEditorVal id = jsNative

    [<Emit("setTimeout(function () {
        $('.wysihtml5-sandbox').contents().find('body').html($1);
    });")>]
    let setEditorVal id value = jsNative

    let htmlEditor id rows value handler =
        makeHtmlEditor id handler
        setEditorVal id value
        textarea [ Id id; Class "form-control"; Rows rows; ] []

    [<Emit("$0.files")>]
    let getFiles target = jsNative

    let fileUpload (onload: Browser.Types.FileList -> unit) =
        a [ Class "btn btn-file" ] [
            input [
                Type "file";
                Class "file";
                Multiple true;
                Data ("show-upload", "false");
                Data ("show-caption", "true")
                OnChange (fun (event: Browser.Types.Event) -> onload (getFiles event.target))
            ]
            i [ Class "fa fa-upload" ] []
            str " Wybierz pliki"
        ]

module React =

    let inline ofList (els: ReactElement list): ReactElement = unbox(List.toArray els)


module Auth =

    [<Emit("sjcl.codec.base64.fromBits(sjcl.hash.sha256.hash($0))")>]
    let hash (key: string) = jsNative

module Components =

    let contentHeader title subtitle =
        section [ Class "content-header" ] [
            h1 [] [
                str title
                small [] [ str subtitle ]
            ]
        ]

module Modals =

    let messageBox id title message buttons =
        div [ Class "modal fade"; Id id; Style [ Display DisplayOptions.None ] ] [
            div [ Class "modal-dialog" ] [
                div [ Class "modal-content" ] [
                    div [ Class "modal-header"] [
                        button [ Type "button"; Class "close"; Data("dismiss", "modal"); AriaLabel "Close" ] [
                            span [ AriaHidden true ] [ str "x" ]
                        ]
                        h4 [Class "modal-title"] [ str title ]
                    ]
                    div [ Class "modal-body" ] [
                        message
                    ]
                    div [ Class "modal-footer" ] buttons
                ]
            ]
        ]


    let confirm id title message dispatch msg =
        messageBox id title message [
            button [
                Type "button"
                Class "btn btn-primary pull-left"
                Data("dismiss", "modal")
                OnClick (Event.none dispatch msg)
            ] [
                str "Tak"
            ] 
            button [ Type "button"; Class "btn btn-default"; Data("dismiss", "modal") ] [str "Nie" ]
        ]

    let info id title message =
        messageBox id title message [
            button [ Type "button"; Class "btn btn-default center-block"; Data("dismiss", "modal") ] [str "OK" ]
        ]

    [<Emit("$('#' + $0).modal({ 'show': true})")>]
    let show id = jsNative

module Forms =

    let formGroup hasError controls =
        div [ Class ("form-group" + if hasError then " has-error" else "") ] controls
    
    let controlWithLabel labWidth ctrlWidth id labText errMsgs control =
        let below, rightWidth, right = errMsgs
        [
            label [ HtmlFor id; Class (labWidth + " control-label") ] [
                if not (List.isEmpty below && List.isEmpty right) then
                    yield i [Class "fa fa-times-circle-o"] []
                    yield str " "
                yield str labText
            ]
            div [ Class ctrlWidth ] (control :: below)
            div [ Class rightWidth ] right            
        ]

    let errorsBelow errors =
        [ for err in errors do
            span [ Class "help-block" ] [ str err ]     
        ],
        "",
        []

    let errorsRight errMsgWidth errors =
        [],
        errMsgWidth,
        [ for err in errors do
            span [ Class "help-block" ] [ str err ]     
        ]
        

    let inputWithLabel labWidth ctrlWidth id labText maxLen valueTag errMsgs handler =
        controlWithLabel labWidth ctrlWidth id labText errMsgs
            <| input [ Class "form-control"; Type "text"; Id id; OnChange handler; valueTag; MaxLength maxLen ]

    let passwordWithLabel labWidth ctrlWidth id labText maxLen valueTag errMsgs handler =
        controlWithLabel labWidth ctrlWidth id labText errMsgs
            <| input [ Class "form-control"; Type "password"; Id id; OnChange handler; valueTag; MaxLength maxLen ]

    let textareaWithLabel labWidth ctrlWidth rows id labText maxLen value errors handler =
        controlWithLabel labWidth ctrlWidth id labText errors
            <| textarea [ Class "form-control"; Rows rows; Id id; Value value; OnChange handler; MaxLength maxLen  ] []


    let selectWithLabel labWidth ctrlWidth id labText options value errors handler =
        controlWithLabel labWidth ctrlWidth id labText errors
            <| select [ Class "form-control"; OnChange handler ] [
                    for id, text in options do
                        option [ Value id; Selected (value = id) ] [ str text ]
                ]

    let dateRangePickerWithLabel labWidth ctrlWidth id labText value errMsgs dispatch msg =
        controlWithLabel labWidth ctrlWidth id labText errMsgs
            <| Controls.dateRangePickerInput id value dispatch msg 
                
    let timePickerWithLabel labWidth ctrlWidth id labText value errMsgs dispatch msg =
        controlWithLabel labWidth ctrlWidth id labText errMsgs
            <| Controls.timePickerInput id value dispatch msg

    let timeMaskedInputWithLabel labWidth ctrlWidth id labText value errMsgs dispatch msg =
        controlWithLabel labWidth ctrlWidth id labText errMsgs
            <| Controls.timeMaskedInput id value dispatch msg

    let checkboxWithLabel labWidth ctrlWidth id labText value errMsgs dispatch msg =
        controlWithLabel labWidth ctrlWidth id labText errMsgs
            <| Controls.checkbox id [] "" value dispatch msg


module Async =

    let Map (f: 'a -> 'b) (v: Async<'a>): Async<'b> =
        async {
            let! v' = v
            return f v'
        }

module Option =

    let FromTryParse (succeed, result) =
        match succeed with
        | true -> Some result
        | false -> None

module Decimal =

    [<Emit("$1.toFixed($0)")>]
    let format (precision: int) (value: Decimal) = jsNative

    let parseWithDefault def (s: string) = Decimal.TryParse s |> Option.FromTryParse |> Option.defaultValue def

module Int32 =

    let parseWithDefault def (s: string) = Int32.TryParse s |> Option.FromTryParse |> Option.defaultValue def

[<AutoOpen>]
module DerivedExtensions =

    type Derived with

        static member internal InternalToString (fmt: 't -> string) (dd: Derived<'t>) =
            match dd with
            | Calculated d
            | Overwritten d -> fmt d
            | Uninitialised -> "---"
            | Failure _ -> "Błąd"       

        static member ToTag format uninitInfo value =
            match value with
            | Calculated v -> Placeholder (format v)
            | Overwritten v -> Value (format v)
            | Uninitialised -> Placeholder uninitInfo
            | Failure msg -> Placeholder "Błąd"

        static member FromOption opt = opt |> Option.map Overwritten |> Option.defaultValue Uninitialised

        static member OfString (s: string) =
            if String.IsNullOrEmpty s then
                Uninitialised
            else
                Overwritten s
        
        static member DecimalToString (precision: int) (dd: Derived<Decimal>) =
            dd |> Derived.InternalToString (Decimal.format precision)

        static member ToString (dd: Derived<Decimal>) =
            dd |> Derived.InternalToString (Decimal.format 2)

        static member ToString (dd: Derived<int>) =
            dd |> Derived.InternalToString string

        static member ToString (dd: Derived<string>) =
            dd |> Derived.InternalToString id

        static member ToString (dd: Derived<DateTime>) =
            dd |> Derived.InternalToString (fun d -> d.ToString("dd.MM.yyyy"))

        static member FormatDecimal (precision: int) =
            Derived.Map (Decimal.format precision)

        static member Format (d: Decimal) =
            d |> Decimal.format 2 |> Derived.OfString

        static member Format (dd: Derived<Decimal>) =
            dd |> Derived.Map (Decimal.format 2)

module Validation =

    let isValidInt (num: string) = Int32.TryParse num |> fst
    let isValidDecimal (num: string) = Decimal.TryParse num |> fst
    let isValidTime (time: string) = DateTime.TryParse(time) |> fst

    let isValidDerived (isUnderlyingValueValid: string -> bool) (dnum: Derived<string>) =
        dnum.HasValue && isUnderlyingValueValid dnum.Value

    let checkIfValidInt label (num: string) =
        [ if not (isValidInt num) then
             yield sprintf "%s nie jest prawidłową liczbą" label            
        ]

    let checkIfValidDecimal label (num: string) =
        [ if not (isValidDecimal num) then
             yield sprintf "%s nie jest prawidłową liczbą" label            
        ]

    let checkIfValidTime label (time: string) =
        [ if not (isValidTime time) then
             yield sprintf "%s powinna mieć format hh:mm." label            
        ]

    let checkIfValidOrEmptyDecimal label (num: string) =
        [ if not (String.IsNullOrWhiteSpace num) then
            yield! checkIfValidDecimal label num
        ]

    let checkIfValidOrEmptyTime label (time: string) =
        [ if not (String.IsNullOrWhiteSpace time) then
            yield! checkIfValidTime label time
        ]

    let checkIfDerivedValidInt label (dnum: Derived<string>) =
        [ if not (dnum.HasValue && dnum.Value |> Int32.TryParse |> fst) then
            yield sprintf "%s nie jest prawidłową liczbą" label
        ]

    let checkIfDerivedValidDecimal label (dnum: Derived<string>) =
        [ if not (dnum.HasValue && dnum.Value |> Decimal.TryParse |> fst) then
            yield sprintf "%s nie jest prawidłową liczbą" label
        ]

module Reservations =

    let getStateName (state: Derived<DetailedState>) =
        match state with
        | Overwritten st
        | Calculated st ->
            match st with
            | DetailedState.New                 -> "Nowa"
            | DetailedState.Confirmed           -> "Zatwierdzona"
            | DetailedState.Prepaid             -> "Zaliczka wpłacona"
            | DetailedState.PrepaymentDelayed   -> "Opóźnienie wpłaty zaliczki"
            | DetailedState.Paid                -> "Opłacona"
            | DetailedState.StartsToday         -> "Przyjazd"
            | DetailedState.Pending             -> "W toku"
            | DetailedState.FinishesToday       -> "Wyjazd"
            | DetailedState.Completed           -> "Zakończona"
            | DetailedState.Cancelled           -> "Odwołana"
            | DetailedState.Refused             -> "Odrzucona"
            | s                                 -> sprintf "Nieprawidłowy: %A" s
        | _ -> sprintf "Nieprawidłowy: %A" state

    let getStateColor (state: Derived<DetailedState>) =
        match state with
        | Overwritten st
        | Calculated st ->
            match st with
            | DetailedState.Confirmed           -> "info"
            | DetailedState.New                 -> "success"
            | DetailedState.Prepaid             -> "info"
            | DetailedState.PrepaymentDelayed   -> "danger"
            | DetailedState.Paid                -> "info"
            | DetailedState.StartsToday         -> "warning"
            | DetailedState.Pending             -> "info"
            | DetailedState.FinishesToday       -> "info"
            | DetailedState.Completed           -> "info"
            | DetailedState.Cancelled           -> "info"
            | DetailedState.Refused             -> "info"
            | s                                 -> "danger"
        | _ -> "danger"

module Utils =

    let getMultilineMarkup (text: string) =
        text.Split('\n') |> Seq.collect (fun x -> [ br[]; str x ]) |> Seq.toList |> Seq.tail

module ErrorHandling =

    let unpack (createMsg: string * ErrorInfo -> 'm) (baseMessage: string) (ex: Exception) =
        createMsg (
            baseMessage,
            match ex with  
            | :? ProxyRequestException as ex -> 
                    match Decode.Auto.fromString<Envelope>(ex.ResponseText) with
                    | Ok { error = e } -> e
                    | Error e ->
                        JS.console.error(sprintf "Error deserialising envelope: %s" e)
                        ErrorInfo.Empty       
            | ex -> ErrorInfo.Empty)
        