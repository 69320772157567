module SidebarCommons

open Elmish
open Fable.Remoting.Client
open Shared

open Commons
open Shared.LoggedUser
open Shared.ServerError

let loggedUserApi = 
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IUserApi>

type Msg =
    | AccountDataLoaded of BasicAccountData option
    | ShowError of string * ErrorInfo
    | ChangePassword
    | UserData
    | Preferences
    | Search
    | SearchTextChanged of string

type Model =
    {
        UserName    : string
        UserIcon    : string
        IsLoggedIn  : bool
        SearchText  : string
    }
    static member NotLoggedIn =
        {   UserName = "Niezalogowany"
            UserIcon = "dist/img/avatar5.png"
            IsLoggedIn = false
            SearchText = ""
        }


let init(): Model * Cmd<Msg> =
    Model.NotLoggedIn
    , Cmd.OfAsync.either id (loggedUserApi.GetBasicAccountData()) AccountDataLoaded (ErrorHandling.unpack ShowError "Nie udało się odczytać danych użytkownika.")
        

let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
    match msg with
    | AccountDataLoaded (Some account) ->
        { model with
            UserName = account.FirstName + " " + account.LastName
            UserIcon = account.Icon |> Option.defaultValue "dist/img/avatar5.png"
            IsLoggedIn = true
        }, Cmd.none
    | AccountDataLoaded None ->
        model, Cmd.none
    | SearchTextChanged t ->
        { model with SearchText = t }, Cmd.none
    | Search ->
        model, Cmd.none

open Fable.React
open Fable.React.Props

let userPanel (model: Model) (dispatch : Msg -> unit) =
    div [ Class "user-panel"] [
        div [ Class "pull-left image" ] [
            img [ Src model.UserIcon; Class "img-circle"; Alt "User Image"]
        ]
        div [ Class "pull-left info" ] [
            p [] [ str model.UserName ]
            a [] [ i [ Class "fa fa-circle text-success"] []; str "Online" ]
        ]
    ]

let searchForm (model: Model) (dispatch : Msg -> unit) = 
    form [ Action "#"; Method "get"; Class "sidebar-form" ] [
        div [ Class "input-group" ] [
            input [
                Type "text"
                Class "form-control"
                Placeholder "Szukaj..."
                Value model.SearchText
                OnChange (Event.str dispatch SearchTextChanged)
            ]
            span [ Class "input-group-btn" ] [
                button [
                    Type "submit"
                    Name "search"
                    Class "btn btn-flat"
                    OnClick (Event.none dispatch Search)
                ] [
                    i [ Class "fa fa-search" ] []
                ]
            ]
        ]
    ]

let menuItem model name icon active dispatch msg =
    li [ if active then Class "active" ] [
        a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch msg) ] [
            i [ Class icon ] [ ]
            span [] [ str (" " + name) ]
        ]
    ]

let menuItemWithLabels model name icon active labels dispatch msg =
    li [ if active then Class "active" ] [
        a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch msg) ] [
            i [ Class icon ] [ ]
            span [] [ str (" " + name) ]
            if labels |> List.length > 0 then
                span [ Class "pull-right-container" ] labels
        ]
    ]
    
    
let submenu name icon childItems =
    li [ Class "treeview" ] [
        a [ Style [ Cursor "pointer" ] ] [
            i [ Class icon ] []; span [] [ str name ]
            span [ Class "pull-right-container" ] [
                i [ Class "fa fa-angle-left pull-right" ] []
            ]
        ]
        ul [ Class "treeview-menu" ] childItems
    ]


let view (sidebarMenu: ReactElement) (model : Model) (dispatch : Msg -> unit) =
    aside [ Class "main-sidebar" ] [
        section [ Class "sidebar" ] [
            userPanel model dispatch
            searchForm model dispatch
            sidebarMenu 
        ]
    ]


