module AccommodationBase

open System
open Elmish
open Fable.React
open Fable.React.Props
open Fable.Remoting.Client
open Commons
open Shared
open Shared.Resorts
open Shared.ServerError


let resortApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IResortApi>

module ResortDetails =

    module PriceListTemplateEditor = 

        type Msg =
            | AddPriceListTemplate
            | EditPriceListTemplate of PriceListTemplate
            | UpdatePriceListTemplate
            | CancelPriceListTemplate
            | RemovePriceListTemplate of PriceListTemplate
            | PriceListTemplateNameChanged of string
            | PriceChangePercentageChanged of string
            | AddApplicationScope of DateTime * DateTime
            | RemoveApplicationScope of ApplicationScope

        type EditedPriceListTemplate =
            {
                Id                      : int
                Name                    : string
                PriceChangePercentage   : string
                Scopes                  : ApplicationScope list
            }
            member this.IsComplete =
                not (String.IsNullOrEmpty this.Name) && Validation.isValidInt this.PriceChangePercentage && this.Scopes.Length > 0
            static member Empty =
                {
                    Id                      = 0
                    Name                    = ""
                    PriceChangePercentage   = ""
                    Scopes                  = []
                }
            static member FromTemplate (template: PriceListTemplate) = 
                {
                    Id                      = template.Id
                    Name                    = template.Name
                    PriceChangePercentage   = template.PriceChangePercentage.ToString()
                    Scopes                  = template.Scopes
                }
            static member ToTemplate (template: EditedPriceListTemplate) =
                {
                    PriceListTemplate.Id    = template.Id
                    Name                    = template.Name
                    PriceChangePercentage   = Int32.Parse template.PriceChangePercentage
                    Scopes                  = template.Scopes
                }

        type Model =
            {
                Items   : (PriceListTemplate * bool) list
                Editor  : EditedPriceListTemplate
            }
            static member Empty =
                {
                    Items = []
                    Editor = EditedPriceListTemplate.Empty
                }
            static member FromTemplateList (templates: PriceListTemplate list) =
                {
                    Items = templates |> List.map (fun t -> t, false)
                    Editor = EditedPriceListTemplate.Empty
                }
            static member ToTemplateList (model: Model) =
                model.Items |> List.map fst

        let priceListTmplLineEditor (template: EditedPriceListTemplate) dispatch buttons =
            tr [] [
                td [] [
                    input [
                        Class "form-control"
                        OnChange (Event.str dispatch PriceListTemplateNameChanged)
                        Value template.Name
                        MaxLength 80.0
                    ]
                ]
                td [] [
                    input [
                        Class "form-control"
                        OnChange (Event.str dispatch PriceChangePercentageChanged)
                        Value template.PriceChangePercentage
                        MaxLength 4.0
                    ]
                ]
                td [] [
                    Controls.dateRangePickerInput "addApplicationScope" (DateTime.Today, DateTime.Today) dispatch AddApplicationScope
                    for s in template.Scopes do
                        span [] [ str <| sprintf "%s - %s" (s.From.ToString("dd.MM.yyyy")) (s.To.ToString("dd.MM.yyyy")) ]
                        str " "
                        Controls.actionButton [] "fa fa-times" dispatch (RemoveApplicationScope s)
                        br []                
                ]
                td [] buttons
            ]

        let view model dispatch =
            table [ Class "table" ] [
                tbody [] [
                    tr [] [
                        th [] [ str "Nazwa" ]
                        th [] [ str "Zmiana ceny" ]
                        th [] [ str "Obowiązywanie" ]
                        th [] []
                    ]
                    for pt, isEdited in model.Items do
                        if isEdited then
                            priceListTmplLineEditor model.Editor dispatch [
                                Controls.actionButton [ Disabled (not model.Editor.IsComplete) ] "fa fa-check" dispatch UpdatePriceListTemplate
                                str " "
                                Controls.actionButton [] "fa fa-ban" dispatch CancelPriceListTemplate
                            ]
                        else
                            tr [] [
                                td [] [ str pt.Name ]
                                td [ Style [TextAlign TextAlignOptions.Right ] ] [                            
                                    str (if pt.PriceChangePercentage < 0
                                         then pt.PriceChangePercentage.ToString() + "%"
                                         else "+" + pt.PriceChangePercentage.ToString() + "%")
                                ]
                                td [] [
                                    for s in pt.Scopes do
                                        span [] [ str <| sprintf "%s - %s" (s.From.ToString("dd.MM.yyyy")) (s.To.ToString("dd.MM.yyyy")) ]
                                        br []
                                ]
                                td [] [
                                    Controls.actionButton [] "fa fa-edit" dispatch (EditPriceListTemplate pt)
                                    str " "
                                    Controls.actionButton [] "fa fa-times" dispatch (RemovePriceListTemplate pt)
                                ]
                            ]
                    if model.Items |> List.forall (snd >> not) then
                        priceListTmplLineEditor model.Editor dispatch [
                            Controls.actionButton [ Disabled (not model.Editor.IsComplete) ] "fa fa-plus" dispatch AddPriceListTemplate 
                        ]
                ]
            ]

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | PriceChangePercentageChanged pc ->
                { model with Editor = { model.Editor with PriceChangePercentage = pc } }, Cmd.none
            | PriceListTemplateNameChanged name ->
                { model with Editor = { model.Editor with Name = name } }, Cmd.none
            | AddApplicationScope (f, t) ->
                { model with
                    Editor = {model.Editor with Scopes = { From = f; To = t} :: model.Editor.Scopes |> List.sort }
                }, Cmd.none
            | RemoveApplicationScope s ->
                { model with
                    Editor = { model.Editor with Scopes = model.Editor.Scopes |> List.filter ((<>) s) }
                }, Cmd.none
            | AddPriceListTemplate ->
                if model.Editor.IsComplete then
                    { model with
                        Items = model.Items @ [ model.Editor |> EditedPriceListTemplate.ToTemplate, false]
                        Editor = EditedPriceListTemplate.Empty
                    }, Cmd.none
                else
                    model, Cmd.none
            | RemovePriceListTemplate pt ->
                { model with Items = model.Items |> List.filter (fst >> (<>) pt) }, Cmd.none
            | EditPriceListTemplate pt ->
                { model with
                    Items = model.Items |> List.map (fun (t, _) -> t, t = pt)
                    Editor = pt |> EditedPriceListTemplate.FromTemplate
                }, Cmd.none
            | UpdatePriceListTemplate ->
                if model.Editor.IsComplete then
                    { model with
                        Items = 
                            model.Items
                            |> List.map (function
                                | (t, true) -> model.Editor |> EditedPriceListTemplate.ToTemplate, false
                                | (t, false) -> t, false)
                        Editor = EditedPriceListTemplate.Empty                    
                    }, Cmd.none
                else
                    model, Cmd.none
            | CancelPriceListTemplate ->
                { model with
                    Items = model.Items |> List.map (fun (t, _) -> t, false)
                    Editor = EditedPriceListTemplate.Empty
                }, Cmd.none


    module UnavailabilityEditor = 

        type Msg =
            | RemoveUnavailability of Unavailability
            | AddUnavailability
            | UnavailabilityPeriodChanged of DateTime * DateTime
            | UnavailabilityNotesChanged of string

        type EditedUnavailability =
            {
                From    : DateTime
                To      : DateTime
                Notes   : string
            }
            static member Empty = { From = DateTime.Today; To = DateTime.Today; Notes = "" }

        type Model =
            {
                Editor  : EditedUnavailability
                Items   : Unavailability list
            }
            static member Empty =
                {
                    Editor  = EditedUnavailability.Empty
                    Items   = []
                }
            static member FromUnavailabilityList unavailabilities =
                {
                    Editor  = EditedUnavailability.Empty
                    Items   = unavailabilities
                }

        let view model dispatch =
            table [ Class "table" ] [
                tbody [] [
                    tr [] [
                        th [] [ str "Od" ]
                        th [] [ str "Do" ]
                        th [] [ str "Opis" ]
                        th [] []
                    ]
                    for u in model.Items do
                        tr [] [
                            td [] [ str <| u.From.ToString("dd.MM.yyyy") ]
                            td [] [ str <| u.To.ToString("dd.MM.yyyy") ]
                            td [] [ str (u.Notes |> Option.defaultValue "") ]
                            td [] [ Controls.actionButton [] "fa fa-remove" dispatch (RemoveUnavailability u) ]
                        ]
                    tr [] [
                        td [ ColSpan 2 ] [
                            Controls.dateRangePickerInput "addUnavailability" (model.Editor.From, model.Editor.To) dispatch UnavailabilityPeriodChanged
                        ]
                        td [] [
                            input [
                                Class "form-control"
                                OnChange (Event.str dispatch UnavailabilityNotesChanged)
                                Value (model.Editor.Notes)
                                MaxLength 120.0
                            ]
                        ]
                        td [] [ Controls.actionButton [] "fa fa-plus" dispatch AddUnavailability ]
                    ]
                ]
            ]

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | RemoveUnavailability u ->
                { model with Items = model.Items |> List.filter ((<>) u) }, Cmd.none
            | AddUnavailability ->
                { model with
                    Items = {
                        Unavailability.From = model.Editor.From
                        To = model.Editor.To
                        Notes = match model.Editor.Notes with
                                | "" -> None
                                | s -> Some s
                    } :: model.Items |> List.sort
                    Editor = EditedUnavailability.Empty
                }, Cmd.none
            | UnavailabilityPeriodChanged (f, t) ->
                { model with Editor = { model.Editor with From = f; To = t } }, Cmd.none
            | UnavailabilityNotesChanged notes ->
                { model with Editor = { model.Editor with Notes = notes } }, Cmd.none


    module AgeGroupEditor = 

        type Msg =
            | AgeToChanged of string
            | PricePercentageChanged of string
            | AddAgeGroup
            | RemoveAgeGroup of AgeGroup

        type EditedAgeGroup =
            {
                AgeTo           : string
                PricePercentage : string
            }
            member this.IsIncomplete =
                not (Validation.isValidInt this.AgeTo && Validation.isValidInt this.PricePercentage)
            static member Empty = {
                AgeTo           = ""
                PricePercentage = ""
            }
    
        type Model =
            {
                Items   : AgeGroup list
                Editor  : EditedAgeGroup
            }
            static member Empty =
                {
                    Items   = []
                    Editor  = EditedAgeGroup.Empty
                }
            static member FromAgeGroupList ageGroups =
                {
                    Items   = ageGroups
                    Editor  = EditedAgeGroup.Empty
                }

        let view (model: Model) dispatch =
            table [ Class "table" ] [
                tbody [] [
                    yield tr [] [
                        th [] [ str "Wiek" ]
                        th [] [ str "Proc. ceny" ]
                        th [] []
                    ]
                    for pp in model.Items do
                        yield tr [] [
                            td [ Style [TextAlign TextAlignOptions.Right ] ] [ str <| pp.AgeTo.ToString() ]
                            td [ Style [TextAlign TextAlignOptions.Right ] ] [ str <| pp.PricePercentage.ToString() ]
                            td [] [ Controls.actionButton [] "fa fa-remove" dispatch (RemoveAgeGroup pp) ]
                        ]
                    yield tr [] [
                        td [] [
                            input [
                                Class "form-control"
                                Type "text"
                                OnChange (Event.str dispatch AgeToChanged)
                                Value model.Editor.AgeTo
                                MaxLength 2.0
                            ]
                        ]
                        td [] [
                            input [
                                Class "form-control"
                                Type "text"
                                OnChange (Event.str dispatch PricePercentageChanged)
                                Value model.Editor.PricePercentage
                                MaxLength 4.0
                            ]
                        ]                        
                        td [] [
                            Controls.actionButton [ Disabled model.Editor.IsIncomplete ] "fa fa-plus" dispatch AddAgeGroup
                        ]
                    ]
                ]
            ]

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | AgeToChanged ageTo -> { model with Editor = { model.Editor with AgeTo = ageTo } }, Cmd.none
            | PricePercentageChanged perc -> { model with Editor = { model.Editor with PricePercentage = perc } }, Cmd.none
            | AddAgeGroup ->
                if model.Editor.IsIncomplete then
                    model, Cmd.none
                else
                    { model with
                        Items = {
                            AgeGroup.AgeTo = Int32.Parse model.Editor.AgeTo
                            PricePercentage = Int32.Parse model.Editor.PricePercentage
                        } :: model.Items 
                        |> List.sortBy (fun pp -> pp.AgeTo)
                        Editor = EditedAgeGroup.Empty
                    }, Cmd.none
            | RemoveAgeGroup pp ->
                { model with Items = model.Items |> List.filter ((<>) pp) }, Cmd.none


    module ArrangementTypeEditor = 

        type Msg =
            | RemoveArrangementType of ArrangementType
            | AddArrangementType
            | UpdateArrangementType
            | CancelArrangementType
            | EditArrangementType of ArrangementType
            | ArrangementTypeDescriptionChanged of string

        type Model =
            {
                Items   : (ArrangementType * bool) list
                Editor  : string
            }
            static member Empty = { Items = []; Editor = "" }
            static member FromArrangementTypeList arrangementTypes =
                {
                    Items   = arrangementTypes |> List.map (fun a -> a, false)
                    Editor  = ""
                }
            static member ToArrangementTypeList model =
                model.Items |> List.map fst

        let view (model: Model) dispatch =
            table [ Class "table" ] [
                tbody [] [
                    tr [] [
                        th [] [ str "Nazwa" ]
                        th [] []
                    ]
                    for a, isEdited in model.Items do
                        if isEdited then
                            tr [] [
                                td [] [
                                    input [
                                        Class "form-control"
                                        Type "text"
                                        OnChange (Event.str dispatch ArrangementTypeDescriptionChanged)
                                        Value model.Editor
                                        MaxLength 180.0
                                    ]
                                ]
                                td [] [
                                    Controls.actionButton [] "fa fa-check" dispatch UpdateArrangementType
                                    str " "
                                    Controls.actionButton [] "fa fa-ban" dispatch CancelArrangementType
                                ]
                            ]
                        else
                            tr [] [
                                td [] [ str a.Description ]
                                td [] [
                                    Controls.actionButton [] "fa fa-edit" dispatch (EditArrangementType a)
                                    str " "
                                    Controls.actionButton [] "fa fa-remove" dispatch (RemoveArrangementType a)
                                ]
                            ]
                    if model.Items |> List.forall (snd >> not) then
                        tr [] [
                            td [] [
                                input [
                                    Class "form-control"
                                    Type "text"
                                    OnChange (Event.str dispatch ArrangementTypeDescriptionChanged)
                                    Value model.Editor
                                    MaxLength 180.0
                                ]
                            ]
                            td [] [
                                Controls.actionButton [] "fa fa-plus" dispatch AddArrangementType
                            ]
                        ]
                ]
            ]

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | RemoveArrangementType a ->
                { model with Items = model.Items |> List.filter (fst >> (<>) a) }, Cmd.none
            | AddArrangementType ->
                if System.String.IsNullOrEmpty model.Editor then
                    model, Cmd.none
                else
                    { model with
                        Items = ({ Id = 0; Description = model.Editor }, false) :: model.Items |> List.sortBy (fun (a, _) -> a.Description)
                        Editor = ""
                    }, Cmd.none
            | EditArrangementType a ->
                { model with
                    Items = model.Items |> List.map (fun (at, _) -> at, (at = a))
                    Editor = a.Description
                }, Cmd.none
            | CancelArrangementType ->
                { model with
                    Items = model.Items |> List.map (fun (at, _) ->at, false)
                    Editor = ""
                }, Cmd.none
            | UpdateArrangementType ->
                { model with
                    Items =
                        model.Items
                        |> List.map (function
                            | (at, true) -> { at with Description = model.Editor }, false
                            | (at, false) -> at, false)
                    Editor = ""
                }, Cmd.none
            | ArrangementTypeDescriptionChanged desc ->
                { model with Editor = desc }, Cmd.none

    type Msg =
        | ResortDetailsLoaded of Resort
        | NameChanged of string
        | CityChanged of string
        | ZipCodeChanged of string
        | StreetChanged of string
        | NumberChanged of string
        | ShortTimeChanged of string
        | PrepaymentPercChanged of string
        | PrepaymentWaitPeriodChanged of string
        | AutoConfirmChanged of bool
        | MaxRentalTimeChanged of string
        | MaxRentalAdvanceChanged of string
        | EmptySpacePricePercChanged of string
        | PriceListTemplateMsg of PriceListTemplateEditor.Msg
        | UnavailabilityMsg of UnavailabilityEditor.Msg
        | AgeGroupMsg of AgeGroupEditor.Msg
        | ArrangementTypeMsg of ArrangementTypeEditor.Msg
        | ValidateReservations
        | ReservationsValidated of (ReservationRef * string list) list
        | Save
        | ResortSaved of int
        | Close
        | ShowError of string * ErrorInfo

    
    type Address =
        {
            City            : string
            CityErrors      : string list
            Street          : string
            StreetErrors    : string list
            Number          : string
            NumberErrors    : string list
            ZipCode         : string
            ZipCodeErrors   : string list
        }

    type Model =
        {
            Id                          : int
            OwnerId                     : int
            Name                        : string
            NameErrors                  : string list
            Address                     : Address
            ShortTime                   : string
            ShortTimeErrors             : string list
            EmptySpacePricePercentage   : string
            EmptySpacePricePercErrors   : string list
            PrepaymentPercentage        : string
            PrepaymentPercentageErrors  : string list
            PrepaymentWaitPeriod        : string
            PrepaymentWaitPeriodErrors  : string list
            AutoConfirm                 : bool
            MaxRentalTime               : string
            MaxRentalTimeErrors         : string list
            MaxRentalAdvance            : string
            MaxRentalAdvanceErrors      : string list
            HasPaymentMethod            : bool
            PriceListTemplates          : PriceListTemplateEditor.Model
            PriceListTemplateErrors     : string list
            AgeGroups                   : AgeGroupEditor.Model
            AgeGroupErrors              : string list
            Unavailabilities            : UnavailabilityEditor.Model
            UnavailabilityErrors        : string list
            ArrangementTypes            : ArrangementTypeEditor.Model
            ArrangementTypeErrors       : string list
            ReferenceLink               : string
            ReservationValidation       : (ReservationRef * string list) list
            PartnerKey                  : (string * string ) option
        }
        static member Empty =
            {
                Id                          = 0
                OwnerId                     = 0
                Name                        = ""
                NameErrors                  = []
                Address                     = {
                    City            = ""
                    CityErrors      = []
                    Street          = ""
                    StreetErrors    = []
                    Number          = ""
                    NumberErrors    = []
                    ZipCode         = ""
                    ZipCodeErrors   = []
                }
                ShortTime                   = ""
                ShortTimeErrors             = []
                EmptySpacePricePercentage   = ""
                EmptySpacePricePercErrors   = []
                PrepaymentPercentage        = ""
                PrepaymentPercentageErrors  = []
                PrepaymentWaitPeriod        = ""
                PrepaymentWaitPeriodErrors  = []
                AutoConfirm                 = true
                MaxRentalTime               = ""
                MaxRentalTimeErrors         = []
                MaxRentalAdvance            = ""
                MaxRentalAdvanceErrors      = []
                HasPaymentMethod            = false
                PriceListTemplates          = PriceListTemplateEditor.Model.Empty
                PriceListTemplateErrors     = []
                Unavailabilities            = UnavailabilityEditor.Model.Empty
                UnavailabilityErrors        = []
                AgeGroups                   = AgeGroupEditor.Model.Empty
                AgeGroupErrors              = []
                ArrangementTypes            = ArrangementTypeEditor.Model.Empty
                ArrangementTypeErrors       = []
                ReferenceLink               = ""
                ReservationValidation       = []
                PartnerKey                  = None
            }
        static member FromPartnerKey (partnerKey: (string * string) option) =
            { Model.Empty with PartnerKey = partnerKey }
        static member FromResort (resort: Resort) =
            {
                Id          = resort.Id
                OwnerId     = resort.OwnerId
                Name        = resort.Name
                NameErrors  = []
                Address     = {
                    City            = resort.Address.City
                    CityErrors      = []
                    Street          = resort.Address.Street
                    StreetErrors    = []
                    Number          = resort.Address.Number
                    NumberErrors    = []
                    ZipCode         = resort.Address.ZipCode
                    ZipCodeErrors   = []
                }
                ShortTime                   = resort.ShortTime.ToString()
                ShortTimeErrors             = []
                EmptySpacePricePercentage   = resort.EmptySpacePricePercentage.ToString()
                EmptySpacePricePercErrors   = []
                PrepaymentPercentage        = resort.PrepaymentPercentage.ToString()
                PrepaymentPercentageErrors  = []
                PrepaymentWaitPeriod        = resort.PrepaymentWaitPeriod.ToString()
                PrepaymentWaitPeriodErrors  = []
                AutoConfirm                 = resort.AutoConfirm
                MaxRentalTime               = resort.MaxRentalTime |> Option.map string |> Option.defaultValue ""
                MaxRentalTimeErrors         = []
                MaxRentalAdvance            = resort.MaxRentalAdvance |> Option.map string |> Option.defaultValue ""
                MaxRentalAdvanceErrors      = []
                HasPaymentMethod            = resort.HasPaymentMethod
                PriceListTemplates          = PriceListTemplateEditor.Model.FromTemplateList resort.PriceListTemplates
                PriceListTemplateErrors     = []
                Unavailabilities            = UnavailabilityEditor.Model.FromUnavailabilityList resort.Unavailabilities 
                UnavailabilityErrors        = []
                AgeGroups                   = AgeGroupEditor.Model.FromAgeGroupList resort.AgeGroups 
                AgeGroupErrors              = []
                ArrangementTypes            = ArrangementTypeEditor.Model.FromArrangementTypeList resort.ArrangementTypes 
                ArrangementTypeErrors       = []
                ReferenceLink               = if resort.Id <> 0 then
                                                sprintf "https://%s/make-reservation?resort=%d" Browser.Dom.window.location.host resort.Id
                                              else
                                                ""
                ReservationValidation       = []
                PartnerKey                  = None
            }
        static member ToResort (details: Model): Resort =
            {
                Id                          = details.Id
                OwnerId                     = details.OwnerId
                Name                        = details.Name
                HasPaymentMethod            = details.HasPaymentMethod
                Address                     = {
                    City        = details.Address.City
                    Street      = details.Address.Street
                    Number      = details.Address.Number
                    ZipCode     = details.Address.ZipCode
                }
                ShortTime                   = Int32.Parse details.ShortTime
                EmptySpacePricePercentage   = Int32.Parse details.EmptySpacePricePercentage
                PrepaymentPercentage        = Int32.Parse details.PrepaymentPercentage
                PrepaymentWaitPeriod        = Int32.Parse details.PrepaymentWaitPeriod
                AutoConfirm                 = details.AutoConfirm
                MaxRentalTime               = if String.IsNullOrEmpty details.MaxRentalTime then None else Some (Int32.Parse details.MaxRentalTime)
                MaxRentalAdvance            = if String.IsNullOrEmpty details.MaxRentalAdvance then None else Some (Int32.Parse details.MaxRentalAdvance)
                Unavailabilities            = details.Unavailabilities.Items
                AgeGroups                   = details.AgeGroups.Items
                ArrangementTypes            = ArrangementTypeEditor.Model.ToArrangementTypeList details.ArrangementTypes 
                PriceListTemplates          = PriceListTemplateEditor.Model.ToTemplateList details.PriceListTemplates
            }
        static member WithErrors (errors: (string * string) list) (details: Model) =
            {
                details with
                    NameErrors                  = errors |> Validation.getErrorsOf "Name"
                    Address                     = {
                        details.Address with
                            CityErrors          = errors |> Validation.getErrorsOf "City"
                            StreetErrors        = errors |> Validation.getErrorsOf "Street"
                            NumberErrors        = errors |> Validation.getErrorsOf "Number"
                            ZipCodeErrors       = errors |> Validation.getErrorsOf "ZipCode"
                        }
                    ShortTimeErrors             = errors |> Validation.getErrorsOf "ShortTime"
                    EmptySpacePricePercErrors   = errors |> Validation.getErrorsOf "EmptySpacePricePercentage"
                    PrepaymentPercentageErrors  = errors |> Validation.getErrorsOf "PrepaymentPercentage"
                    PrepaymentWaitPeriodErrors  = errors |> Validation.getErrorsOf "PrepaymentWaitPeriod"
                    MaxRentalTimeErrors         = errors |> Validation.getErrorsOf "MaxRentalTime"
                    MaxRentalAdvanceErrors      = errors |> Validation.getErrorsOf "MaxRentalAdvance"
                    PriceListTemplateErrors     = errors |> Validation.getErrorsOf "PriceListTemplates"
                    UnavailabilityErrors        = errors |> Validation.getErrorsOf "Unavailabilities"
                    AgeGroupErrors              = errors |> Validation.getErrorsOf "AgeGroups"
                    ArrangementTypeErrors       = errors |> Validation.getErrorsOf "ArrangementTypes"
            }

    
    let view (model: Model) dispatch =    
        React.ofList [
            div [ Class "box box-info" ] [
                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Dane obiektu" ]
                ]
                form [ Class "form-horizontal"] [
                    div [ Class "box-body" ] [
    
                        Forms.formGroup (not model.NameErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-9" "name" "Nazwa" 180.0
                            (Value model.Name)
                            (Forms.errorsBelow model.NameErrors)
                            (Event.str dispatch NameChanged)
    
                        Forms.formGroup (not model.Address.CityErrors.IsEmpty || not model.Address.ZipCodeErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-5" "city" "Miejscowość" 120.0
                            (Value model.Address.City)
                            (Forms.errorsBelow model.Address.CityErrors)
                            (Event.str dispatch CityChanged)
                         @ Forms.inputWithLabel "col-sm-2" "col-sm-2" "zipCode" "Kod pocztowy" 10.0
                            (Value model.Address.ZipCode)
                            (Forms.errorsBelow model.Address.ZipCodeErrors)
                            (Event.str dispatch ZipCodeChanged)
                            
                        Forms.formGroup (not model.Address.StreetErrors.IsEmpty || not model.Address.NumberErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-5" "street" "Ulica" 180.0
                            (Value model.Address.Street)
                            (Forms.errorsBelow model.Address.StreetErrors)
                            (Event.str dispatch StreetChanged)
                         @ Forms.inputWithLabel "col-sm-2" "col-sm-2" "number" "Numer" 20.0
                            (Value model.Address.Number)
                            (Forms.errorsBelow model.Address.NumberErrors)
                            (Event.str dispatch NumberChanged)
                            
                        Forms.formGroup (not model.ShortTimeErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "shortTime" "Czas najmu krótkoterminowego (dni)" 2.0
                            (Value model.ShortTime)
                            (Forms.errorsRight "col-sm-4" model.ShortTimeErrors)
                            (Event.str dispatch ShortTimeChanged)

                        Forms.formGroup (not model.PrepaymentWaitPeriodErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "prepaymentWaitPeriod" "Czas oczekiwania na zaliczkę (dni)" 2.0
                            (Value model.PrepaymentWaitPeriod)
                            (Forms.errorsRight "col-sm-4" model.PrepaymentWaitPeriodErrors)
                            (Event.str dispatch PrepaymentWaitPeriodChanged)

                        Forms.formGroup (not model.MaxRentalTimeErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "maxRentalTime" "Maksymalny czas wynajmu (dni)" 3.0
                            (Value model.MaxRentalTime)
                            (Forms.errorsRight "col-sm-4" model.MaxRentalTimeErrors)
                            (Event.str dispatch MaxRentalTimeChanged)

                        Forms.formGroup (not model.MaxRentalAdvanceErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "maxRentalAdvance" "Maksymalne wyprzedzenie (mies.)" 2.0
                            (Value model.MaxRentalAdvance)
                            (Forms.errorsRight "col-sm-4" model.MaxRentalAdvanceErrors)
                            (Event.str dispatch MaxRentalAdvanceChanged)

                        Forms.formGroup false
                        <| Forms.checkboxWithLabel "col-sm-3" "col-sm-1" "autoConfirm" "Automatyczne zatwierdzanie nowych rezerwacji" 
                            model.AutoConfirm
                            (Forms.errorsRight "col-sm-4" [])
                            dispatch AutoConfirmChanged

                        Forms.formGroup (not model.PrepaymentPercentageErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "prepaymentPercentage" "Zaliczka (%)" 3.0
                            (Value model.PrepaymentPercentage)
                            (Forms.errorsRight "col-sm-4" model.PrepaymentPercentageErrors)
                            (Event.str dispatch PrepaymentPercChanged)
    
                        Forms.formGroup (not model.EmptySpacePricePercErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "emptySpacePricePercentage" "Opłata za niewykorzystane miejsce (%)" 3.0
                            (Value model.EmptySpacePricePercentage)
                            (Forms.errorsRight "col-sm-4" model.EmptySpacePricePercErrors)
                            (Event.str dispatch EmptySpacePricePercChanged)
    
                        Forms.formGroup (not model.AgeGroupErrors.IsEmpty)
                        <| Forms.controlWithLabel "col-sm-3" "col-sm-4" "ageGroups" "Grupy wiekowe"
                            (Forms.errorsRight "col-sm-4" model.AgeGroupErrors)
                            (AgeGroupEditor.view model.AgeGroups (AgeGroupMsg >> dispatch))

                        Forms.formGroup (not model.PriceListTemplateErrors.IsEmpty)
                        <| Forms.controlWithLabel "col-sm-3" "col-sm-8" "priceListTemplates" "Cenniki niestandardowe"
                            (Forms.errorsBelow model.PriceListTemplateErrors)
                            (PriceListTemplateEditor.view model.PriceListTemplates (PriceListTemplateMsg >> dispatch))
    
                        Forms.formGroup (not model.ArrangementTypeErrors.IsEmpty)
                        <| Forms.controlWithLabel "col-sm-3" "col-sm-4" "arrangementTypes" "Dodatkowe wyposażenie"
                            (Forms.errorsRight "col-sm-4" model.ArrangementTypeErrors)
                            (ArrangementTypeEditor.view model.ArrangementTypes (ArrangementTypeMsg >> dispatch))

                        Forms.formGroup (not model.UnavailabilityErrors.IsEmpty)
                        <| Forms.controlWithLabel "col-sm-3" "col-sm-5" "unavailabilities" "Zamknięty w dniach"
                            (Forms.errorsBelow model.UnavailabilityErrors)
                            (UnavailabilityEditor.view model.Unavailabilities (UnavailabilityMsg >> dispatch))

                        if not <| String.IsNullOrEmpty model.ReferenceLink then
                            Forms.formGroup (not model.HasPaymentMethod)
                            <| Forms.controlWithLabel "col-sm-3" "col-sm-5" "referenceLink" "Link do rezerwacji"
                                (Forms.errorsBelow
                                    [ if not model.HasPaymentMethod then
                                        "Nie wprowadzono danych do przelewu bankowego"
                                    ])
                                (if model.HasPaymentMethod then
                                    a [ Class "form-control"; Style [ Border "none" ]; Id "referenceLink"; Href model.ReferenceLink; Target "_blank" ] [ str model.ReferenceLink ]
                                 else
                                    label [ Class "control-label" ] [ str "Link do rezerwacji jest niedostępny" ])
                    ]
                ]
                div [ Class "box-footer" ] [
                    div [ Class "pull-right"] [
                        Controls.simpleButton [] "btn-primary" " Zapisz" "fa fa-save" dispatch ValidateReservations
                        str " "
                        Controls.simpleButton [] "btn-default" " Anuluj" "fa fa-times" dispatch Close
                    ]
                ]
                Modals.confirm
                    "reservationValidationInfo"
                    "Ostrzeżenie"
                    (p [] [
                        str "Po wprowadzeniu zmian następujące rezerwacje staną się błędne:"
                        table [ Class "table"] [
                            tbody [] [
                                for res, errors in model.ReservationValidation do
                                    tr [] [
                                        td [ ColSpan 4] [
                                            for room in res.Rooms do
                                                str room
                                                br []
                                        ]
                                    ]
                                    tr [] [
                                        td [] [ str (res.FirstName + " " + res.LastName) ]
                                        td [] [ str (res.From.ToString("dd.MM.yyyy")) ]
                                        td [] [ str (res.To.ToString("dd.MM.yyyy")) ]
                                        td [] [
                                            for err in errors do
                                                str err
                                                br []

                                        ]
                                    ]
                            ]
                        ]
                                           
                        str "i nie będzie można ich modyfikować bez zmiany warunków tak, aby nie pojawiały się błędy."
                        br []
                        str "Czy na pewno chcesz zapisać zmiany?"
                    ])
                    dispatch Save
            ]
        ]

    
    let getResortCmd resId =
        Cmd.OfAsync.either id (resortApi.GetResort resId) ResortDetailsLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać danych ośrodka")

    let saveResortCmd resort =
        Cmd.OfAsync.either id (resortApi.SaveResort resort) ResortSaved (ErrorHandling.unpack ShowError "Nie udało się zapisać zmian")

    let validateReservationsCmd room =
        Cmd.OfAsync.either id (resortApi.ValidateReservations room) ReservationsValidated (ErrorHandling.unpack ShowError "Nie udało się sprawdzić wpływu zmian na istniejące rezerwacje")

    let updatePartnerReferenceCmd resortId (partner, key) =
        Cmd.OfAsync.either id (resortApi.UpdatePartnerReference (partner, key, resortId)) (fun () -> Close) (ErrorHandling.unpack ShowError "Nie udało się zmienić danych partnera.")


    let validateModel (model: Model) =
        [
            yield! Validation.checkIfValidInt "Czas wynajmu krótkoterminowego" model.ShortTime |> Validation.forField "ShortTime"
            yield! Validation.checkIfValidInt "Zaliczka" model.PrepaymentPercentage |> Validation.forField "PrepaymentPercentage"
            yield! Validation.checkIfValidInt "Czas na wpłatę zaliczki" model.PrepaymentWaitPeriod |> Validation.forField "PrepaymentWaitPeriod"
            yield! Validation.checkIfValidInt "Opłata za niewykorzystane miejsce" model.EmptySpacePricePercentage |> Validation.forField "EmptySpacePricePercentage"
            if not (String.IsNullOrEmpty model.MaxRentalTime) then
                yield! Validation.checkIfValidInt "Maksymalny czas wynajmu" model.MaxRentalTime |> Validation.forField "MaxRentalTime"
            if not (String.IsNullOrEmpty model.MaxRentalAdvance) then
                yield! Validation.checkIfValidInt "Maksymalne wyprzedzenie" model.MaxRentalAdvance |> Validation.forField "MaxRentalAdvance"
        ]

    

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> = 
        match msg with
        | ResortDetailsLoaded resort -> Model.FromResort resort, Cmd.none
        | NameChanged name -> { model with Name = name }, Cmd.none
        | CityChanged city -> { model with Address = { model.Address with City = city } }, Cmd.none
        | ZipCodeChanged zipCode -> { model with Address = { model.Address with ZipCode = zipCode } }, Cmd.none
        | StreetChanged street -> { model with Address = { model.Address with Street = street } }, Cmd.none
        | NumberChanged number -> { model with Address = { model.Address with Number = number } }, Cmd.none
        | ShortTimeChanged shortTime -> { model with ShortTime = shortTime }, Cmd.none
        | PrepaymentPercChanged perc -> { model with PrepaymentPercentage = perc }, Cmd.none
        | PrepaymentWaitPeriodChanged wt -> { model with PrepaymentWaitPeriod = wt }, Cmd.none
        | MaxRentalTimeChanged mrt -> { model with MaxRentalTime = mrt }, Cmd.none
        | MaxRentalAdvanceChanged mra -> { model with MaxRentalAdvance = mra }, Cmd.none
        | AutoConfirmChanged auto -> { model with AutoConfirm = auto }, Cmd.none
        | EmptySpacePricePercChanged perc -> { model with EmptySpacePricePercentage = perc }, Cmd.none
        | PriceListTemplateMsg msg ->
            let ptModel, ptCmd = PriceListTemplateEditor.update msg model.PriceListTemplates
            { model with PriceListTemplates = ptModel }, Cmd.map PriceListTemplateMsg ptCmd
        | UnavailabilityMsg msg ->
            let unMdl, unCmd = UnavailabilityEditor.update msg model.Unavailabilities
            {model with Unavailabilities = unMdl }, Cmd.map UnavailabilityMsg unCmd
        | AgeGroupMsg msg ->
            let agMdl, agMsg = AgeGroupEditor.update msg model.AgeGroups
            { model with AgeGroups = agMdl }, Cmd.map AgeGroupMsg agMsg
        | ArrangementTypeMsg msg ->
            let atMdl, atMsg = ArrangementTypeEditor.update msg model.ArrangementTypes
            { model with ArrangementTypes = atMdl }, Cmd.map ArrangementTypeMsg atMsg
        | ValidateReservations ->
            let errors = model |> Validation.combine validateModel (Model.ToResort >> validateResort) 
            if errors.Length > 0 then
                model |> Model.WithErrors errors, Cmd.none
            else
                model, validateReservationsCmd (model |> Model.ToResort)
        | ReservationsValidated result ->
            if not result.IsEmpty then
                Modals.show "reservationValidationInfo"
                { model with ReservationValidation = result }, Cmd.none
            else
                model, Cmd.ofMsg Save
        | Save ->
            let errors = model |> Validation.combine validateModel (Model.ToResort >> validateResort)
            if errors.IsEmpty then
                model, saveResortCmd (Model.ToResort model)
            else
                model |> Model.WithErrors errors, Cmd.none
        | ResortSaved resortId ->
            model, model.PartnerKey |> Option.map (updatePartnerReferenceCmd resortId) |> Option.defaultValue (Cmd.ofMsg Close)
        | Close -> failwith "Close should be handled upper level" 
    

module ResortList = 

    type Msg =
        | ResortsLoaded of ResortSummary list
        | PartnerReferenceLoaded of int option
        | New of (string * string) option
        | Edit of int
        | Remove of int
        | RemovalPossibilityChecked of int * bool
        | RemoveConfirmed of int
        | ResortRemoved
        | ShowRooms of int * string * bool
        | UpdatePartnerReferredResort of int
        | DeletePartnerReference 
        | PartnerReferenceUpdated of int option
        | ShowError of string * ErrorInfo

    
    type ResortSummaryModel =
        {
            Id                  : int
            Name                : string
            Address             : string
            Utilisation         : int
            UtilisationColor    : string
            Status              : string
            StatusColor         : string
            HasPaymentMethod    : bool
        }
        static member FromSummary (rs: Shared.Resorts.ResortSummary) =
            let utilisation = int(Math.Round(float(rs.PendingReservations)/float(rs.Rooms) * 100.0))
            {
                Id = rs.Id
                Name = rs.Name
                Address = sprintf "%s %s, %s %s" rs.Address.Street rs.Address.Number rs.Address.ZipCode rs.Address.City
                Utilisation = utilisation
                UtilisationColor =
                    if utilisation < 25 then "red"
                    elif utilisation < 50 then "yellow"
                    elif utilisation < 75 then "blue"
                    else "green"
                Status =
                    match rs.Status with
                    | ResortStatus.Open -> "Czynny"
                    | ResortStatus.Closed -> "Zamknięty"
                    | _ -> "Nieznany"
                StatusColor = 
                    match rs.Status with
                    | ResortStatus.Open -> "success"
                    | ResortStatus.Closed -> "danger"
                    | _ -> "warning"
                HasPaymentMethod = rs.HasPaymentMethod
            }

    type Deletion =
        | ConfirmDelete of int * string
        | CanNotDelete of string
        | NoDeleteRequest


    type PartnerReference =
        {
            Partner : string
            Key     : string
            ResortId: int option
        }

    type Model =
        {
            Resorts             : ResortSummaryModel list
            PartnerReference    : PartnerReference option
            Deletion            : Deletion
        }
        static member Empty = { Resorts = []; PartnerReference = None; Deletion = NoDeleteRequest }
        static member WithSummaryList (resorts: Shared.Resorts.ResortSummary list) (model: Model) =
            { model with
                Resorts = resorts |> List.map ResortSummaryModel.FromSummary
            }
        static member FromPartnerReference (partnerRef: (string * string) option)  =
            { Model.Empty with
                PartnerReference = partnerRef |> Option.map (fun (p, k) -> { Partner = p; Key = k; ResortId = None } )
            }
        member this.PartnerKey =
            this.PartnerReference |> Option.bind (fun pr -> if pr.ResortId.IsSome then None else Some (pr.Partner, pr.Key))


    let getResortSummariesCmd =
        Cmd.OfAsync.either id (resortApi.GetResortSummaries()) ResortsLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać listy ośrodków.")

    let getReferredResortIdCmd (partner, key) =
        Cmd.OfAsync.either id (resortApi.GetReferredResortId (partner, key)) PartnerReferenceLoaded (ErrorHandling.unpack ShowError (sprintf "Nie udało się pobrać przyporządkowania do ośrodka z '%s'." partner))

    let updatePartnerReferenceCmd (partner, key, resortId) =
        Cmd.OfAsync.either id (resortApi.UpdatePartnerReference (partner, key, resortId)) (fun () -> PartnerReferenceUpdated (Some resortId)) (ErrorHandling.unpack ShowError (sprintf "Nie udało się zmienić przyporządkowania do ośrodka z '%s'." partner))

    let deletePartnerReferenceCmd (partner, key) =
        Cmd.OfAsync.either id (resortApi.DeletePartnerReference (partner, key)) (fun () -> PartnerReferenceUpdated None) (ErrorHandling.unpack ShowError (sprintf "Nie udało się usunąć przyporządkowania do ośrodka z '%s'." partner))

    let canDeleteResortCmd resortId =
        Cmd.OfAsync.either id (resortApi.CanDeleteResort resortId) (fun check -> RemovalPossibilityChecked (resortId, check)) (ErrorHandling.unpack ShowError "Nie udało się sprawdzić możliwości usunięcia ośrodka.")

    let deleteResortCmd resortId =
        Cmd.OfAsync.either id (resortApi.DeleteResort resortId) (fun () -> ResortRemoved) (ErrorHandling.unpack ShowError "Nie udało się usunąć ośrodka.")
    
    let init(partnerKey: (string * string) option): Model * Cmd<Msg> =
        Model.FromPartnerReference partnerKey,
        Cmd.batch <| getResortSummariesCmd :: (partnerKey |> Option.map (getReferredResortIdCmd) |> Option.toList)

    let view (model: Model) dispatch =    
        div [ Class "box" ] [
            div [ Class "box-header" ] [
                h3 [ Class "box-title" ] [ str "Moje obiekty" ]
                div [Class "box-tools"] [
                    let icon =
                        model.PartnerReference
                        |> Option.map (fun pr -> if pr.ResortId.IsSome then "fa fa-file-o" else "fa fa-link")
                        |> Option.defaultValue "fa fa-file-o"
                    Controls.simpleButton [] "" " Nowy" icon dispatch (New model.PartnerKey)
                ]
            ]
            div [ Class "box-body no-padding"] [
                table [ Class "table" ] [
                    tbody [] [
                        yield tr [] [
                            if model.PartnerReference.IsSome then 
                                th [ Style [ Width "5%" ] ] [ str "Powiązanie" ]
                            th [] [ str "Nazwa" ]
                            th [] [ str "Adres" ]
                            th [] [ str "Obłożenie" ]
                            th [ Style [ Width "5%"] ] []
                            th [ Style [ Width "5%"] ] [ str "Status" ]
                            th [ Style [ Width "10%"] ] [ str "" ]
                        ]
                        for r in model.Resorts do
                            yield tr [] [
                                if model.PartnerReference.IsSome then
                                    td [ Style [ TextAlign TextAlignOptions.Center ] ] [
                                        if model.PartnerReference.Value.ResortId = Some r.Id then
                                            a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch DeletePartnerReference) ] [
                                                i [ Class "fa fa-link" ] []
                                            ]
                                        else
                                            a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch (UpdatePartnerReferredResort r.Id)) ] [
                                                i [ Class "fa fa-unlink"; Style [ Opacity "50%"] ] []
                                            ]
                                    ]
                                td [] [ str r.Name ]
                                td [] [ str r.Address ]
                                td [] [ Controls.progressBar r.UtilisationColor r.Utilisation ]
                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                    str (sprintf "%d%%" r.Utilisation)
                                ]
                                td [ Style [ TextAlign TextAlignOptions.Center ] ] [
                                    Controls.label r.StatusColor r.Status
                                ]
                                td [ Style [TextAlign TextAlignOptions.Center ] ] [
                                    Controls.actionButton [ Title "Edytuj" ] "fa fa-edit" dispatch (Edit r.Id)
                                    str " "
                                    Controls.actionButton [ Title "Pokoje" ] "fa fa-bed" dispatch (ShowRooms (r.Id, r.Name, r.HasPaymentMethod))
                                    str " "
                                    Controls.actionButton [ Title "Usuń"; Data("toggle", "modal"); Data("target", "#confirmResortDelete") ] "fa fa-remove" dispatch (Remove r.Id)
                                    str " "
                                    if r.HasPaymentMethod then
                                        a [ Title "Link do rezerwacji"; Href <| sprintf "/make-reservation?resort=%d" r.Id; Target "_blank" ] [ i [ Class "fa fa-calendar" ] [] ]
                                    else
                                        span [ Style [ Color "tomato" ]; Title "Link do rezerwacji jest niedostępny.\nNie wprowadzono danych do przelewu bankowego." ] [ i [ Class "fa fa-calendar" ] [] ]
                                ]
                            ]
                    ]
                ]
            ]
            match model.Deletion with
            | ConfirmDelete (id, name) ->
                Modals.confirm
                    "confirmResortDelete" "Potwierdź"
                    (p [] [ str (sprintf "Czy na pewno chcesz usunąć ośrodek %s?" name) ])
                    dispatch (RemoveConfirmed id)
            | CanNotDelete name ->
                Modals.info
                    "confirmResortDelete"
                    "Błąd"
                    (p [] [ str (sprintf "Nie można usunąć ośrodka %s. Należy najpierw usunąć wszystkie pokoje." name) ])
            | NoDeleteRequest ->
                Modals.info "confirmResortDelete" "Info" (p [] [ str "Nie ma nic do usunięcia" ])
        ]

    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | ResortsLoaded items ->
            model |> Model.WithSummaryList items, Cmd.none
        | PartnerReferenceLoaded resortId ->
            { model with
                PartnerReference = model.PartnerReference |> Option.map (fun pr -> { pr with ResortId = resortId })
            }, Cmd.none
        | UpdatePartnerReferredResort resortId ->
            model, updatePartnerReferenceCmd (model.PartnerReference.Value.Partner, model.PartnerReference.Value.Key, resortId)
        | DeletePartnerReference ->
            model, deletePartnerReferenceCmd (model.PartnerReference.Value.Partner, model.PartnerReference.Value.Key)
        | PartnerReferenceUpdated resortId ->
            { model with
                PartnerReference = model.PartnerReference |> Option.map (fun pr -> { pr with ResortId = resortId })
            }, Cmd.none
        | Remove id ->
            model, canDeleteResortCmd id
        | RemovalPossibilityChecked (id, possible) ->
            let newModel =
                { model with
                    Deletion =
                        model.Resorts
                        |> List.tryFind (fun r -> r.Id = id)
                        |> Option.map (fun r -> if possible then ConfirmDelete (id, r.Name) else CanNotDelete r.Name)
                        |> Option.defaultValue NoDeleteRequest
                }
            newModel, Cmd.none
        | RemoveConfirmed id ->
            { model with Deletion = NoDeleteRequest }, deleteResortCmd id
        | ResortRemoved ->
            model, Cmd.batch <| getResortSummariesCmd :: (model.PartnerReference |> Option.map (fun pr -> getReferredResortIdCmd (pr.Partner, pr.Key)) |> Option.toList)
        | _ ->
            failwithf "Invalid function: %A, %A" msg model

type Msg =
    | FormMsg of ResortDetails.Msg
    | ListMsg of ResortList.Msg
    | RoomsMsg of Rooms.Msg
    | ShowError of string * ErrorInfo


type Submodel =
    | Items of ResortList.Model
    | Details of ResortDetails.Model
    | Rooms of Rooms.Model

type Model =
    {
        Submodel    : Submodel
        PartnerKey  : (string * string ) option
    }

let init(partnerKey: (string * string) option): Model * Cmd<Msg> =
    let model, cmd = ResortList.init partnerKey
    { Submodel = Items model; PartnerKey = partnerKey }, Cmd.map ListMsg cmd
    

let view (model : Model) (dispatch : Msg -> unit) =
    React.ofList [
        Components.contentHeader "Baza noclegowa" "" 
        div [ Class "content" ] [
            div [ Class "row" ] [
                div [ Class "col-xs-12" ] [
                    match model.Submodel with
                    | Items resorts -> ResortList.view resorts (ListMsg >> dispatch)
                    | Details resort -> ResortDetails.view resort (FormMsg >> dispatch)
                    | Rooms rooms -> Rooms.view rooms (RoomsMsg >> dispatch)
                ]
            ]
        ]
    ]

let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
    match msg, model.Submodel with
    | ListMsg (ResortList.New partnerKey), Items _ ->
        { model with Submodel = Details (ResortDetails.Model.FromPartnerKey partnerKey) }, Cmd.none
    | ListMsg (ResortList.Edit resId), _ ->
        { model with Submodel = Details ResortDetails.Model.Empty }, Cmd.map FormMsg (ResortDetails.getResortCmd resId)
    | ListMsg (ResortList.ShowRooms (resortId, name, hasPaymentMethod)), _ ->
        { model with Submodel = Rooms Rooms.Model.Empty },
        Cmd.map (Rooms.ListMsg >> RoomsMsg) (Rooms.RoomList.getRoomSummariesCmd { Id = resortId; Name = name; HasPaymentMethod = hasPaymentMethod })
    | RoomsMsg Rooms.Close, _ 
    | FormMsg ResortDetails.Close, _ ->
        let rlModel, rlCmd = ResortList.init model.PartnerKey
        { model with Submodel = Items rlModel }, Cmd.map ListMsg rlCmd
    | ListMsg (ResortList.ShowError (msg, err)), _
    | FormMsg (ResortDetails.ShowError (msg, err)), _ 
    | RoomsMsg (Rooms.ShowError (msg, err)), _ ->
        model, Cmd.ofMsg (ShowError (msg, err))
    | FormMsg msg, Details details ->
        let dtModel, dtMsg = ResortDetails.update msg details                                
        { model with Submodel = Details dtModel }, Cmd.map FormMsg dtMsg
    | ListMsg msg, Items items ->
        let lsModel, lsMsg = ResortList.update msg items
        { model with Submodel = Items lsModel }, Cmd.map ListMsg lsMsg
    | RoomsMsg msg, Rooms rooms ->
        let rModel, rMsg = Rooms.update msg rooms
        { model with Submodel = Rooms rModel }, Cmd.map RoomsMsg rMsg
    | _, _ ->
        failwith "Invalid function"


    
