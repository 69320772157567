namespace Shared

open System
#if !FABLE_COMPILER
open SqlFun
#endif
open Calculations
open Validation
open Resorts
open Rooms
open PaymentMethods

module Reservations =

    type OrdererData = 
        {
            OrdererId   : int option
            FirstName   : string
            LastName    : string
            Email       : string
            Phone       : string
        }
        static member Empty =
            {
                OrdererId   = None
                FirstName   = ""
                LastName    = ""
                Email       = ""
                Phone       = ""
            }

    type Arrangement = 
        {
            Type    : ArrangementType
            Units   : int
        }
    
    [<RequireQualifiedAccess>]
    type BaseState =
#if !FABLE_COMPILER
        | [<EnumValue("NEW")>] New          = 1       
        | [<EnumValue("CNF")>] Confirmed    = 2
        | [<EnumValue("PRP")>] Prepaid      = 3
        | [<EnumValue("CPL")>] Completed    = 4   
        | [<EnumValue("CNC")>] Cancelled    = 5
        | [<EnumValue("RFS")>] Refused      = 6
#else
        | New       = 1       
        | Confirmed = 2
        | Prepaid   = 3
        | Completed = 4 
        | Cancelled = 5
        | Refused   = 6
#endif

    [<RequireQualifiedAccess>]
    type DetailedState =
        | New               = 1
        | Confirmed         = 2
        | Prepaid           = 3
        | PrepaymentDelayed = 4
        | Paid              = 5
        | StartsToday       = 6
        | Pending           = 7
        | FinishesToday     = 8
        | Completed         = 9
        | Cancelled         = 10
        | Refused           = 11

    type IReservationStateComponents =
        abstract member RSFrom: DateTime
        abstract member RSTo: DateTime
        abstract member RSBaseState: BaseState
        abstract member RSPrice: Derived<decimal>
        abstract member RSPrepaymentDeadline: DateTime
        abstract member RSPayments: decimal
        abstract member RSPrepayment: Derived<decimal>


    type Payment = 
        {
            Amount: decimal
            PaidAt: DateTime
        }

    [<RequireQualifiedAccess>]
    type Party =
#if !FABLE_COMPILER
        | [<EnumValue("R")>] Resort = 1
        | [<EnumValue("G")>] Guest = 2
#else
        | Resort = 1
        | Guest = 2
#endif

    type Message =
        {
            AddedAt : DateTime
            Party   : Party
            Content : string
        }

    type Allocation =
        {
            Id              : int
            RoomId          : int
            Adults          : Derived<int>
            Children        : int list
            Arrangements    : Arrangement list
            Price           : Derived<decimal>
        }

    type Reservation = 
        {
            Id                  : int
            ResortId            : int
            Orderer             : OrdererData
            From                : DateTime
            To                  : DateTime
            Allocations         : Allocation list
            Payments            : Payment list
            Messages            : Message list
            Notes               : string
            Price               : Derived<decimal>
            PriceIsFixed        : bool
            Prepayment          : Derived<decimal>
            PrepaymentIsFixed   : bool
            PrepaymentDeadline  : DateTime
            DeclaredArrival     : DateTime option
            BaseState           : BaseState
            DetailedState       : DetailedState
        }
        static member Empty =
            {
                Id                  = 0
                ResortId            = 0
                Orderer             = OrdererData.Empty
                From                = DateTime()
                To                  = DateTime()
                Allocations         = []
                Payments            = []
                Messages            = []
                Notes               = ""
                Price               = Calculated 0m
                PriceIsFixed        = false
                Prepayment          = Calculated 0m
                PrepaymentIsFixed   = false
                PrepaymentDeadline  = DateTime()
                DeclaredArrival     = None
                BaseState           = BaseState.New
                DetailedState       = DetailedState.New
            }
        member this.Adults      = Derived.Calc(this.Allocations |> List.map (fun a -> a.Adults) |> Derived.ofList, List.sum)
        // TODO: remove after switching calculation tests to ReservationCalculationData
        interface IReservationStateComponents with
            member this.RSFrom = this.From
            member this.RSTo = this.To
            member this.RSBaseState = this.BaseState
            member this.RSPrice = this.Price
            member this.RSPrepayment = this.Prepayment
            member this.RSPrepaymentDeadline = this.PrepaymentDeadline
            member this.RSPayments = this.Payments |> List.sumBy (fun p -> p.Amount)


    type ReservationSummary = 
        {
            Id                  : int
            ResortId            : int
            Resort              : string
            Rooms               : (string * int) list
            From                : DateTime
            To                  : DateTime
            FirstName           : string
            LastName            : string
            Email               : string
            Phone               : string
            Adults              : int
            Children            : int
            Price               : decimal option
            Prepayment          : decimal option
            PrepaymentDeadline  : DateTime
            Payments            : decimal
            DeclaredArrival     : DateTime option
            BaseState           : BaseState 
            DetailedState       : Derived<DetailedState>
        }
        interface IReservationStateComponents with
            member this.RSFrom = this.From
            member this.RSTo = this.To
            member this.RSBaseState = this.BaseState
            member this.RSPrice = this.Price |> Option.map Calculated |> Option.defaultValue Uninitialised
            member this.RSPrepayment = this.Prepayment |> Option.map Calculated |> Option.defaultValue Uninitialised
            member this.RSPrepaymentDeadline = this.PrepaymentDeadline
            member this.RSPayments = this.Payments


    let calculateState (calcData: IReservationStateComponents, currentDate: DateTime) =
        Derived.Calc(calcData.RSPrice, calcData.RSPrepayment,
            fun price prepayment ->
                match calcData.RSBaseState with
                | BaseState.New ->
                    DetailedState.New
                | BaseState.Confirmed ->
                    if currentDate = calcData.RSFrom then // More important, than payment status
                        DetailedState.StartsToday
                    elif currentDate = calcData.RSTo then
                        DetailedState.FinishesToday
                    elif currentDate > calcData.RSFrom && currentDate < calcData.RSTo then
                        DetailedState.Pending
                    elif calcData.RSPayments >= price then 
                        DetailedState.Paid
                    elif calcData.RSPayments >= prepayment then
                        DetailedState.Prepaid
                    elif currentDate > calcData.RSPrepaymentDeadline then    
                        DetailedState.PrepaymentDelayed
                    else
                        DetailedState.Confirmed
                | BaseState.Prepaid ->
                    if currentDate = calcData.RSFrom then
                        DetailedState.StartsToday
                    elif currentDate = calcData.RSTo then
                        DetailedState.FinishesToday
                    elif currentDate > calcData.RSFrom && currentDate < calcData.RSTo then
                        DetailedState.Pending
                    elif calcData.RSPayments >= price then 
                        DetailedState.Paid
                    else
                        DetailedState.Prepaid
                | BaseState.Completed ->
                    DetailedState.Completed
                | BaseState.Cancelled ->
                    DetailedState.Cancelled
                | BaseState.Refused ->
                    DetailedState.Refused
                | s ->
                    failwithf "Invalid reservation state: %A" s)
        

    type ReservationCalculationData =
        {
            From                : DateTime
            To                  : DateTime
            Allocations         : Allocation list
            Payments            : Payment list
            Price               : Derived<decimal>
            Prepayment          : Derived<decimal>
            PrepaymentDeadline  : DateTime
            BaseState           : BaseState
            DetailedState       : Derived<DetailedState>
        }
        static member FromReservation (reservation: Reservation) =
            {
                From                = reservation.From
                To                  = reservation.To
                Allocations         = reservation.Allocations
                Payments            = reservation.Payments
                Price               = reservation.Price
                Prepayment          = reservation.Prepayment
                PrepaymentDeadline  = reservation.PrepaymentDeadline
                BaseState           = reservation.BaseState
                DetailedState       = Calculated reservation.DetailedState
            }
        static member UpdateReservation (reservation: Reservation) (data: ReservationCalculationData) =
            { reservation with
                Price               = data.Price
                Prepayment          = data.Prepayment
                BaseState           = data.BaseState
                PrepaymentDeadline  = data.PrepaymentDeadline
                DetailedState       = data.DetailedState.Value               
            }
        interface IReservationStateComponents with
            member this.RSFrom = this.From
            member this.RSTo = this.To
            member this.RSBaseState = this.BaseState
            member this.RSPrice = this.Price
            member this.RSPrepayment = this.Prepayment
            member this.RSPrepaymentDeadline = this.PrepaymentDeadline
            member this.RSPayments = this.Payments |> List.sumBy (fun p -> p.Amount)
        

    let updateState (date: DateTime) (rs: ReservationSummary) =
        { rs with
            DetailedState = calculateState (rs, date)
        }

    let pricingsByAge (pricings: AgeGroupPricing list, defaultPricing: AgeGroupPricing) (children: int list) = 
        [ for age in children do
            yield pricings |> List.tryFind (fun p -> p.AgeTo >= age) |> Option.defaultValue defaultPricing
        ]

    let paidChildren (children: int list, ageGroups: AgeGroupPricing list) =
        children
        |> List.filter (fun age -> ageGroups |> List.forall (fun ag -> ag.AgeTo < age || ag.ShortTimePrice.Value > 0m  || ag.LongTimePrice.Value > 0m))
        |> List.length

    let fullyPaidChildren (children: int list, ageGroups: AgeGroupPricing list) =
        children
        |> List.filter (fun age -> ageGroups |> List.forall (fun ag -> ag.AgeTo < age))
        |> List.length

    let updateOccupancies (reservation: Reservation) (room: Room) =        
        { room with
            Occupancies =
                [ for o in room.Occupancies do
                    if o.ReservationId <> reservation.Id then
                        yield o
                  for a in reservation.Allocations do
                    if a.RoomId = room.Id then
                        yield { ReservationId = reservation.Id; From = reservation.From; To = reservation.To }
                ]
                |> List.sortBy (fun o -> o.From)                
        }

    let getMaxAllocationCount (resFrom: DateTime, resTo: DateTime) (room: Room) =
        [   for u in room.Unavailabilities do
                if u.From <= resTo && u.To >= resFrom then
                    yield u.From, 1
                    yield u.To.AddDays(1.0), -1
            for o in room.Occupancies do                    
                if o.From < resTo && o.To > resFrom then
                    yield o.From.AddHours(18.0), 1
                    yield o.To.AddHours(14.0), -1
        ]
        |> List.groupBy fst
        |> List.sortBy fst
        |> List.map (snd >> List.sumBy snd)
        |> List.scan (+) 0
        |> List.max

    let getRoomAvalability (resFrom: DateTime, resTo: DateTime) (room: Room) =
        let allocs = room |> getMaxAllocationCount (resFrom, resTo)
        room.Volume - allocs 

    let roomIsAvailable (resort: Resort, resFrom: DateTime, resTo: DateTime) (room: Room) = 
        if resort.Unavailabilities |> List.exists (fun u -> u.From <= resTo && u.To >= resFrom) then
            false
        else
            let av = room |> getRoomAvalability (resFrom, resTo)
            av > 0

    let roomIsCorrectlyAllocated (resort: Resort, room: Room) (reservation: Reservation) =
        if resort.Unavailabilities |> List.exists (fun u -> u.From <= reservation.To && u.To >= reservation.From) then
            false
        else
            room |> getRoomAvalability (reservation.From, reservation.To) >= 0

    let monthDiff (d1: DateTime, d2: DateTime) =
        (d2.Month - d1.Month + 12) % 12 

    let roomById roomId (rooms: Room list) =
        rooms |> List.find (fun r -> r.Id = roomId)

    let validateReservation (resort: Resort, rooms: Room list, currentDate: DateTime) (reservation: Reservation) = 
        [
            if reservation.From >= reservation.To then
                yield "From", "Niepoprawny termin rezerwacji - data wyjazdu musi być późniejsza, niż data przyjazdu"

            if resort.MaxRentalTime |> Option.map (fun t -> t < int((reservation.To - reservation.From).TotalDays)) |> Option.defaultValue false then
                yield "From", sprintf "Zbyt długi okres wynajmu. Maksymalny czas wynajmu wynosi %d dni." resort.MaxRentalTime.Value

            if resort.MaxRentalAdvance |> Option.map (fun t -> t < monthDiff(currentDate, reservation.From)) |> Option.defaultValue false then
                yield "From", sprintf "Zbyt odległy termin rezerwacji. Maksymalne wyprzedzenie wynosi %d mies." resort.MaxRentalAdvance.Value

            for room in reservation.Allocations |> List.map (fun a -> rooms |> roomById a.RoomId) |> List.distinct do
                if not (reservation |> roomIsCorrectlyAllocated(resort, room)) then
                    yield "From", sprintf "'%s' jest niedostępny w podanym terminie" room.Name
            
            yield! checkEmpty "Imię" "e" reservation.Orderer.FirstName |> forField "FirstName"
            yield! checkEmpty "Nazwisko" "e" reservation.Orderer.LastName |> forField "LastName"
            yield! checkEmpty "Telefon" "y" reservation.Orderer.Phone |> forField "Phone"
            yield! checkEmpty "Email" "y" reservation.Orderer.Email |> forField "Email"

            for allocation, i in Seq.zip reservation.Allocations (Seq.initInfinite id) do

                for age in allocation.Children do
                    yield! checkInt "Wiek dziecka" (Some 0) None age |> forField (sprintf "Children%d" i)

                let room = rooms |> roomById allocation.RoomId

                let utilisation =
                    allocation.Adults.Value + 
                    fullyPaidChildren (
                        allocation.Children,
                        room.AgeGroupPricings @ (room.PriceLists |> List.collect (fun pl -> pl.AgeGroupPricings)))
                            
                yield! checkInt "Liczba osób (dorośli + dzieci)" (Some 1) (Some (room.Capacity + room.AdditionalCapacity)) utilisation |> forField (sprintf "Capacity%d" i)
        
                for a in allocation.Arrangements do
                    yield! checkInt (sprintf "Liczba dodatków %s" a.Type.Description) (Some 1) None a.Units |> forField (sprintf "Arrangements%d" i)

                let suitableVariants = 
                    [ for v in room.ArrangementVariants do
                        let incapable = 
                            allocation.Arrangements
                            |> joinMatching v.Availabilities (fun a a' -> a.Type.Id = a'.Type.Id)
                            |> List.tryFind (fun (a, a') -> a' |> Option.map (fun a' -> a.Units > a'.AvailableUnits) |> Option.defaultValue true)
                        if Option.isNone incapable then
                            yield v
                    ]

                if allocation.Arrangements.Length > 0 && suitableVariants.Length = 0 then
                    yield (sprintf "ArrangementVariants%d" i), "Wybór lub liczebność dodatków jest nieprawidłowa"
        ]


    let calculatePricePart
            (rooms: Room list,
             calcData: ReservationCalculationData,
             dateFrom: DateTime,
             dateTo: DateTime,
             useLongTimePrice: bool,
             priceList: PriceList option)
            =
        [ for allocation in calcData.Allocations do
            let room = rooms |> roomById allocation.RoomId
            let shortTimePrice, longTimePrice, shortEmptySpacePrice, longEmptySpacePrice, ageGroups =
                match priceList with
                | Some pl -> pl.ShortTimePrice, pl.LongTimePrice, pl.ShortEmptySpacePrice, pl.LongEmptySpacePrice, pl.AgeGroupPricings
                | None -> Calculated room.ShortTimePrice, Calculated room.LongTimePrice, room.ShortEmptySpacePrice, room.LongEmptySpacePrice, room.AgeGroupPricings

            room.Id,
            Derived.Calc(shortTimePrice, longTimePrice, shortEmptySpacePrice, longEmptySpacePrice, allocation.Adults,
                fun shortTimePrice longTimePrice shortEmptySpacePrice longEmptySpacePrice adults -> 
                    let days = Math.Round((dateTo - dateFrom).TotalDays) |> int
                    let adults = (if useLongTimePrice then longTimePrice else shortTimePrice) * decimal(adults)
                    let adultPricing: AgeGroupPricing = { 
                        AgeTo = 0
                        ShortTimePrice = Overwritten shortTimePrice
                        LongTimePrice = Overwritten longTimePrice
                    }

                    let children = 
                        allocation.Children
                        |> pricingsByAge (ageGroups, adultPricing)
                        |> List.map (fun p -> if useLongTimePrice then p.LongTimePrice.Value else p.ShortTimePrice.Value)
                        |> List.sum
                    let basePrice = (adults + children) * decimal(days)
    
                    let utilisation = allocation.Adults.Value + paidChildren (allocation.Children, ageGroups)
                    let emptySpacePrice = if useLongTimePrice then longEmptySpacePrice else shortEmptySpacePrice
                    let emptySpace =
                        if utilisation < room.Capacity then // because of AdditionalCapacity
                            decimal(room.Capacity - utilisation) * emptySpacePrice * decimal(days)
                        else
                            0m

                    let arrangements = 
                        allocation.Arrangements
                        |> joinMatching room.Arrangements (fun a a' -> a.Type.Id = a'.Type.Id)
                        |> List.map (fun (ra, a) -> a |> Option.map (fun a -> a.PayType, a.ExtraCharge * decimal(ra.Units)))
                        |> List.choose id
                    let paidOnce = arrangements |> List.filter (fst >> (=) PayType.Once) |> List.sumBy snd
                    let paidPerDay = (arrangements |> List.filter (fst >> (=) PayType.PerDay) |> List.sumBy snd) * decimal(days)
    
                    basePrice + paidOnce + paidPerDay + emptySpace)
        ]
        
    let mergeParts (part1: (int * Derived<decimal>) list, part2: (int * Derived<decimal>) list) =
        [ for roomId, price1 in part1 do
            let price2 = part2 |> List.tryFind (fst >> (=) roomId) |> Option.map snd
            roomId, price2 |> Option.map (fun p2 -> Derived.Calc(price1, p2, (+))) |> Option.defaultValue price1
        ]

    let rec calculateParts
            (rooms: Room list,
             calcData: ReservationCalculationData,
             involvedPriceLists: (ApplicationScope * PriceList) list,
             dateFrom: DateTime,
             dateTo: DateTime,
             useLongTimePrice: bool)
            =
        match involvedPriceLists with
        | [] ->
            calculatePricePart (rooms, calcData, dateFrom, dateTo, useLongTimePrice, None)
        | (scope, pl) :: further ->
            if dateFrom < scope.From then
                let part = calculatePricePart (rooms, calcData, dateFrom, scope.From, useLongTimePrice, None)
                let furtherParts = calculateParts (rooms, calcData, involvedPriceLists, scope.From, dateTo, useLongTimePrice)
                mergeParts(part, furtherParts)
            elif dateFrom >= scope.From && dateTo <= scope.To then
                let part = calculatePricePart (rooms, calcData, dateFrom, dateTo, useLongTimePrice, Some pl)
                part
            elif dateFrom >= scope.From && dateTo > scope.To then
                let part = calculatePricePart (rooms, calcData, dateFrom, scope.To.AddDays(1.0), useLongTimePrice, Some pl)
                let furtherParts = calculateParts (rooms, calcData, further, scope.To.AddDays(1.0), dateTo, useLongTimePrice)
                mergeParts(part, furtherParts)
            else
                failwith "Should never happen"

    let ofYear year (date: DateTime) =               
        if DateTime.IsLeapYear year && date.Month = 2 && date.Day = 29 then
            DateTime(year, 2, 28)
        else
            DateTime(year, date.Month, date.Day)

    let preparePriceListTemplates(resort, yearFrom, yearTo) =
        { resort with
            PriceListTemplates =
                [ for year in yearFrom..yearTo do
                    for pt in resort.PriceListTemplates do
                        { pt with
                            Scopes =
                                [ for s in pt.Scopes do
                                    { s with From = s.From |> ofYear year; To = s.To |> ofYear year }
                                ]
                        }
                ]
        }

    let calculateAllocationPrices (resort: Resort, rooms: Room list) (calcData: ReservationCalculationData) =
        let resort = preparePriceListTemplates(resort, calcData.From.Year, calcData.To.Year)
        let involvedPriceLists =
            [ for a in calcData.Allocations do
                let room = rooms |> roomById a.RoomId
                for pl in room.PriceLists do
                    for sc in (resort.PriceListTemplates |> List.find (fun t -> t.Id = pl.TemplateId)).Scopes do
                        if sc.To >= calcData.From && sc.From <= calcData.To then
                            yield sc, pl
            ]
            |> List.distinct
            |> List.sortBy fst
        let useLongTimePrice = (calcData.To - calcData.From).TotalDays > float(resort.ShortTime)
        calculateParts (rooms, calcData, involvedPriceLists, calcData.From, calcData.To, useLongTimePrice)

    let calculatePrepayment (resort: Resort, rooms: Room list) (allocationPrices: (int * Derived<decimal>) list) =
        let allocationPrepayments = 
            [ for roomId, price in allocationPrices do
                let room = rooms |> roomById roomId
                Derived.Calc(price, room.PrepaymentPercentage, fun price percentage -> price * decimal(percentage) / 100m)
            ]
            |> Derived.ofList
        Derived.Calc(allocationPrepayments, List.sum)

    let calculateReservation (resort: Resort, rooms: Room list, date: DateTime) (calcData: ReservationCalculationData) =
        let prices = calcData |> calculateAllocationPrices (resort, rooms)
        let price = Derived.Calc(prices |> List.map snd |> Derived.ofList, List.sum)
        let autoConfirm = calcData.BaseState = BaseState.New && resort.AutoConfirm
        let temp = 
            { calcData with
                Price               = calcData.Price |> Derived.Update price
                Prepayment          = calcData.Prepayment |> Derived.Update (prices |> calculatePrepayment (resort, rooms))
                PrepaymentDeadline  = if calcData.BaseState = BaseState.New then
                                        date.AddDays(float(resort.PrepaymentWaitPeriod))
                                      else
                                        calcData.PrepaymentDeadline
                BaseState = if autoConfirm then BaseState.Confirmed else calcData.BaseState
            }
        { temp with DetailedState = calculateState (temp, date) }

    let recalcReservation (resort: Resort, rooms: Room list, date: DateTime) (reservation: Reservation) =
        reservation
        |> ReservationCalculationData.FromReservation
        |> calculateReservation (resort, rooms, date)
        |> ReservationCalculationData.UpdateReservation reservation

    let childrenOccupancy (room: Room, children: int list) =
        children
        |> List.filter (fun age -> room.AgeGroupPricings |> List.forall (fun p -> p.AgeTo < age || p.LongTimePrice.Value <> 0m))
        |> List.length
        

    let baseFilterRooms (resort: Resort, dateFrom: DateTime, dateTo: DateTime, days: int, adults: int option, children: int list) (rooms: Room list) =
        let filteredByCapacity =
            adults
            |> Option.map (fun a -> rooms |> List.filter (fun r -> r.Capacity + r.AdditionalCapacity >= a + childrenOccupancy(r,  children)))
            |> Option.defaultValue rooms
        if days = 0 then
            filteredByCapacity |> List.map (fun r -> r, [ dateFrom, dateTo ])
        else
            [ for d in 0..int(Math.Round(dateTo.Subtract(dateFrom).TotalDays)) - days do
                let df = dateFrom.AddDays (float(d))
                let dt = dateFrom.AddDays (float(d + days))
                for r in filteredByCapacity do
                    if r |> roomIsAvailable(resort, df, dt) then
                        r, (df, dt)
            ]
            |> List.groupBy fst
            |> List.map (fun (r, ps) -> r, ps |> List.map snd)

    let rec aggregatePeriods (periods: (DateTime * DateTime) list) =
        match periods with
        | [] -> []
        | (df, dt) :: rest ->
            let ovl, nonovl = rest |> List.partition (fst >> (>=) dt)
            if List.isEmpty ovl then
                (df, dt) :: (aggregatePeriods nonovl)
            else
                aggregatePeriods ((df, ovl |> List.map snd |> List.max) :: aggregatePeriods nonovl)

    let estimatePrice (resort: Resort, room: Room, adults: int, children: int list) (dateFrom: DateTime, dateTo: DateTime) =
        let calcData =
            {
                From                = dateFrom
                To                  = dateTo
                Allocations         = [
                    {
                        Id              = 0
                        RoomId          = room.Id
                        Adults          = Calculated adults
                        Children        = children
                        Arrangements    = []
                        Price           = Uninitialised
                    }
                ]
                Payments            = []
                Price               = Uninitialised
                Prepayment          = Uninitialised
                PrepaymentDeadline  = DateTime()
                BaseState           = BaseState.New
                DetailedState       = Calculated DetailedState.New
            }

        Derived.Calc(
            calcData |> calculateAllocationPrices (resort, [ room |> recalcRoom resort ])
            |> List.map snd
            |> Derived.ofList,
            List.sum)


    let filterRooms
            (resort: Resort, dateFrom: DateTime, dateTo: DateTime, days: int)
            (rooms: Room list) =
        rooms
        |> baseFilterRooms (resort, dateFrom, dateTo, days, None, [])
        |> List.map (fun (r, avail) -> r, aggregatePeriods avail)
            
    let filterAndPriceRooms
            (resort: Resort, dateFrom: DateTime, dateTo: DateTime, days: int, adults: int, children: int list)
            (rooms: Room list) =
        rooms
        |> baseFilterRooms (resort, dateFrom, dateTo, days, Some adults, children)
        |> List.map (fun (r, avail) ->
            let prices = avail |> List.map (estimatePrice (resort, r, adults, children))
            r,            
            prices |> List.min,
            prices |> List.max,
            aggregatePeriods avail)

    let validateFilter (resort: Resort, currentDate: DateTime, dateFrom: DateTime, dateTo: DateTime, days: int) =
        [
            if resort.MaxRentalTime |> Option.map ((>) days) |> Option.defaultValue false then
                yield "Days", sprintf "Zbyt długi okres wynajmu. Maksymalny czas wynajmu wynosi %d dni." resort.MaxRentalTime.Value

            if days > int(Math.Round((dateTo - dateFrom).TotalDays)) then
                yield "Days", "Zadeklarowana liczba dni nie może przekraczać liczby dni pomiedzy datą rozpoczęcia a datą zakończenia"

            if resort.MaxRentalAdvance |> Option.map ((>) (monthDiff(currentDate, dateFrom))) |> Option.defaultValue false then
                yield "From", sprintf "Zbyt odległy termin rezerwacji. Maksymalne wyprzedzenie wynosi %d mies." resort.MaxRentalAdvance.Value
        ]

    type IOwnerReservationApi =
        {
            GetOwnerReservationSummaries    : bool -> Async<ReservationSummary list>
            GetResortReservationSummaries   : int * bool -> Async<ReservationSummary list>
            GetRoomReservationSummaries     : int * bool -> Async<ReservationSummary list>
            GetReservation                  : int -> Async<Reservation * Resort * Room list>
            SaveReservation                 : Reservation -> Async<unit>
            ConfirmReservation              : Reservation * int -> Async<unit>
            AcceptPrepayment                : Reservation -> Async<unit>
            RefuseReservation               : Reservation -> Async<unit>
            WarnOnPrepaymentDelay           : int -> Async<unit>
        }

    type IOrdererReservationApi =
        {
            GetReservation          : int * Guid -> Async<Reservation * Resort * Room list * BankAccount * OrdererData option>
            GetRoom                 : int -> Async<Room * Resort * Room list * BankAccount * OrdererData option>
            GetResort               : int -> Async<Resort * Room list * BankAccount * OrdererData option>
            GetResortByPartnerKey   : string * string -> Async<Resort * Room list * BankAccount * OrdererData option>
            SaveReservation         : Reservation * Guid -> Async<int>
            SendMessage             : int * string * Guid -> Async<unit>
            CancelReservation       : int * string * Guid -> Async<unit>
        }