namespace Shared

module Utils =

    let concatRoomNames (names: (string * int) list) =
        match names with
        | [ name, 1 ] -> name
        | _ -> names |> List.map (fun (name, count) -> sprintf "%s (%d)" name count) |> String.concat ", "


