module HeaderCommons

open System
open Elmish
open Fable.Remoting.Client
open Shared

open Commons
open Shared.LoggedUser
open Shared.ServerError


let loggedUserApi = 
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IUserApi>


type Msg =
    | AccountDataLoaded of BasicAccountData option
    | ChangePassword
    | AccountDetails
    | BankAccount
    | Logout
    | LogIn
    | LoggedOut of unit
    | ShowError of string * ErrorInfo


type MessageInfo =
    {
        Id      : int
        Icon    : string
        Sender  : string
        Subject : string
    }

type Model =
    {
        UserName    : string
        UserIcon    : string
        Registered  : DateTime
        Messages    : MessageInfo list
        LoginPage   : string
        LoggedIn    : bool
    }
    
open Fable.React
open Fable.React.Props

let init(loginPage: string): Model * Cmd<Msg> =
    {   UserName = "Niezalogowany"
        UserIcon = "dist/img/avatar5.png"
        Registered = DateTime.Now
        Messages = [
            { Id = 1; Sender = "Adam Majewski"; Icon = "dist/img/user2-160x160.jpg"; Subject = "Pytanie o parking" }
        ]
        LoginPage = loginPage
        LoggedIn  = false
    },
    Cmd.OfAsync.either id (loggedUserApi.GetBasicAccountData()) AccountDataLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać danych użytkownika.")

let logoutCmd = Cmd.OfAsync.either id (loggedUserApi.Logout()) LoggedOut (ErrorHandling.unpack ShowError "Nie udało się wylogować.")

let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
    match msg with
    | AccountDataLoaded (Some account) ->
        { model with
            UserName = account.FirstName + " " + account.LastName
            UserIcon = account.Icon |> Option.defaultValue "dist/img/avatar5.png"
            LoggedIn = true
        }, Cmd.none
    | AccountDataLoaded None ->
       model, Cmd.none
    | LogIn ->
#if DEBUG
        Browser.Dom.window.location.href <- model.LoginPage
#else
        Browser.Dom.window.location.reload()
#endif        
        model, Cmd.none
    | Logout ->
        model, logoutCmd
    | LoggedOut () ->
        Browser.Dom.window.location.href <- model.LoginPage
        model, Cmd.none
    | Msg ->
        model, Cmd.none

let dropdownMenu menuType menuIcon labelType headerText childItems = 
    li [ Class ("dropdown " + menuType) ] [
        a [ Style [ Cursor "pointer" ]; Class "dropdown-toggle"; Data("toggle", "dropdown") ] [
            i [ Class menuIcon ] []
            span [ Class ("label " + labelType) ] [ str (childItems |> List.length |> string) ]
        ]
        ul [ Class "dropdown-menu" ] [
            li [ Class "header" ] [ str headerText ]
            li [] [
                ul [ Class "menu" ] childItems
            ]
            li [ Class "footer" ] [ str "Pokaż wszystkie" ]
        ]
    ]

let messageMenu items =
    dropdownMenu "messages-menu" "fa fa-envelope-o" "label-info"
        (sprintf "Masz %d nowych wiadomości" (List.length items)) items

let notificationMenu items =
    dropdownMenu "notifications-menu" "fa fa-bell-o" "label-warning"
        (sprintf "Masz %d nowych powiadomień" (List.length items)) items

let taskMenu items =
    dropdownMenu "tasks-menu" "fa fa-flag-o" "label-danger"
        (sprintf "Masz %d nowych zadań" (List.length items)) items

let messageItem sender icon subject dispatch msg =
    li [] [
        ul [ Class "menu" ] [
            li [] [
                a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch msg) ] [
                    div [ Class "pull-left" ] [
                        img [ Src icon; Class "img-circle"; Alt "User Image"] 
                    ]
                    h4 [] [
                        str sender
                        small [] [ i [ Class "fa fa-clock-o"] [ str "5 min" ] ]
                    ]
                    p [] [ str subject ]
                ]
            ]
        ]
    ]


let notificationItem info icon dispatch msg =
    li [] [
        a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch msg) ] [ i [ Class icon ] []; str info ]
    ]

let taskItem info color perc dispatch msg =
    li [] [
        a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch msg) ] [
            h3 [] [
                str info
                small [ Class "pull-right" ] [ str (sprintf "%d%%" perc)]
            ]
            div [ Class "progress xs" ] [
                div [
                    Class (sprintf "progress-bar progress-bar-%s" color)
                    Style [ Width (sprintf "%d%%" perc)]
                    Role "progressbar"
                    AriaValuenow (float perc)
                    AriaValuemin 0.0
                    AriaValuemax 100.0
                ] [
                    span [ Class "sr-only" ] [ str (sprintf "Wykonano %d%%" perc)]
                ]
            ]
        ]
    ]


let userMenu (model: Model) (dispatch : Msg -> unit) =
    li [ Class "dropdown user user-menu"] [
        a [ Style [ Cursor "pointer" ]; Class "dropdown-toggle"; Data("toggle", "dropdown") ] [
            img [ Src model.UserIcon; Class "user-image"; Alt "User Image" ]
            span [ Class "hidden-xs" ] [ str model.UserName ]
        ]
        ul [ Class "dropdown-menu" ] [
            li [ Class "user-header" ] [
                img [ Src model.UserIcon; Class "img-circle"; Alt "User Image" ]
                p [] [
                    str model.UserName
                    small [] [ str <| sprintf "Użytkownik od %d.%d" model.Registered.Month model.Registered.Year]
                ]
            ]
            li [ Class "user-body"] [
                div [ Class "row" ] [
                    div [ Class "col-xs-4 text-center" ] [
                        a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch ChangePassword) ] [ str "Hasło" ]
                    ]
                    div [ Class "col-xs-4 text-center" ] [
                        a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch AccountDetails) ] [ str "Kontakt" ]
                    ]
                    div [ Class "col-xs-4 text-center" ] [
                        a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch BankAccount) ] [ str "Rachunek" ]
                    ]
                ]
            ]
            li [ Class "user-footer"] [
                div [Class "pull-left"] [
                    a [ Style [ Cursor "pointer" ]; Class "btn btn-default btn-flat"; OnClick (Event.none dispatch AccountDetails) ] [ str "Profil" ]
                ]
                if model.LoggedIn then
                    div [Class "pull-right"] [
                        a [ Style [ Cursor "pointer" ]; Class "btn btn-default btn-flat"; OnClick (Event.none dispatch Logout) ] [ str "Wyloguj" ]
                    ]
                else
                    div [Class "pull-right"] [
                        a [ Style [ Cursor "pointer" ]; Class "btn btn-default btn-flat"; OnClick (Event.none dispatch LogIn) ] [ str "Zaloguj" ]
                    ]
            ]
        ]
    ]


let view (menu: ReactElement) (model : Model) (dispatch : Msg -> unit) =
    header [ Class "main-header" ] [
        a [ Href "/"; Class "logo" ] [
            span [ Class "logo-mini" ] [ b [] [ str "Q" ]; str "a" ]
            span [ Class "logo-lg" ] [ b [] [ str "Qua" ]; str "tera" ]
        ]
        nav [ Class "navbar navbar-static-top" ] [
            a [ Href "#"; Class "sidebar-toggle"; Data("toggle", "push-menu"); Role "button" ] [
                span [ Class "sr-only" ] [ str "Toggle navigation" ]
            ]
            
            menu
        ]
    ]