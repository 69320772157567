namespace Shared

open Validation

module UserProfile =

    type AccountDetails =
        {
            FirstName   : string
            LastName    : string
            Email       : string
            Phone       : string option
        }

    let validateAccountDetails (data: AccountDetails) =
        [
            checkEmpty "Imię" "e" data.FirstName |> forField "FirstName"        
            checkEmpty "Nazwisko" "e" data.LastName |> forField "LastName"
            checkEmpty "Email" "y" data.Email |> forField "Email"
            checkEmpty "Telefon" "y" data.Email |> forField "Phone"
        ] |> List.collect id

    type IUserProfileApi =
        {
            GetAccountDetails: unit -> Async<AccountDetails>
            UpdateAccountDetails: AccountDetails -> Async<unit>
            GetSalt: unit -> Async<int>
            ChangePassword: string * string -> Async<bool>
        }
