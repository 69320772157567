module UserProfile

open System
open Elmish
open Fable.Remoting.Client
open Shared
open Fable.React
open Fable.React.Props

open Commons
open Shared.UserProfile
open Shared.PaymentMethods
open Shared.Validation
open Fable.Core
open Shared.ServerError

let userProfileApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IUserProfileApi>

let paymentApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IPaymentApi>

module ContentHeader =

    let wrap content =
        React.ofList [
            Components.contentHeader "Profil użytkownika" "" 
            div [ Class "content" ] [
                div [ Class "row" ] [
                    div [ Class "col-xs-12" ] [
                        content
                    ]
                ]
            ]
        ]

module AccountDetails =

    type Msg =
        | AccountDetailsLoaded of AccountDetails
        | FirstNameChanged of string
        | LastNameChanged of string
        | EmailChanged of string
        | PhoneChanged of string
        | Save
        | AccountDetailsUpdated of unit
        | ShowError of string * ErrorInfo

    type Model =
        {
            FirstName       : string
            FirstNameErrors : string list
            LastName        : string 
            LastNameErrors  : string list
            Email           : string
            EmailErrors     : string list
            Phone           : string 
            PhoneErrors     : string list
            Finished        : bool
        }
        static member Empty =
            {
                FirstName       = ""
                FirstNameErrors = []
                LastName        = "" 
                LastNameErrors  = []
                Email           = ""
                EmailErrors     = []
                Phone           = "" 
                PhoneErrors     = []
                Finished        = false
            }
        static member FromAccountDetails (details: AccountDetails) =
            {
                FirstName       = details.FirstName
                FirstNameErrors = []
                LastName        = details.LastName 
                LastNameErrors  = []
                Email           = details.Email
                EmailErrors     = []
                Phone           = details.Phone |> Option.defaultValue ""
                PhoneErrors     = []
                Finished        = false
            }
        static member ToAccountDetails (model: Model) =
            {
                FirstName   = model.FirstName
                LastName    = model.LastName 
                Email       = model.Email
                Phone       = if String.IsNullOrEmpty model.Phone then None else Some model.Phone
            }
        static member WithErrors errors (model: Model) =
            { model with
                FirstNameErrors = errors |> getErrorsOf "FirstName"
                LastNameErrors = errors |> getErrorsOf "LastName"
                EmailErrors = errors |> getErrorsOf "Email"
                PhoneErrors = errors |> getErrorsOf  "Phone"
            }

    let accountDetailsCmd =
        Cmd.OfAsync.either id (userProfileApi.GetAccountDetails()) AccountDetailsLoaded (ErrorHandling.unpack ShowError "Nie udało się odczytać danych użytkownika.")

    let updateAccountDetailsCmd details =
        Cmd.OfAsync.either id (userProfileApi.UpdateAccountDetails details) AccountDetailsUpdated (ErrorHandling.unpack ShowError "Nie udało się zapisać danych użytkownika.")


    let init (): Model * Cmd<Msg> =
        Model.Empty, accountDetailsCmd

    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | AccountDetailsLoaded details ->
            details |> Model.FromAccountDetails, Cmd.none
        | FirstNameChanged name ->
            { model with FirstName = name }, Cmd.none
        | LastNameChanged name ->
            { model with LastName = name }, Cmd.none
        | EmailChanged email ->
            { model with Email = email }, Cmd.none
        | PhoneChanged phone ->
            { model with Phone = phone }, Cmd.none
        | Save ->
            let errors = model |> Model.ToAccountDetails |> validateAccountDetails
            if errors.IsEmpty then
                model, model |> Model.ToAccountDetails |> updateAccountDetailsCmd
            else
                model |> Model.WithErrors errors, Cmd.none
        | AccountDetailsUpdated () ->
            { model with Finished = true }, Cmd.none

    let view (model : Model) (dispatch : Msg -> unit) =        
        ContentHeader.wrap
        <|  div [ Class "box box-info" ] [
                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Moje dane" ]
                ]
                form [ Class "form-horizontal"] [
                    div [ Class "box-body" ] [
    
                        Forms.formGroup (not model.FirstNameErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-6" "firstName" "Imię" 50.0                                               
                            (Value model.FirstName)
                            (Forms.errorsBelow model.FirstNameErrors)
                            (Event.str dispatch FirstNameChanged)

                        Forms.formGroup (not model.LastNameErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-6" "LastName" "Nazwisko" 50.0                                               
                            (Value model.LastName)
                            (Forms.errorsBelow model.LastNameErrors)
                            (Event.str dispatch LastNameChanged)

                        Forms.formGroup (not model.EmailErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-6" "email" "Email" 120.0                                               
                            (Value model.Email)
                            (Forms.errorsBelow model.EmailErrors)
                            (Event.str dispatch EmailChanged)

                        Forms.formGroup (not model.PhoneErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-6" "phone" "Telefon" 15.0                                               
                            (Value model.Phone)
                            (Forms.errorsBelow model.PhoneErrors)
                            (Event.str dispatch PhoneChanged)

                        div [ Class "form-group has-success" ] [
                            div [ Class "col-xs-offset-3" ] [
                                if model.Finished then
                                    yield label [ Class "control-label" ] [ str "Dane zostały zmienione." ]
                            ]                                        
                        ]
                    ]
                ]
                div [ Class "box-footer" ] [
                    div [ Class "col-sm-5 pull-right"] [
                        Controls.simpleButton [] "btn-primary" " Zapisz" "fa fa-save" dispatch Save
                    ]
                ]
            ]


module ChangePassword =

    type Msg =
        | OldPasswordChanged of string
        | Password1Changed of string
        | Password2Changed of string
        | Save
        | PasswordUpdated of bool
        | ShowError of string * ErrorInfo

    type Model =
        {
            OldPassword         : string
            Password1           : string
            Password1Errors     : string list
            Password2           : string
            Password2Errors     : string list
            Success             : bool option
        }
        static member Empty =
            {
                OldPassword         = ""
                Password1           = ""
                Password1Errors     = []
                Password2           = ""
                Password2Errors     = []
                Success             = None
            }
        member this.HasErrors = not (this.Password1Errors.IsEmpty && this.Password2Errors.IsEmpty)

    let validate (model: Model) =
        { model with
            Password1Errors = checkPasswordStrength model.Password1 
            Password2Errors = [ if model.Password1 <> model.Password2 then "Hasła są niezgodne" ]            
        }

    let updatePasswordCmd (oldPwd, newPwd) =
        let cmd = async {
            let! salt = userProfileApi.GetSalt()
            let sltOldPwd = Auth.hash (oldPwd + salt.ToString())
            let sltNewPwd = Auth.hash (newPwd + salt.ToString())
            return! userProfileApi.ChangePassword (sltOldPwd, sltNewPwd)
        }
        Cmd.OfAsync.either id cmd PasswordUpdated (ErrorHandling.unpack ShowError "Nie udało się zmodyfikować hasła.")

    let init (): Model * Cmd<Msg> =
        Model.Empty, Cmd.none

    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | OldPasswordChanged pwd ->
            { model with OldPassword = pwd }, Cmd.none
        | Password1Changed pwd ->
            { model with Password1 = pwd }, Cmd.none
        | Password2Changed pwd ->
            { model with Password2 = pwd }, Cmd.none
        | Save ->
            let validated = validate model
            if validated.HasErrors then
                validated, Cmd.none
            else
                model, updatePasswordCmd (model.OldPassword, model.Password1)
        | PasswordUpdated result ->
            { model with Success = Some result }, Cmd.none

    let view (model : Model) (dispatch : Msg -> unit) =        
        ContentHeader.wrap
        <|  div [ Class "box box-info" ] [
                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Moje dane" ]
                ]
                form [ Class "form-horizontal"] [
                    div [ Class "box-body" ] [
    
                        Forms.formGroup false
                        <| Forms.passwordWithLabel "col-sm-2" "col-sm-6" "oldPassword" "Stare hasło" 64.0                                               
                            (Value model.OldPassword)
                            (Forms.errorsBelow [])
                            (Event.str dispatch OldPasswordChanged)

                        Forms.formGroup (not model.Password1Errors.IsEmpty)
                        <| Forms.passwordWithLabel "col-sm-2" "col-sm-6" "password1" "Nowe hasło" 64.0                                               
                            (Value model.Password1)
                            (Forms.errorsBelow model.Password1Errors)
                            (Event.str dispatch Password1Changed)

                        Forms.formGroup (not model.Password2Errors.IsEmpty)
                        <| Forms.passwordWithLabel "col-sm-2" "col-sm-6" "password2" "Powtórz nowe hasło" 64.0                                               
                            (Value model.Password2)
                            (Forms.errorsBelow model.Password2Errors)
                            (Event.str dispatch Password2Changed)

                        match model.Success with
                        | Some succ ->
                            div [ Class <| "form-group " + (if succ then "has-success" else "has-error")] [
                                div [ Class "col-xs-offset-3" ] [
                                    label [ Class "control-label" ] [
                                        if succ then
                                            str "Hasło zostało zmienione."
                                        else
                                            str "Stare hasło jest błędne."
                                    ]
                                ]                                        
                            ]
                        | None ->
                            div [] []
                    ]
                ]
                div [ Class "box-footer" ] [
                    div [ Class "col-sm-5 pull-right"] [
                        Controls.simpleButton [] "btn-primary" " Zapisz" "fa fa-save" dispatch Save
                    ]
                ]
            ]

module BankAccount =

    type Msg =
        | BankAccountLoaded of BankAccount option
        | OwnerNameChanged of string
        | AccountNumberChanged of string
        | BankNameChanged of string
        | CityChanged of string
        | StreetChanged of string
        | NumberChanged of string
        | ZipCodeChanged of string
        | PasswordChanged of string
        | Save
        | ShowError of string* ErrorInfo
        | BankAccountUpdated of bool

    type Address =
        {
            City            : string
            CityErrors      : string list
            Street          : string
            StreetErrors    : string list
            Number          : string
            NumberErrors    : string list
            ZipCode         : string
            ZipCodeErrors   : string list
        }

    type Model =
        {
            OwnerName           : string
            OwnerNameErrors     : string list
            OwnerAddress        : Address
            AccountNumber       : string
            AccountNumberErrors : string list
            BankName            : string
            BankNameErrors      : string list
            Password            : string
            Success             : bool option
        }
        static member Empty =
            {
                OwnerName           = ""
                OwnerNameErrors     = []
                OwnerAddress        =  {
                    City            = ""
                    CityErrors      = []
                    Street          = ""
                    StreetErrors    = []
                    Number          = ""
                    NumberErrors    = []
                    ZipCode         = ""
                    ZipCodeErrors   = []
                }
                AccountNumber       = ""
                AccountNumberErrors = []
                BankName            = ""
                BankNameErrors     = []
                Password            = ""
                Success             = None
            }
        static member FromBankAccount (account: BankAccount) =
            {
                OwnerName           = account.OwnerName
                OwnerNameErrors     = []
                OwnerAddress        =  {
                    City            = account.OwnerAddress.City
                    CityErrors      = []
                    Street          = account.OwnerAddress.Street
                    StreetErrors    = []
                    Number          = account.OwnerAddress.Number
                    NumberErrors    = []
                    ZipCode         = account.OwnerAddress.ZipCode
                    ZipCodeErrors   = []
                }
                AccountNumber       = account.AccountNumber
                AccountNumberErrors = []
                BankName            = account.BankName
                BankNameErrors      = []
                Password            = ""
                Success             = None
            }
        static member ToBankAccount (model: Model) =
            {
                OwnerName           = model.OwnerName
                OwnerAddress        =  {
                    City            = model.OwnerAddress.City
                    Street          = model.OwnerAddress.Street
                    Number          = model.OwnerAddress.Number
                    ZipCode         = model.OwnerAddress.ZipCode
                }
                AccountNumber       = model.AccountNumber
                BankName            = model.BankName
            }
        static member WithErrors errors (model: Model) =
            { model with
                OwnerNameErrors         = errors |> getErrorsOf "OwnerName"
                OwnerAddress = {
                    model.OwnerAddress with
                        CityErrors      = errors |> getErrorsOf "City"
                        StreetErrors    = errors |> getErrorsOf "Street"
                        NumberErrors    = errors |> getErrorsOf "Number"
                        ZipCodeErrors   = errors |> getErrorsOf "ZipCode"
                }
                AccountNumberErrors     = errors |> getErrorsOf "AccountNumber"
                BankNameErrors          = errors |> getErrorsOf "BankName"
            }

    let bankAccountCmd =
        let cmd = async {
            JS.console.error("bankAccountCmd/1")
            let! account = paymentApi.GetBankAccount()
            JS.console.error("bankAccountCmd/2")
            return account
        }
        Cmd.OfAsync.either id cmd BankAccountLoaded (ErrorHandling.unpack ShowError "Nie udało się odczytać danych rachunku bankowego.")

    let updateBankAccount (account, pwd) =
        let cmd = async {
            let! salt = userProfileApi.GetSalt()
            let sltPwd = Auth.hash (pwd + salt.ToString())
            return! paymentApi.SaveBankAccount (account, sltPwd)
        }
        Cmd.OfAsync.either id cmd BankAccountUpdated (ErrorHandling.unpack ShowError "Nie udało się zmodyfikować danych rachunku bankowego.")

    let init (): Model * Cmd<Msg> =
        JS.console.error("BankAccount.init()")
        Model.Empty, bankAccountCmd

    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | BankAccountLoaded (Some account) ->
            JS.console.error("BankAccountLoaded Some")
            account |> Model.FromBankAccount, Cmd.none
        | BankAccountLoaded None ->
            JS.console.error("BankAccountLoaded None")
            model, Cmd.none
        | OwnerNameChanged name ->
            { model with OwnerName = name }, Cmd.none
        | AccountNumberChanged number ->
            { model with AccountNumber = number }, Cmd.none
        | BankNameChanged name ->
            { model with BankName = name }, Cmd.none
        | PasswordChanged pwd ->
            { model with Password = pwd }, Cmd.none
        | CityChanged city ->
            { model with OwnerAddress = { model.OwnerAddress with City = city } }, Cmd.none
        | StreetChanged street ->
            { model with OwnerAddress = { model.OwnerAddress with Street = street } }, Cmd.none
        | NumberChanged number ->
            { model with OwnerAddress = { model.OwnerAddress with Number = number } }, Cmd.none
        | ZipCodeChanged code ->
            { model with OwnerAddress = { model.OwnerAddress with ZipCode = code } }, Cmd.none
        | Save ->
            let errors = model |> Model.ToBankAccount |> validateBankAccount
            if errors.IsEmpty then
                model, updateBankAccount (model |> Model.ToBankAccount, model.Password)
            else
                model |> Model.WithErrors errors, Cmd.none
        | BankAccountUpdated success ->
            { model with Success = Some success }, Cmd.none

    let view (model : Model) (dispatch : Msg -> unit) =        
        ContentHeader.wrap
        <|  div [ Class "box box-info" ] [
                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Rachunek bankowy" ]
                ]
                form [ Class "form-horizontal"] [
                    div [ Class "box-body" ] [

                        Forms.formGroup (not model.OwnerNameErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-9" "ownerName" "Właściciel" 120.0                                               
                            (Value model.OwnerName)
                            (Forms.errorsBelow model.OwnerNameErrors)
                            (Event.str dispatch OwnerNameChanged)

                        Forms.formGroup (not model.OwnerAddress.CityErrors.IsEmpty || not model.OwnerAddress.ZipCodeErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-5" "city" "Miejscowość" 120.0
                            (Value model.OwnerAddress.City)
                            (Forms.errorsBelow model.OwnerAddress.CityErrors)
                            (Event.str dispatch CityChanged)
                         @ Forms.inputWithLabel "col-sm-2" "col-sm-2" "zipCode" "Kod pocztowy" 10.0
                            (Value model.OwnerAddress.ZipCode)
                            (Forms.errorsBelow model.OwnerAddress.ZipCodeErrors)
                            (Event.str dispatch ZipCodeChanged)
                            
                        Forms.formGroup (not model.OwnerAddress.StreetErrors.IsEmpty || not model.OwnerAddress.NumberErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-5" "street" "Ulica" 180.0
                            (Value model.OwnerAddress.Street)
                            (Forms.errorsBelow model.OwnerAddress.StreetErrors)
                            (Event.str dispatch StreetChanged)
                         @ Forms.inputWithLabel "col-sm-2" "col-sm-2" "number" "Numer" 20.0
                            (Value model.OwnerAddress.Number)
                            (Forms.errorsBelow model.OwnerAddress.NumberErrors)
                            (Event.str dispatch NumberChanged)

                        Forms.formGroup (not model.AccountNumberErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-9" "accountNumber" "Numer rachunku" 26.0                                               
                            (Value model.AccountNumber)
                            (Forms.errorsBelow model.AccountNumberErrors)
                            (Event.str dispatch AccountNumberChanged)

                        Forms.formGroup (not model.BankNameErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-2" "col-sm-9" "bankName" "Bank" 120.0                                               
                            (Value model.BankName)
                            (Forms.errorsBelow model.BankNameErrors)
                            (Event.str dispatch BankNameChanged)

                        Forms.formGroup false
                        <| Forms.passwordWithLabel "col-sm-2" "col-sm-6" "password" "Hasło" 64.0                                               
                            (Value model.Password)
                            (Forms.errorsBelow [])
                            (Event.str dispatch PasswordChanged)

                        match model.Success with
                        | Some succ ->
                            div [ Class <| "form-group " + (if succ then "has-success" else "has-error")] [
                                div [ Class "col-xs-offset-3" ] [
                                    label [ Class "control-label" ] [
                                        if succ then
                                            str "Dane rachunku zostały zmienione."
                                        else
                                            str "Hasło jest błędne."
                                    ]
                                ]                                        
                            ]
                        | None ->
                            div [] []
                    ]
                ]
                div [ Class "box-footer" ] [
                    div [ Class "col-sm-2 pull-right"] [
                        Controls.simpleButton [] "btn-primary" " Zapisz" "fa fa-save" dispatch Save
                    ]
                ]
            ]