namespace Shared

open Validation
open System

module Authentication =

    type Credentials =
        {
            Username : string
            Password : string
        }

    [<RequireQualifiedAccess>]
    type LoginResult =
        | Success of int
        | Inactive
        | InvalidCredentials
        | ToManyFailedAttempts

    type RegistrationData =
        {
            FirstName   : string
            LastName    : string
            Email       : string
            Username    : string
            Password    : string
            Salt        : int
        }

    [<RequireQualifiedAccess>]
    type RegistrationResult =
        | Success of int
        | UsernameAlreadyUsed

    [<RequireQualifiedAccess>]
    type SecretCodeActionResult =
        | Success of int
        | Expired of int
        | Removed

    let validateRegistrationData (data: RegistrationData) =
        [
            checkEmpty "Imię" "e" data.FirstName |> forField "FirstName"        
            checkEmpty "Nazwisko" "e" data.LastName |> forField "LastName"
            checkEmpty "Nazwa użytkownika" "a" data.Username |> forField "Username"
            checkEmpty "Email" "y" data.Email |> forField "Email"
            checkEmpty "Hasło" "e" data.Password |> forField "Password"
            checkPasswordStrength data.Password |> forField "Password"
        ] |> List.collect id

    type IAuthApi =
        {
            GetSalt         : string -> Async<int>
            GetSaltByCode   : Guid -> Async<int option>
            LogIn           : Credentials * bool -> Async<LoginResult>
            Logout          : unit -> Async<unit>
            Register        : RegistrationData -> Async<RegistrationResult>
            Activate        : Guid -> Async<SecretCodeActionResult>
            ResetPassword   : string -> Async<unit>
            UpdatePassword  : string * Guid -> Async<SecretCodeActionResult>
        }

    
