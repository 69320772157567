module Authentication

open System
open Elmish
open Fable.React
open Fable.React.Props
open Commons
open Shared.Authentication
open Fable.Remoting.Client
open Shared
open Fable.Core
open Shared.Validation

[<RequireQualifiedAccess>]
type AuthParams =
    | Activation of Guid
    | PasswordReset of Guid
    | FullReturnUrl of string

module Login =

    type Msg =
        | UsernameChanged of string
        | PasswordChanged of string
        | PasswordKeyDown of string
        | RememberMeChanged of bool
        | Submit
        | ShowResult of LoginResult
        | Register
        | PasswordReset
        | ShowError of exn

    type Model =
        {
            Username        : string
            Password        : string
            RememberMe      : bool
            Redirection     : string
            LoginResult     : string * string
        }

    let init (redirection: string): Model * Cmd<Msg> =
        { Username = ""; Password = ""; RememberMe = true; Redirection = redirection; LoginResult = "", "" },
        Cmd.none

    let loginCmd(api, username, password, rememberMe) =
        let cmd = async {
            let! salt = api.GetSalt username
            let! res = api.LogIn ({ Username = username; Password = Auth.hash (password + salt.ToString()) }, rememberMe)
            return ShowResult res
        }
        Cmd.OfAsync.either id cmd id ShowError

    let update (api: IAuthApi) (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | UsernameChanged username ->
            { model with Username = username }, Cmd.none
        | PasswordChanged password ->
            { model with Password = password }, Cmd.none
        | PasswordKeyDown key ->            
            model, if key = "Enter" then Cmd.ofMsg Submit else Cmd.none
        | RememberMeChanged rememberMe ->
            { model with RememberMe = rememberMe }, Cmd.none
        | Submit ->
            model, loginCmd(api, model.Username, model.Password, model.RememberMe)
        | ShowResult res ->
            let message = 
                match res with
                | LoginResult.Success _ ->
                    Browser.Dom.window.location.href <- model.Redirection
                    "has-success", sprintf "Witaj %s" model.Username
                | LoginResult.Inactive _ ->
                    "has-error", "Konto nie zostało jeszcze aktywowane. Poszukaj wiadomości z linkiem aktywacyjnym w swojej skrzynce pocztowej."
                | LoginResult.InvalidCredentials ->
                    "has-error", "Nieprawidłowa nazwa użytkownika lub hasło." 
                | LoginResult.ToManyFailedAttempts ->
                    "has-error", "Konto zablokowane z powodu zbyt dużej liczby nieudanych prób logowania."
            { model with LoginResult = message }, Cmd.none
        | ShowError ex ->
            let message = sprintf "Podczas logowania nastąpił nieoczekiwany błąd: %s" ex.Message
            { model with LoginResult = "has-error", message }, Cmd.none
        | Register
        | PasswordReset ->
            Browser.Dom.window.location.hash <- ""
            //Browser.Dom.window.location.href <- "404.html"
            model, Cmd.none


    let view (model : Model) (dispatch : Msg -> unit) =
        React.ofList [
            p [ Style [ TextAlign TextAlignOptions.Center ] ] [ str "Zaloguj się, by rozpocząć pracę" ]
            div [] [
                div [ Class "form-group has-feedback" ] [
                    input [
                        Class "form-control"
                        Placeholder "Użytkownik"
                        Value model.Username
                        OnChange (Event.str dispatch UsernameChanged)
                        MaxLength 16.0
                    ]
                    span [ Class "glyphicon glyphicon-user form-control-feedback" ] []
                ]
                div [ Class "form-group has-feedback" ] [
                    input [
                        Type "password"
                        Class "form-control"
                        Placeholder "Hasło"
                        Value model.Password
                        OnKeyDown (fun evt ->  dispatch (PasswordKeyDown evt.key))
                        OnChange (Event.str dispatch PasswordChanged)
                        MaxLength 64.0
                    ]
                    span [ Class "glyphicon glyphicon-lock form-control-feedback" ] []

                ]
                div [Class (sprintf "form-group %s" (fst model.LoginResult))] [
                    if not <| String.IsNullOrEmpty (snd model.LoginResult) then
                        yield label [ Class "control-label" ] [ str (snd model.LoginResult) ]
                ]
                div [ Class "row" ] [
                    div [Class "col-xs-8"] [
                        Controls.checkbox "rememberMe" [] "Zapamiętaj mnie" model.RememberMe dispatch RememberMeChanged
                    ]                    
                    div [ Class "col-xs-4" ] [
                        div [ 
                            Class "btn btn-primary btn-block btn-flat"
                            OnClick (Event.none dispatch Submit)
                        ] [ str "Zaloguj" ]
                    ]
                ]
            ]
            a [ Style [ Cursor "pointer" ]
                OnClick (Event.none dispatch PasswordReset)
            ] [ str "Zapomniałem hasła" ]
            br []
            a [ Style [ Cursor "pointer" ]; 
                Class "text-center"
                OnClick (Event.none dispatch Register)
            ] [ str "Nie mam jeszcze konta" ]
        ]

module Registration =

    type Msg =
        | UsernameChanged  of string
        | Password1Changed of string
        | Password2Changed of string
        | FirstNameChanged of string
        | LastNameChanged of string
        | EmailChanged of string
        | TermsAcceptChanged of bool
        | Submit
        | ShowResult of RegistrationResult
        | ShowError of exn
        | LogIn

    [<RequireQualifiedAccess>]
    type ViewType =
        | Editor
        | Summary

    type Model =
        {
            FirstName       : string
            FirstNameErrors : string list
            LastName        : string
            LastNameErrors  : string list
            Email           : string
            EmailErrors     : string list
            Username        : string
            UsernameErrors  : string list
            Password1       : string
            Password1Errors : string list
            Password2       : string
            Password2Errors : string list
            TermsAccepted   : bool
            ErrorMessage    : string
            Redirection     : string
            ViewType        : ViewType
        }
        static member Empty =
            {
                FirstName       = ""
                FirstNameErrors = []
                LastName        = ""
                LastNameErrors  = []
                Email           = ""
                EmailErrors     = []
                Username        = ""
                UsernameErrors  = []
                Password1       = ""
                Password1Errors = []
                Password2       = ""
                Password2Errors = []
                TermsAccepted   = false
                ErrorMessage    = ""
                Redirection     = ""
                ViewType        = ViewType.Editor
            }
        static member WithErrors (errors: (string * string) list) (model: Model) =
            { model with
                FirstNameErrors = errors |> getErrorsOf "FirstName"
                LastNameErrors  = errors |> getErrorsOf "LastName"
                EmailErrors     = errors |> getErrorsOf "Email"
                UsernameErrors  = errors |> getErrorsOf "Username"
                Password1Errors = errors |> getErrorsOf "Password1"
                Password2Errors = errors |> getErrorsOf "Password2"
            }
        static member ToRegistrationData (model: Model): RegistrationData =
            {
                FirstName       = model.FirstName
                LastName        = model.LastName
                Email           = model.Email
                Username        = model.Username
                Password        = model.Password1
                Salt            = 0
            }

    let init (redirection: string): Model * Cmd<Msg> =
        { Model.Empty with Redirection= redirection }, Cmd.none

    let registerCmd(api, data: RegistrationData) =
        let msg = async {
            let! salt = api.GetSalt data.Username
            let! res = api.Register { data with Password = Auth.hash (data.Password + salt.ToString()); Salt = salt }
            return ShowResult res
        }
        Cmd.OfAsync.either id msg id ShowError

    let update (api: IAuthApi) (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | UsernameChanged  usr -> { model with Username = usr }, Cmd.none
        | Password1Changed pwd -> { model with Password1 = pwd }, Cmd.none
        | Password2Changed pwd -> { model with Password2 = pwd }, Cmd.none
        | FirstNameChanged name -> { model with FirstName = name }, Cmd.none
        | LastNameChanged name -> { model with LastName = name }, Cmd.none
        | EmailChanged email -> { model with Email = email }, Cmd.none
        | TermsAcceptChanged acc -> { model with TermsAccepted = acc }, Cmd.none
        | Submit ->
            if model.TermsAccepted then
                let errors =                
                    model
                    |> Model.ToRegistrationData
                    |> validateRegistrationData
                    |> List.map (function ("Password", msg) -> "Password1", msg | x -> x)
                    |> List.append [ if model.Password1 <> model.Password2 then "Password2", "Hasła są niezgodne" ]
                if errors.IsEmpty then
                    model, registerCmd (api, model |> Model.ToRegistrationData)
                else
                    model |> Model.WithErrors errors, Cmd.none
            else
                model, Cmd.none
        | ShowError ex ->
            let message = sprintf "Podczas rejestracji nastąpił nieoczekiwany błąd: %s" ex.Message
            { model with ErrorMessage = message }, Cmd.none
        | ShowResult result ->
            match result with
            | RegistrationResult.Success _ ->
                { model with ViewType = ViewType.Summary }, Cmd.none
            | RegistrationResult.UsernameAlreadyUsed ->
                { model with UsernameErrors = [ "Nazwa użytkownika jest już zajęta" ] }, Cmd.none


    let showErrors errors =
        React.ofList
            [ for err in errors do
                span [ Class "help-block" ] [ str err ]     
            ]

    let hasError errors =
        if List.isEmpty errors then "" else " has-error"

    let view (model : Model) (dispatch : Msg -> unit) =
        match model.ViewType with
        | ViewType.Editor ->
            React.ofList [
                p [ Style [ TextAlign TextAlignOptions.Center ] ] [ str "Rejestracja nowego użytkownika" ]
                div [] [
                    div [ Class <| "form-group has-feedback" + hasError model.FirstNameErrors ] [
                        input [
                            Class "form-control"
                            Placeholder "Imię"
                            Value model.FirstName
                            OnChange (Event.str dispatch FirstNameChanged)
                            MaxLength 50.0
                        ]
                        span [ Class "glyphicon glyphicon-user form-control-feedback" ] []
                        showErrors model.FirstNameErrors
                    ]
                    div [ Class <| "form-group has-feedback" + hasError model.LastNameErrors ] [
                        input [
                            Class "form-control"
                            Placeholder "Nazwisko"
                            Value model.LastName
                            OnChange (Event.str dispatch LastNameChanged)
                            MaxLength 50.0
                        ]
                        span [ Class "glyphicon glyphicon-user form-control-feedback" ] []
                        showErrors model.LastNameErrors
                    ]
                    div [ Class <| "form-group has-feedback" + hasError model.UsernameErrors ] [
                        input [
                            Class "form-control"
                            Placeholder "Nazwa użytkownika"
                            Value model.Username
                            OnChange (Event.str dispatch UsernameChanged)
                            MaxLength 16.0
                        ]
                        span [ Class "glyphicon glyphicon-user form-control-feedback" ] []
                        showErrors model.UsernameErrors
                    ]
                    div [ Class <| "form-group has-feedback" + hasError model.EmailErrors ] [
                        input [
                            Class "form-control"
                            Placeholder "Email"
                            Value model.Email
                            OnChange (Event.str dispatch EmailChanged)
                            MaxLength 120.0
                        ]
                        span [ Class "glyphicon glyphicon-envelope form-control-feedback" ] []
                        showErrors model.EmailErrors
                    ]
                    div [ Class <| "form-group has-feedback" + hasError model.Password1Errors ] [
                        input [
                            Type "password"
                            Class "form-control"
                            Placeholder "Hasło"
                            Value model.Password1
                            OnChange (Event.str dispatch Password1Changed)
                            MaxLength 64.0
                        ]
                        span [ Class "glyphicon glyphicon-lock form-control-feedback" ] []
                        showErrors model.Password1Errors
                    ]
                    div [ Class <| "form-group has-feedback" + hasError model.Password2Errors ] [
                        input [
                            Type "password"
                            Class "form-control"
                            Placeholder "Powtórz hasło"
                            Value model.Password2
                            OnChange (Event.str dispatch Password2Changed)
                            MaxLength 64.0
                        ]
                        span [ Class "glyphicon glyphicon-log-in form-control-feedback" ] []
                        showErrors model.Password2Errors
                    ]
                    div [Class "form-group has-error"] [
                        if not <| String.IsNullOrEmpty model.ErrorMessage then
                            yield label [ Class "control-label" ] [ str model.ErrorMessage ]
                    ]
                    div [ Class "row" ] [
                        Controls.checkbox "termsAccepted" [] "Akceptuję regulamin serwisu" model.TermsAccepted dispatch TermsAcceptChanged
                        div [ Class "col-xs-4" ] [
                            div [ 
                                Class "btn btn-primary btn-block btn-flat"
                                Disabled (not model.TermsAccepted)
                                OnClick (Event.none dispatch Submit)
                            ] [ str "Zarejestruj" ]
                        ]
                    ]
                ]            
            ]
        | ViewType.Summary ->
            div [] [
                p [] [ str "Konto użytkownika zostało poprawnie zarejestrowane." ]
                p [] [ str "Sprawdź swoją pocztę. Na podany przez Ciebie adres email wysłaliśmy wiadomość z linkiem aktywacyjnym.
                            Konto zostanie aktywowane, gdy klikniesz ten link."
                ]
                p [] [
                    str "Kiedy konto będzie już aktywne, zaloguj się i uzupełnij swoje dane:"
                    ul [] [
                        li [] [ str "w zakładce 'Kontakt' wpisz swój numer telefonu" ]
                        li [] [ str "w zakładce 'Rachunek' wprowadź dane rachunku bankowego do rozliczeń z gośćmi" ]
                    ]
                ]
                div [Class "row" ] [
                    div [ Class "col-xs-6  col-xs-offset-3" ] [
                        div [ 
                            Class "btn btn-primary btn-block btn-flat"
                            OnClick (Event.none dispatch LogIn)
                        ] [ str "Przejdź do logowania" ]
                    ]
                ]
            ]

module Activation =

    type Submodel =
        | Result of SecretCodeActionResult
        | Error of string
        | Nothing

    type Model = string * Submodel

    type Msg =
        | ShowResult of SecretCodeActionResult
        | LogIn
        | ShowError of exn

    let activateCmd (api: IAuthApi, activationCode: Guid) =        
        Cmd.OfAsync.either id (api.Activate activationCode) ShowResult ShowError

    let init api (redirection: string, activationCode: Guid): Model * Cmd<Msg> =
        (redirection, Nothing), activateCmd(api, activationCode)

    let update (msg: Msg) (redirection: string, _: Submodel) : Model * Cmd<Msg> =
        match msg with
        | ShowResult result ->
            (redirection, Result result), Cmd.none
        | ShowError ex ->
            let message = sprintf "Podczas aktywacji nastąpił nieoczekiwany błąd: %s" ex.Message
            (redirection, Error message), Cmd.none

    let view (model : Model) (dispatch : Msg -> unit) =
        match model with
        | _, Result (SecretCodeActionResult.Success _) ->
            div [] [
                p [] [ str "Konto użytkownika jest teraz aktywne. Możesz się już zalogować." ]            
                div [Class "row" ] [
                    div [ Class "col-xs-6  col-xs-offset-3" ] [
                        div [ 
                            Class "btn btn-primary btn-block btn-flat"
                            OnClick (Event.none dispatch LogIn)
                        ] [ str "Przejdź do logowania" ]
                    ]
                ]
            ]
        | _, Result (SecretCodeActionResult.Expired _) ->
            div [] [ str "Link aktywacyjny wygasł. Zarejestruj się powtórnie." ]
        | _, Result (SecretCodeActionResult.Removed) ->
            div [] [
                str "Link aktywacyjny został usunięty."
                br []
                str "Zarejestruj się powtórnie."
            ]
        | _, Error message ->
            div [Class "form-group has-error"] [
                label [ Class "control-label" ] [ str message ]
            ]
        | _, Nothing ->
            div [] []

module ResetPassword =

    type Msg =
        | UsernameChanged of string
        | Submit
        | ShowResult of unit
        | ShowError of exn

    type ViewType =
        | Editor
        | Summary

    type Model =
        {
            Username        : string
            ViewType        : ViewType
            ErrorMessage    : string
        }
        static member Empty =
            { Username = ""; ViewType = ViewType.Editor; ErrorMessage = "" }

    let init(username: string): Model * Cmd<Msg> =
        { Model.Empty with Username = username }, Cmd.none

    let resetPasswordCmd (api: IAuthApi, username: string) =        
        Cmd.OfAsync.either id (api.ResetPassword username) ShowResult ShowError

    let update (api: IAuthApi) (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | UsernameChanged name ->
            { Model.Empty with Username = name }, Cmd.none
        | Submit ->
            model, resetPasswordCmd (api, model.Username)
        | ShowResult () ->
            { model with ViewType = ViewType.Summary }, Cmd.none
        | ShowError ex ->
             { model with ErrorMessage = sprintf "Podczas wysyłania linka do zmiany hasła wystąpił błąd %s" ex.Message }, Cmd.none

    let view (model : Model) (dispatch : Msg -> unit) =
        match model.ViewType with
        | Editor ->
            React.ofList [
                p [] [
                    str "Podaj swoją nazwę użytkownika.
                         Na jej podstawie wyślemy wiadomość zawierającą link do zmiany hasła na adres email,
                         podany przez Ciebie podczas rejestracji."
                ]
                div [] [
                    div [ Class <| "form-group has-feedback" ] [
                        input [
                            Type "username"
                            Class "form-control"
                            Placeholder "Użytkownik"
                            Value model.Username
                            OnChange (Event.str dispatch UsernameChanged)
                            MaxLength 16.0
                        ]
                        span [ Class "glyphicon glyphicon-user form-control-feedback" ] []
                    ]                    
                    div [Class "form-group has-error"] [
                        if not <| String.IsNullOrEmpty model.ErrorMessage then
                            yield label [ Class "control-label" ] [ str model.ErrorMessage ]
                    ]
                    div [ Class "row" ] [
                        div [ Class "col-xs-4" ] [
                            div [ 
                                Class "btn btn-primary btn-block btn-flat"
                                OnClick (Event.none dispatch Submit)
                            ] [ str "Wyślij" ]
                        ]
                    ]
                ]            
            ]
        | Summary ->
            div [] [
                str "Sprawdź swoją skrzynkę pocztową."
                br []
                str "Wysłaliśmy do Ciebie wiadomość z linkiem do zmiany hasła."
            ]

module UpdatePassword =

    type Msg =
        | Password1Changed of string
        | Password2Changed of string
        | Submit
        | LogIn
        | ShowResult of SecretCodeActionResult
        | ShowError of exn

    type Model =
        {
            Redirection     : string
            ResetCode       : Guid
            Password1       : string
            Password1Errors : string list
            Password2       : string
            Password2Errors : string list
            Result          : SecretCodeActionResult option
            ErrorMessage    : string
        }
        static member Empty =
            {
                Redirection     = ""
                ResetCode       = Guid.Empty;
                Password1       = "";
                Password1Errors = []
                Password2       = ""
                Password2Errors = []
                Result          = None
                ErrorMessage           = ""
            }
        member this.HasErrors = not this.Password1Errors.IsEmpty || not this.Password2Errors.IsEmpty

    let validate (model: Model) =
        { model with
            Password1Errors = checkPasswordStrength model.Password1 
            Password2Errors = [ if model.Password1 <> model.Password2 then "Hasła są niezgodne" ]            
        }

    let init(redirection: string, passwordResetCode: Guid): Model * Cmd<Msg> =
        { Model.Empty with Redirection = redirection; ResetCode = passwordResetCode }, Cmd.none

    let updatePasswordCmd (api: IAuthApi, password: string, resetCode: Guid) =
        let msg = async {
            let! salt = api.GetSaltByCode resetCode
            let! res = api.UpdatePassword (Auth.hash (password + string(salt |> Option.defaultValue 0)), resetCode)
            return ShowResult res
        }
        Cmd.OfAsync.either id msg id ShowError

    let update (api: IAuthApi) (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | Password1Changed pwd ->
            { model with Password1 = pwd }, Cmd.none
        | Password2Changed pwd ->
            { model with Password2 = pwd }, Cmd.none
        | Submit ->
            let vmodel = validate model
            if vmodel.HasErrors then
                vmodel, Cmd.none
            else
                model, updatePasswordCmd (api, model.Password1, model.ResetCode)
        | ShowResult result ->
            { model with Result = Some result }, Cmd.none
        | ShowError ex ->
            { model with ErrorMessage = ex.ToString() }, Cmd.none

    let showErrors errors =
        React.ofList
            [ for err in errors do
                span [ Class "help-block" ] [ str err ]     
            ]

    let hasError errors =
        if List.isEmpty errors then "" else " has-error"

    let view (model : Model) (dispatch : Msg -> unit) =
        match model.Result with
        | Some (SecretCodeActionResult.Success _) ->
            div [] [
                p [] [ str "Hasło zostało zmienione. Możesz się już zalogować." ]            
                div [Class "row" ] [
                    div [ Class "col-xs-6  col-xs-offset-3" ] [
                        div [ 
                            Class "btn btn-primary btn-block btn-flat"
                            OnClick (Event.none dispatch LogIn)
                        ] [ str "Przejdź do logowania" ]
                    ]
                ]
            ]
        | Some (SecretCodeActionResult.Expired _) ->
            div [] [ str "Link aktywacyjny wygasł. Poproś o ponowne wygenerowanie linku." ]
        | Some (SecretCodeActionResult.Removed) ->
            div [] [
                str "Link aktywacyjny został usunięty."
                br []
                str "Poproś o ponowne wygenerowanie linku."
            ]        
        | None ->
            React.ofList [
                p [ Style [ TextAlign TextAlignOptions.Center ] ] [ str "Zmiana hasła" ]
                div [] [
                    div [ Class <| "form-group has-feedback" + hasError model.Password1Errors ] [
                        input [
                            Type "password"
                            Class "form-control"
                            Placeholder "Hasło"
                            Value model.Password1
                            OnChange (Event.str dispatch Password1Changed)
                            MaxLength 64.0
                        ]
                        span [ Class "glyphicon glyphicon-lock form-control-feedback" ] []
                        showErrors model.Password1Errors
                    ]
                    div [ Class <| "form-group has-feedback" + hasError model.Password2Errors ] [
                        input [
                            Type "password"
                            Class "form-control"
                            Placeholder "Powtórz hasło"
                            Value model.Password2
                            OnChange (Event.str dispatch Password2Changed)
                            MaxLength 64.0
                        ]
                        span [ Class "glyphicon glyphicon-log-in form-control-feedback" ] []
                        showErrors model.Password2Errors
                    ]
                    div [Class "form-group has-error"] [
                        if not <| String.IsNullOrEmpty model.ErrorMessage then
                            yield label [ Class "control-label" ] [ str model.ErrorMessage ]
                    ]
                    div [ Class "row" ] [
                        div [ Class "col-xs-4" ] [
                            div [ 
                                Class "btn btn-primary btn-block btn-flat"
                                OnClick (Event.none dispatch Submit)
                            ] [ str "Wyślij" ]
                        ]
                    ]
                ]            
            ]


let ownerApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder (Route.builder2 "Owner")
    |> Remoting.buildProxy<IAuthApi>

let ordererApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder (Route.builder2 "Orderer")
    |> Remoting.buildProxy<IAuthApi>


type Msg =
    | LoginMsg of Login.Msg
    | RegistrationMsg of Registration.Msg
    | ActivationMsg of Activation.Msg
    | ResetPasswordMsg of ResetPassword.Msg
    | UpdatePasswordMsg of UpdatePassword.Msg

type Model =
    | LoginModel of Login.Model
    | RegistrationModel of Registration.Model
    | ActivationModel of Activation.Model
    | ResetPasswordModel of ResetPassword.Model
    | UpdatePasswordModel of UpdatePassword.Model

let init api (redirection: string, authParams: AuthParams option): Model * Cmd<Msg> =
    match authParams with
    | Some (AuthParams.Activation code) ->
        let act, msg = Activation.init api (redirection, code)
        ActivationModel act, Cmd.map ActivationMsg msg
    | Some (AuthParams.PasswordReset code) ->
        let mdl, msg = UpdatePassword.init (redirection, code)
        UpdatePasswordModel mdl, Cmd.map UpdatePasswordMsg msg
    | Some (AuthParams.FullReturnUrl url) ->
        let login, msg = Login.init url
        LoginModel login, Cmd.map LoginMsg msg
    | None ->
        let login, msg = Login.init redirection
        LoginModel login, Cmd.map LoginMsg msg

let ownerInit activationCode = init ownerApi ("owner-panel", activationCode)
let ordererInit activationCode = init ordererApi ("make-reservation", activationCode) // TODO: go to orderer panel

let update (api: IAuthApi) (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | LoginMsg Login.Register, LoginModel submodel ->
        let rmdl, rcmd = Registration.init submodel.Redirection
        RegistrationModel rmdl, Cmd.map RegistrationMsg rcmd
    | LoginMsg Login.PasswordReset, LoginModel submodel ->
        let rmdl, rcmd = ResetPassword.init submodel.Username
        ResetPasswordModel rmdl, Cmd.map ResetPasswordMsg rcmd
    | LoginMsg submsg, LoginModel submodel ->
        let model, cmd = Login.update api submsg submodel
        LoginModel model, Cmd.map LoginMsg cmd
    | RegistrationMsg Registration.LogIn, RegistrationModel submodel ->
        let lmdl, lcmd = Login.init submodel.Redirection
        LoginModel lmdl, Cmd.map LoginMsg lcmd
    | ActivationMsg Activation.LogIn, ActivationModel (redirection, _) ->
        let lmdl, lcmd = Login.init redirection
        LoginModel lmdl, Cmd.map LoginMsg lcmd
    | ActivationMsg msg, ActivationModel (redirection, model) ->
        let actmdl, actcmd = Activation.update msg (redirection, model)
        ActivationModel actmdl, Cmd.map ActivationMsg actcmd
    | UpdatePasswordMsg UpdatePassword.LogIn, UpdatePasswordModel model ->
        let lmdl, lcmd = Login.init model.Redirection
        LoginModel lmdl, Cmd.map LoginMsg lcmd
    | UpdatePasswordMsg msg, UpdatePasswordModel model ->
        let prmdl, prcmd = UpdatePassword.update api msg model
        UpdatePasswordModel prmdl, Cmd.map UpdatePasswordMsg prcmd
    | ResetPasswordMsg msg, ResetPasswordModel model ->
        let prmdl, prcmd = ResetPassword.update api msg model
        ResetPasswordModel prmdl, Cmd.map ResetPasswordMsg prcmd
    | RegistrationMsg submsg, RegistrationModel submodel ->
        let rmdl, rcmd = Registration.update api submsg submodel
        RegistrationModel rmdl, Cmd.map RegistrationMsg rcmd

let ownerUpdate = update ownerApi

let ordererUpdate = update ordererApi

let view (model : Model) (dispatch : Msg -> unit) =
    match model with
    | LoginModel submodel ->
        Login.view submodel (LoginMsg >> dispatch)
    | RegistrationModel submodel ->
        Registration.view submodel (RegistrationMsg >> dispatch)
    | ActivationModel submodel ->
        Activation.view submodel (ActivationMsg >> dispatch)
    | ResetPasswordModel submodel ->
        ResetPassword.view submodel (ResetPasswordMsg >> dispatch)
    | UpdatePasswordModel submodel ->
        UpdatePassword.view submodel (UpdatePasswordMsg >> dispatch)
