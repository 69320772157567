module Rooms

open System
open Elmish
open Fable.React
open Fable.React.Props
open Fable.Remoting.Client
open Commons
open Shared
open Shared.Rooms
open Shared.Calculations
open Shared.Resorts
open Fable.Core
open Shared.ServerError
open System.IO

let roomApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IRoomApi>

type ResortRef =
    {
        Id                  : int
        Name                : string
        HasPaymentMethod    : bool
    }
    static member FromResort (resort: Resort) =
        {
            Id                  = resort.Id
            Name                = resort.Name
            HasPaymentMethod    = resort.HasPaymentMethod
        }

module RoomDetails =

    module AgeGroupEditor = 

        type Msg =
            | RemoveAgeGroup of AgeGroupPricing
            | AddAgeGroup
            | AgeToChanged of int option
            | ShortTimePriceChanged of string
            | LongTimePriceChanged of string

        type EditedAgeGroupPricing =
            {
                AgeTo           : int
                ShortTimePrice  : Derived<string>
                LongTimePrice   : Derived<string>
                AvailableAges   : int list
            }
            static member Empty = {
                AgeTo           = 0
                ShortTimePrice  = Uninitialised
                LongTimePrice   = Uninitialised
                AvailableAges   = []
            }
            member this.IsComplete =
                this.AgeTo <> 0 &&
                (not this.ShortTimePrice.HasValue || Validation.isValidDecimal this.ShortTimePrice.Value) && 
                (not this.LongTimePrice.HasValue || Validation.isValidDecimal this.LongTimePrice.Value) 

        type Model =
            {
                Items   : AgeGroupPricing list
                Editor  : EditedAgeGroupPricing
            }
            static member Empty =
                {
                    Items   = []
                    Editor  = EditedAgeGroupPricing.Empty
                }
            static member FromAgeGroupPricingList ageGroups =
                {
                    Items   = ageGroups
                    Editor  = EditedAgeGroupPricing.Empty
                }
            static member UpdateAvailableAges (resort: Resort) (model: Model) =
                { model with
                    Editor =
                        { model.Editor with
                            AvailableAges =
                                resort.AgeGroups |> List.map (fun a -> a.AgeTo)
                                |> List.except (model.Items |> List.map (fun a -> a.AgeTo))
                        }
                }
            static member UpdateDerived (ageGroups: AgeGroupPricing list) (model: Model) =
                { model with
                    Items = model.Items |> List.map (fun ag ->
                        let dg = ageGroups |> List.find (fun dg -> dg.AgeTo = ag.AgeTo)
                        { ag with
                            ShortTimePrice = ag.ShortTimePrice |> Derived.Update dg.ShortTimePrice
                            LongTimePrice = ag.LongTimePrice |> Derived.Update dg.LongTimePrice
                        })
                }

        let ageLabel age =
            sprintf "Do %d %s" age (if age = 1 then "roku" else "lat")

        let view (model: Model) dispatch =
            table [ Class "table" ] [
                tbody [] [
                    tr [] [
                        th [ Style [ Width "30%" ] ] [ str "Wiek" ]
                        th [] [ str "Cena wynajmu krótkoterm."; br []; str " (od osoby)" ]
                        th [] [ str "Cena wynajmu długoterm."; br []; str " (od osoby)" ]
                        th [] []
                    ]
                    for p in model.Items do
                        tr [] [
                            td [] [ str <| ageLabel p.AgeTo ]
                            td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Derived.ToString p.ShortTimePrice ]
                            td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Derived.ToString p.LongTimePrice ]
                            td [] [ Controls.actionButton [] "fa fa-remove" dispatch (RemoveAgeGroup p) ]
                        ]
                    tr [] [                    
                        td [] [
                            select [
                                Class "form-control"
                                OnChange (Event.int dispatch AgeToChanged)
                                Value model.Editor.AgeTo
                            ] [
                                option [ Value 0 ] [ str "" ]
                                for age in model.Editor.AvailableAges do
                                    option [ Value age ] [ str <| ageLabel age ]
                            ]
                        ]
                        td [] [
                            input [
                                Class "form-control"
                                OnChange (Event.str dispatch (ShortTimePriceChanged))
                                Derived.ToTag id "" model.Editor.ShortTimePrice
                                MaxLength 8.0
                            ]
                        ]
                        td [] [
                            input [
                                Class "form-control"
                                OnChange (Event.str dispatch (LongTimePriceChanged))
                                Derived.ToTag id "" model.Editor.LongTimePrice
                                MaxLength 8.0
                            ]
                        ]
                        td [] [
                            Controls.actionButton [ Disabled (not model.Editor.IsComplete) ] "fa fa-plus" dispatch AddAgeGroup
                        ]
                    ]
                ]
            ]

        let update (msg: Msg) (model: Model) =
            match msg with
            | RemoveAgeGroup p ->
                { model with Items = model.Items |> List.filter ((<>) p) }, Cmd.none
            | AddAgeGroup ->
                if model.Editor.IsComplete then
                    { model with
                        Items = {
                            AgeTo           = model.Editor.AgeTo
                            ShortTimePrice  = model.Editor.ShortTimePrice |> Derived.ParseDecimal
                            LongTimePrice   = model.Editor.LongTimePrice |> Derived.ParseDecimal
                        } :: model.Items |> List.sortBy (fun a -> a.AgeTo)
                        Editor = EditedAgeGroupPricing.Empty
                    }, Cmd.none
                else
                    model, Cmd.none
            | AgeToChanged age ->
                { model with
                    Editor = { model.Editor with AgeTo = age |> Option.defaultValue 0 }
                }, Cmd.none
            | ShortTimePriceChanged stp ->
                { model with
                    Editor = { model.Editor with ShortTimePrice =  Derived.OfString stp }
                }, Cmd.none
            | LongTimePriceChanged ltp ->
                { model with
                    Editor = { model.Editor with LongTimePrice = Derived.OfString ltp }
                }, Cmd.none

    module ArrangementEditor = 

        type Msg =
            | RemoveArrangement of Arrangement
            | AddArrangement
            | ArrangementTypeChanged of int option
            | ExtraChargeChanged of string
            | PayTypeChanged of int option

        type EditedArrangement =
            {
                Type                        : ArrangementType option
                ExtraCharge                 : string
                PayType                     : PayType option
                AvailableArrangementTypes   : ArrangementType list
            }
            static member Empty = {
                Type                        = None
                ExtraCharge                 = ""
                PayType                     = None
                AvailableArrangementTypes   = []
            }
            member this.IsComplete = this.Type.IsSome && Validation.isValidDecimal this.ExtraCharge && this.PayType.IsSome

        type Model =
            {
                Resort  : Resort
                Items   : Arrangement list
                Editor  : EditedArrangement
            }
            static member Empty resort =
                {
                    Resort  = resort
                    Items   = []
                    Editor  = EditedArrangement.Empty
                }
            static member FromArrangementList resort arrangements =
                {
                    Resort  = resort
                    Items   = arrangements
                    Editor  = EditedArrangement.Empty
                }
            static member UpdateAvailableArrangementTypes (resort: Resort) (model: Model) =
                { model with
                    Editor =
                        { model.Editor with
                            AvailableArrangementTypes =
                                resort.ArrangementTypes
                                |> List.except (model.Items |> List.map (fun a -> a.Type))
                        }
                }

        let view (model: Model) dispatch =
            table [ Class "table" ] [
                tbody [] [
                    tr [] [
                        th [] [ str "Rodzaj" ]
                        th [ Style [ Width "20%" ] ] [ str "Dopłata (PLN)" ]
                        th [] []
                        th [] []
                    ]
                    for a in model.Items do
                        tr [] [
                            td [] [ str a.Type.Description ]
                            td [ Style [TextAlign TextAlignOptions.Right ] ] [ str <| Decimal.format 2 a.ExtraCharge ]
                            td [] [ str (match a.PayType with PayType.PerDay -> "dziennie" | PayType.Once -> "jednorazowo" | _ -> failwith "Unknown PayType") ]
                            td [] [ Controls.actionButton [] "fa fa-remove" dispatch (RemoveArrangement a)]
                        ]
                    tr [] [
                        td [] [
                            select [ Class "form-control"; OnChange (Event.int dispatch ArrangementTypeChanged) ] [
                                option [ Value 0 ] []
                                for at in model.Editor.AvailableArrangementTypes do
                                    option [ Value at.Id; Selected (Some at = model.Editor.Type) ] [ str at.Description ]
                            ]
                        ]
                        td [] [
                            input [
                                Class "form-control";
                                OnChange (Event.str dispatch ExtraChargeChanged);
                                Value model.Editor.ExtraCharge
                                MaxLength 8.0
                            ]
                        ]
                        td [] [
                            select [ Class "form-control"; OnChange (Event.int dispatch PayTypeChanged) ] [
                                option [ Value 0 ] []
                                option [ Value PayType.PerDay; Selected (model.Editor.PayType = Some PayType.PerDay) ] [ str "dziennie" ]
                                option [ Value PayType.Once; Selected (model.Editor.PayType = Some PayType.Once) ] [ str "jednorazowo" ]
                            ]
                        ]
                        td [] [
                            Controls.actionButton [ Disabled (not model.Editor.IsComplete) ] "fa fa-plus" dispatch AddArrangement
                        ]
                    ]
                ]
            ]

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | RemoveArrangement a ->
                { model with Items = model.Items |> List.filter ((<>) a) }
                |> Model.UpdateAvailableArrangementTypes model.Resort, Cmd.none
            | AddArrangement ->
                if model.Editor.IsComplete then
                    { model with
                        Items = model.Items @ [ {
                            Type = model.Editor.Type.Value
                            ExtraCharge = Decimal.Parse model.Editor.ExtraCharge
                            PayType = model.Editor.PayType.Value
                        } ]
                        Editor = EditedArrangement.Empty
                    }
                    |> Model.UpdateAvailableArrangementTypes model.Resort, Cmd.none
                else
                    model, Cmd.none
            | ArrangementTypeChanged idopt ->
                { model with
                    Editor =
                        { model.Editor with
                            Type = idopt |> Option.bind (fun id -> model.Resort.ArrangementTypes |> List.tryFind (fun at -> at.Id = id))
                        }
                }, Cmd.none
            | PayTypeChanged ptopt ->
                { model with
                    Editor =
                        { model.Editor with
                            PayType = ptopt |> Option.map LanguagePrimitives.EnumOfValue<int, PayType> 
                        }
                }, Cmd.none
            | ExtraChargeChanged ec ->
                { model with Editor = { model.Editor with ExtraCharge = ec } }, Cmd.none


    module UnavailabilityEditor = 

        type Msg =
            | RemoveUnavailability of Unavailability
            | AddUnavailability
            | UnavailabilityPeriodChanged of DateTime * DateTime
            | UnavailabilityNotesChanged of string

        type EditedUnavailability =
            {
                From    : DateTime
                To      : DateTime
                Notes   : string
            }
            static member Empty = { From = DateTime.Today; To = DateTime.Today; Notes = "" }

        type Model =
            {
                Items   : Unavailability list
                Editor  : EditedUnavailability
            }
            static member Empty =
                {
                    Items   = []
                    Editor  = EditedUnavailability.Empty
                }
            static member FromUnavailabilityList unavailabilities =
                {
                    Items   = unavailabilities
                    Editor  = EditedUnavailability.Empty
                }

        let view (model: Model) dispatch =
            table [ Class "table" ] [
                tbody [] [
                    tr [] [
                        th [] [ str "Od" ]
                        th [] [ str "Do" ]
                        th [] [ str "Opis" ]
                        th [] []
                    ]
                    for u in model.Items do
                        tr [] [
                            td [] [ str <| u.From.ToString("dd.MM.yyyy") ]
                            td [] [ str <| u.To.ToString("dd.MM.yyyy") ]
                            td [] [ str (u.Notes |> Option.defaultValue "") ]
                            td [] [ Controls.actionButton [] "fa fa-remove" dispatch (RemoveUnavailability u) ]
                        ]
                    tr [] [
                        td [ ColSpan 2 ] [
                            Controls.dateRangePickerInput "addUnavailability" (model.Editor.From, model.Editor.To) dispatch UnavailabilityPeriodChanged
                        ]
                        td [] [
                            input [
                                Class "form-control"
                                OnChange (Event.str dispatch UnavailabilityNotesChanged)
                                Value (model.Editor.Notes)
                                MaxLength 120.0
                            ]
                        ]
                        td [] [ Controls.actionButton [] "fa fa-plus" dispatch AddUnavailability ]
                    ]
                ]
            ]

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | RemoveUnavailability u ->
                { model with Items = model.Items |> List.filter ((<>) u) }, Cmd.none
            | AddUnavailability ->
                { model with
                    Items = {
                        Unavailability.From = model.Editor.From
                        To = model.Editor.To
                        Notes = match model.Editor.Notes with
                                | "" -> None
                                | s -> Some s
                    } :: model.Items |> List.sort
                    Editor = EditedUnavailability.Empty
                }, Cmd.none
            | UnavailabilityPeriodChanged (f, t) ->
                { model with Editor = { model.Editor with From = f; To = t } }, Cmd.none
            | UnavailabilityNotesChanged notes ->
                { model with Editor = { model.Editor with Notes = notes } }, Cmd.none


    module ArrangementVariantEditor = 

        type Msg =
            | RemoveArrangementVariant of ArrangementVariant
            | AddArrangementVariant
            | AvailableUnitsChanged of ArrangementType * string

        type EditedArrangementVariant =
            {
                No              : int
                Availabilities  : (ArrangementType * string) list
            }
            static member Empty =
                {
                    No              = 0
                    Availabilities  = []
                }
            static member Update (tp, units) (variant: EditedArrangementVariant) =
                { variant with
                    Availabilities = (tp, units) :: (variant.Availabilities |> List.filter (fst >> (<>) tp))
                }

        type Model =
            {                
                Items           : ArrangementVariant list
                Editor          : EditedArrangementVariant
                AvailableTypes  : ArrangementType list
            }
            static member Empty =
                {
                    Items           = []
                    Editor          = EditedArrangementVariant.Empty
                    AvailableTypes  = []
                }
            static member FromArrangementVariantList variants =
                {
                    Items           = variants
                    Editor          = EditedArrangementVariant.Empty
                    AvailableTypes  = []
                }
            static member NextArrangementVariantNo (model: Model) =
                match model.Items with
                | [] -> 1
                | l -> (l |> List.map (fun v -> v.No) |> List.max) + 1
            static member UpdateAvailableTypes types model =
                { model with AvailableTypes = types }
    

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | RemoveArrangementVariant av ->
                { model with
                    Items = model.Items |> List.filter ((<>) av)
                }, Cmd.none
            | AddArrangementVariant ->
                { model with
                    Items = model.Items @ [ {
                        No = model |> Model.NextArrangementVariantNo
                        Availabilities =
                            model.Editor.Availabilities
                            |> List.filter (snd >> String.IsNullOrEmpty >> not)
                            |> List.map (fun (t, u) -> { Type = t; AvailableUnits = Int32.Parse u })
                    }]
                    Editor = EditedArrangementVariant.Empty
                }, Cmd.none
            | AvailableUnitsChanged (t, u) ->
                { model with
                    Editor = model.Editor |> EditedArrangementVariant.Update (t, u)
                }, Cmd.none

        let view (model: Model) dispatch =
            table [ Class "table" ] [
                tbody [] [
                    tr [] [
                        th [] [ str "Nr" ]
                        for at in model.AvailableTypes do
                            th [] [ str at.Description ]
                        th [] []
                    ]
                    for v in model.Items do
                        tr [] [
                            td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| string v.No ]
                            for at in model.AvailableTypes do
                                match v.Availabilities |> List.tryFind (fun aa -> aa.Type = at) with
                                | Some aa -> td [ Style [TextAlign TextAlignOptions.Right ] ] [ str <| string aa.AvailableUnits ]
                                | None -> td [ Style [TextAlign TextAlignOptions.Center ] ] [ str "---" ]
                            td [] [ Controls.actionButton [] "fa fa-remove" dispatch (RemoveArrangementVariant v) ]
                        ]
                    tr [] [
                        td [ Style [TextAlign TextAlignOptions.Right ] ] [ str <| string (model |> Model.NextArrangementVariantNo) ]
                        for at in model.AvailableTypes do
                            td [] [
                                input [
                                    Class "form-control";
                                    OnChange (Event.str dispatch (fun units -> AvailableUnitsChanged (at, units)));
                                    Value (model.Editor.Availabilities |> List.tryFind (fst >> (=) at) |> Option.map snd |> Option.defaultValue "")
                                    MaxLength 3.0
                                ]
                            ]
                        td [] [ Controls.actionButton [] "fa fa-plus" dispatch AddArrangementVariant ]
                    ]
                ]
            ]

    module PriceListEditor = 

        type Msg =
            | ShortTimePriceChanged of string
            | LongTimePriceChanged of string
            | ShortEmptySpacePriceChanged of string
            | LongEmptySpacePriceChanged of string
            | AgeGroupMsg of AgeGroupEditor.Msg

        type Model =
            {
                TemplateId                  : int
                Name                        : string
                ShortTimePrice              : Derived<string>
                ShortTimePriceErrors        : string list
                LongTimePrice               : Derived<string>
                LongTimePriceErrors         : string list
                ShortEmptySpacePrice        : Derived<string>
                ShortEmptySpacePriceErrors  : string list
                LongEmptySpacePrice         : Derived<string>
                LongEmptySpacePriceErrors   : string list
                AgeGroups                   : AgeGroupEditor.Model
                AgeGroupErrors              : string list
            }

            static member Empty =
                {
                    TemplateId                  = 0
                    Name                        = ""
                    ShortTimePrice              = Calculated ""
                    ShortTimePriceErrors        = []
                    LongTimePrice               = Calculated ""
                    LongTimePriceErrors         = []
                    ShortEmptySpacePrice        = Calculated ""
                    ShortEmptySpacePriceErrors  = []
                    LongEmptySpacePrice         = Calculated ""
                    LongEmptySpacePriceErrors   = []
                    AgeGroups                   = AgeGroupEditor.Model.Empty
                    AgeGroupErrors              = []
                }

            static member FromRoom (room: Room) =
                {
                    TemplateId                  = 0
                    Name                        = "Cennik podstawowy"
                    ShortTimePrice              = room.ShortTimePrice |> Derived.Format 
                    ShortTimePriceErrors        = []
                    LongTimePrice               = room.LongTimePrice |> Derived.Format 
                    LongTimePriceErrors         = []
                    ShortEmptySpacePrice        = room.ShortEmptySpacePrice |> Derived.Format
                    ShortEmptySpacePriceErrors  = []
                    LongEmptySpacePrice         = room.LongEmptySpacePrice |> Derived.Format
                    LongEmptySpacePriceErrors   = []
                    AgeGroups                   = AgeGroupEditor.Model.FromAgeGroupPricingList room.AgeGroupPricings
                    AgeGroupErrors              = []
                }

            static member FromPriceList (priceList: PriceList, name: string) =
                {
                    TemplateId                  = priceList.TemplateId
                    Name                        = name
                    ShortTimePrice              = priceList.ShortTimePrice |> Derived.Format
                    ShortTimePriceErrors        = []
                    LongTimePrice               = priceList.LongTimePrice |> Derived.Format
                    LongTimePriceErrors         = []
                    ShortEmptySpacePrice        = priceList.ShortEmptySpacePrice |> Derived.Format
                    ShortEmptySpacePriceErrors  = []
                    LongEmptySpacePrice         = priceList.LongEmptySpacePrice |> Derived.Format
                    LongEmptySpacePriceErrors   = []
                    AgeGroups                   = AgeGroupEditor.Model.FromAgeGroupPricingList priceList.AgeGroupPricings
                    AgeGroupErrors              = []
                }

            static member ToRoom (model: Model) (room: Room) =
                {
                    room with
                        ShortTimePrice          = model.ShortTimePrice |> Derived.ParseDecimal |> Derived.DefaultValue 0M
                        LongTimePrice           = model.LongTimePrice |> Derived.ParseDecimal |> Derived.DefaultValue 0M
                        ShortEmptySpacePrice    = model.ShortEmptySpacePrice |> Derived.ParseDecimal
                        LongEmptySpacePrice     = model.LongEmptySpacePrice |> Derived.ParseDecimal
                        AgeGroupPricings        = model.AgeGroups.Items
                }

            static member ToRoomCalculationData (model: Model) (calcData: RoomCalculationData) =
                {
                    calcData with
                        ShortTimePrice          = model.ShortTimePrice |> Derived.ParseDecimal 
                        LongTimePrice           = model.LongTimePrice |> Derived.ParseDecimal
                        ShortEmptySpacePrice    = model.ShortEmptySpacePrice |> Derived.ParseDecimal
                        LongEmptySpacePrice     = model.LongEmptySpacePrice |> Derived.ParseDecimal
                        AgeGroupPricings        = model.AgeGroups.Items
                }

            static member ToPriceList (model: Model) =
                {
                    PriceList.TemplateId    = model.TemplateId
                    ShortTimePrice          = model.ShortTimePrice |> Derived.ParseDecimal
                    LongTimePrice           = model.LongTimePrice |> Derived.ParseDecimal
                    ShortEmptySpacePrice    = model.ShortEmptySpacePrice |> Derived.ParseDecimal
                    LongEmptySpacePrice     = model.LongEmptySpacePrice |> Derived.ParseDecimal
                    AgeGroupPricings        = model.AgeGroups.Items
                }

            static member FromRoomCalculationData (room: RoomCalculationData) (model: Model) =
                { model with
                    ShortEmptySpacePrice = model.ShortEmptySpacePrice |> Derived.Update (room.ShortEmptySpacePrice |> Derived.Format)
                    LongEmptySpacePrice = model.LongEmptySpacePrice |> Derived.Update (room.LongEmptySpacePrice |> Derived.Format)
                    AgeGroups = model.AgeGroups |> AgeGroupEditor.Model.UpdateDerived room.AgeGroupPricings
                }

            static member UpdateDerived (pl: PriceList) (model: Model) =
                { model with
                    ShortTimePrice = model.ShortTimePrice |> Derived.Update (pl.ShortTimePrice |> Derived.Format)
                    LongTimePrice = model.LongTimePrice |> Derived.Update (pl.LongTimePrice |> Derived.Format)
                    ShortEmptySpacePrice = model.ShortEmptySpacePrice |> Derived.Update (pl.ShortEmptySpacePrice |> Derived.Format)
                    LongEmptySpacePrice = model.LongEmptySpacePrice |> Derived.Update (pl.LongEmptySpacePrice |> Derived.Format)
                    AgeGroups = model.AgeGroups |> AgeGroupEditor.Model.UpdateDerived pl.AgeGroupPricings
                }

            static member WithErrors (errors: (string * string) list) (model: Model) =
                let getErrorsOf name =
                    let postfix = if model.TemplateId = 0 then "" else model.TemplateId.ToString()
                    errors |> Validation.getErrorsOf (name + postfix)
                { model with
                    ShortTimePriceErrors        = getErrorsOf "ShortTimePrice"
                    LongTimePriceErrors         = getErrorsOf "LongTimePrice"
                    LongEmptySpacePriceErrors   = getErrorsOf "EmptySpacePrice"
                    AgeGroupErrors              = getErrorsOf "AgeGroupPricings"
                }

            member this.HasErrors =
                not (this.ShortTimePriceErrors.IsEmpty &&
                     this.LongTimePriceErrors.IsEmpty &&
                     this.LongEmptySpacePriceErrors.IsEmpty &&
                     this.AgeGroupErrors.IsEmpty)

            static member UpdateAvailableAges resort (model: Model) =
                { model with AgeGroups = model.AgeGroups |> AgeGroupEditor.Model.UpdateAvailableAges resort }
            
        let view model dispatch =
            div [
                Class <| sprintf "tab-pane %s" (if model.TemplateId = 0 then "active" else "")
                Id <| sprintf "priceList%d" model.TemplateId
                ] [

                Forms.formGroup (not model.ShortTimePriceErrors.IsEmpty)
                <| Forms.inputWithLabel "col-sm-3" "col-sm-2" (sprintf "shortTimePrice%d" model.TemplateId) "Cena wynajmu krótkoterm. (od osoby)" 8.0
                    (Derived.ToTag id "" model.ShortTimePrice)
                    (Forms.errorsRight "col-sm-4" model.ShortTimePriceErrors)
                    (Event.str dispatch ShortTimePriceChanged)

                Forms.formGroup (not model.LongTimePriceErrors.IsEmpty)
                <| Forms.inputWithLabel "col-sm-3" "col-sm-2" (sprintf "longTimePrice%d" model.TemplateId) "Cena wynajmu długoterm. (od osoby)" 8.0
                    (Derived.ToTag id "" model.LongTimePrice)
                    (Forms.errorsRight "col-sm-4" model.LongTimePriceErrors)
                    (Event.str dispatch LongTimePriceChanged)

                Forms.formGroup (not model.ShortEmptySpacePriceErrors.IsEmpty)
                <| Forms.inputWithLabel "col-sm-3" "col-sm-2" (sprintf "shortEmptySpacePrice%d" model.TemplateId) "Cena niewykorzystanego miejsca krótkoterm." 8.0
                    (Derived.ToTag id "" model.ShortEmptySpacePrice)
                    (Forms.errorsRight "col-sm-4" model.ShortEmptySpacePriceErrors)
                    (Event.str dispatch ShortEmptySpacePriceChanged)
    
                Forms.formGroup (not model.LongEmptySpacePriceErrors.IsEmpty)
                <| Forms.inputWithLabel "col-sm-3" "col-sm-2" (sprintf "longEmptySpacePrice%d" model.TemplateId) "Cena niewykorzystanego miejsca długoterm." 8.0
                    (Derived.ToTag id "" model.LongEmptySpacePrice)
                    (Forms.errorsRight "col-sm-4" model.LongEmptySpacePriceErrors)
                    (Event.str dispatch LongEmptySpacePriceChanged)

                Forms.formGroup (not model.AgeGroupErrors.IsEmpty)
                <| Forms.controlWithLabel "col-sm-3" "col-sm-5" (sprintf "ageGroupPricings%d" model.TemplateId) "Grupy wiekowe"
                    (Forms.errorsBelow model.AgeGroupErrors)
                    (AgeGroupEditor.view model.AgeGroups (AgeGroupMsg >> dispatch))
            ]

        let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
            match msg with
            | ShortTimePriceChanged stp->
                { model with ShortTimePrice = stp |> Derived.OfString }, Cmd.none
            | LongTimePriceChanged ltp ->
                { model with LongTimePrice = ltp |> Derived.OfString }, Cmd.none
            | ShortEmptySpacePriceChanged sesp ->
                { model with ShortEmptySpacePrice = sesp |> Derived.OfString }, Cmd.none    
            | LongEmptySpacePriceChanged lesp ->
                { model with LongEmptySpacePrice = lesp |> Derived.OfString }, Cmd.none      
            | AgeGroupMsg msg ->
                let agMdl, agCmd = AgeGroupEditor.update msg model.AgeGroups            
                { model with AgeGroups = agMdl }, Cmd.map AgeGroupMsg agCmd


    type Msg =
        | ResortDetailsLoaded of Resort * RoomType list
        | RoomDetailsLoaded of Room * Resort * RoomType list
        | RoomTypeChanged of string
        | NameChanged of string
        | ShortDescriptionChanged of string
        | DetailedDescriptionChanged of string
        | CapacityChanged of string
        | AdditionalCapacityChanged of string
        | PrepaymentPercentageChanged of string
        | PriceListMsg of int * PriceListEditor.Msg
        | VolumeChanged of string        
        | ArrangementMsg of ArrangementEditor.Msg
        | ArrangementVariantMsg  of ArrangementVariantEditor.Msg
        | UnavailabilityMsg of UnavailabilityEditor.Msg
        | ValidateReservations
        | ReservationsValidated of (ReservationRef * string list) list
        | Save
        | Close
        | ShowError of string * ErrorInfo
    
    type Model =
        {
            Id                          : int
            Resort                      : Resort
            Name                        : string
            NameErrors                  : string list
            ShortDescription            : string
            ShortDescriptionErrors      : string list
            DetailedDescription         : string
            DetailedDescriptionErrors   : string list
            Capacity                    : string
            CapacityErrors              : string list
            AdditionalCapacity          : string
            AdditionalCapacityErrors    : string list
            PrepaymentPercentage        : Derived<string>
            PrepaymentPercentageErrors  : string list
            Volume                      : string
            VolumeErrors                : string list
            Arrangements                : ArrangementEditor.Model
            ArrangementErrors           : string list
            ArrangementVariants         : ArrangementVariantEditor.Model
            ArrangementVariantErrors    : string list
            Unavailabilities            : UnavailabilityEditor.Model
            UnavailabilityErrors        : string list
            PriceLists                  : PriceListEditor.Model list
            ReferenceLink               : string
            ReservationValidation       : (ReservationRef * string list) list
            Occupancies                 : Occupancy list
            RoomType                    : RoomType
            RoomTypes                   : RoomType list
        }

        static member Empty =
            {
                Id                          = 0
                Resort                      = Resort.Empty
                Name                        = ""
                NameErrors                  = []
                ShortDescription            = ""
                
                ShortDescriptionErrors      = []
                DetailedDescription         = ""
                DetailedDescriptionErrors   = []
                Capacity                    = ""
                CapacityErrors              = []
                AdditionalCapacity          = ""
                AdditionalCapacityErrors    = []
                PrepaymentPercentage        = Uninitialised
                PrepaymentPercentageErrors  = []
                Volume                      = ""
                VolumeErrors                = []
                Arrangements                = ArrangementEditor.Model.Empty Resort.Empty
                ArrangementErrors           = []
                ArrangementVariants         = ArrangementVariantEditor.Model.Empty
                ArrangementVariantErrors    = []
                Unavailabilities            = UnavailabilityEditor.Model.Empty
                UnavailabilityErrors        = []
                PriceLists                  = [ PriceListEditor.Model.Empty ]
                ReferenceLink               = ""
                ReservationValidation       = []
                Occupancies                 = []
                RoomType                    = RoomType.Empty
                RoomTypes                   = []
            }

        static member BuildPriceListModels (templates: PriceListTemplate list) (room: Room) =
            PriceListEditor.Model.FromRoom room
            ::
            (room.PriceLists
            |> List.map (Model.CreatePriceListModel templates))

        static member FromRoom (resort: Resort) (room: Room) (roomTypes: RoomType list): Model =
            {
                Id                          = room.Id
                Resort                      = resort
                Name                        = room.Name
                NameErrors                  = []
                ShortDescription            = room.ShortDescription                
                ShortDescriptionErrors      = []
                DetailedDescription         = room.DetailedDescription
                DetailedDescriptionErrors   = []
                Capacity                    = room.Capacity.ToString()
                CapacityErrors              = []
                AdditionalCapacity          = room.AdditionalCapacity.ToString()
                AdditionalCapacityErrors    = []
                PrepaymentPercentage        = room.PrepaymentPercentage |> Derived.Map string
                PrepaymentPercentageErrors  = []
                Volume                      = room.Volume.ToString()
                VolumeErrors                = []
                Arrangements                = ArrangementEditor.Model.FromArrangementList resort room.Arrangements
                ArrangementErrors           = []
                ArrangementVariants         = ArrangementVariantEditor.Model.FromArrangementVariantList room.ArrangementVariants
                ArrangementVariantErrors    = []
                Unavailabilities            = UnavailabilityEditor.Model.FromUnavailabilityList room.Unavailabilities
                UnavailabilityErrors        = []
                PriceLists                  = Model.BuildPriceListModels resort.PriceListTemplates room
                ReferenceLink               = if room.Id <> 0 then
                                                sprintf "https://%s/make-reservation?room=%d" Browser.Dom.window.location.host room.Id
                                              else
                                                ""
                ReservationValidation       = []
                Occupancies                 = room.Occupancies
                RoomType                    = room.RoomType
                RoomTypes                   = roomTypes
            }
            |> Model.UpdateAvailableArrangementTypes
            |> Model.UpdateAvailableAges

        static member ToRoom (model: Model): Room =
            {
                Id                      = model.Id
                ResortId                = model.Resort.Id
                Name                    = model.Name
                ShortDescription        = model.ShortDescription
                DetailedDescription     = model.DetailedDescription
                Capacity                = Int32.Parse model.Capacity
                AdditionalCapacity      = Int32.Parse model.AdditionalCapacity
                ShortTimePrice          = 0M
                LongTimePrice           = 0M
                ShortEmptySpacePrice    = Uninitialised
                LongEmptySpacePrice     = Uninitialised
                PrepaymentPercentage    = model.PrepaymentPercentage |> Derived.ParseInt
                Volume                  = Int32.Parse model.Volume
                AgeGroupPricings        = []
                Arrangements            = model.Arrangements.Items
                ArrangementVariants     = model.ArrangementVariants.Items
                Unavailabilities        = model.Unavailabilities.Items
                PriceLists              = model.PriceLists.Tail |> List.map PriceListEditor.Model.ToPriceList
                Occupancies             = model.Occupancies
                RoomType                = model.RoomType

            }
            |> PriceListEditor.Model.ToRoom model.PriceLists.Head

        static member ToCalculationData (model: Model): RoomCalculationData =
            {
                ShortTimePrice          = Uninitialised
                LongTimePrice           = Uninitialised
                ShortEmptySpacePrice    = Uninitialised
                LongEmptySpacePrice     = Uninitialised
                PrepaymentPercentage    = model.PrepaymentPercentage |> Derived.ParseInt
                AgeGroupPricings        = []
                PriceLists              = model.PriceLists.Tail |> List.map PriceListEditor.Model.ToPriceList
            }
            |> PriceListEditor.Model.ToRoomCalculationData model.PriceLists.Head

        static member FromCalculationData (calcData: RoomCalculationData) (model: Model) =
            { model with
                PrepaymentPercentage =
                    model.PrepaymentPercentage |> Derived.Update (calcData.PrepaymentPercentage |> Derived.Map string)
                PriceLists =
                    (model.PriceLists
                     |> List.head
                     |> PriceListEditor.Model.FromRoomCalculationData calcData)
                    ::
                    (model.PriceLists
                     |> List.tail
                     |> List.map (fun plm ->
                        let pl = calcData.PriceLists |> List.find (fun pl -> pl.TemplateId = plm.TemplateId)
                        PriceListEditor.Model.UpdateDerived pl plm))
            }

        static member Validate (model: Model) =
            [
                yield! Validation.checkIfValidInt "Max. liczba osób" model.Capacity |> Validation.forField "Capacity"
                yield! Validation.checkIfDerivedValidInt "Zaliczka" model.PrepaymentPercentage |> Validation.forField "PrepaymentPercentage"
                yield! Validation.checkIfValidInt "Liczba lokali" model.Volume |> Validation.forField "Volume"
            ]

        static member WithErrors (errors: (string * string) list) (model: Model) =
            {
                model with
                    NameErrors                  = errors |> Validation.getErrorsOf "Name"
                    ShortDescriptionErrors      = errors |> Validation.getErrorsOf "ShortDescription"
                    DetailedDescriptionErrors   = errors |> Validation.getErrorsOf "DetailedDescription"
                    CapacityErrors              = errors |> Validation.getErrorsOf "Capacity"
                    AdditionalCapacityErrors    = errors |> Validation.getErrorsOf "AdditionalCapacity"
                    PrepaymentPercentageErrors  = errors |> Validation.getErrorsOf "PrepaymentPercentage"
                    VolumeErrors                = errors |> Validation.getErrorsOf "Volume"
                    ArrangementErrors           = errors |> Validation.getErrorsOf "Arrangements"
                    ArrangementVariantErrors    = errors |> Validation.getErrorsOf "ArrangementVariants"
                    UnavailabilityErrors        = errors |> Validation.getErrorsOf "Unavailabilities"
                    PriceLists                  = model.PriceLists |> List.map (PriceListEditor.Model.WithErrors errors)
            }

        static member Recalc (model: Model) =
            model |> Model.FromCalculationData (model |> Model.ToCalculationData |> calculateRoom model.Resort)

        static member  CreatePriceListModel templates pl =
            PriceListEditor.Model.FromPriceList(pl, (templates |> List.find (fun pt -> pt.Id = pl.TemplateId)).Name)

        static member AddInitialPriceLists (model: Model) =
            { model with
                PriceLists =
                    { PriceListEditor.Model.Empty with
                        Name = "Cennik podstawowy"
                        AgeGroups = AgeGroupEditor.Model.Empty |> AgeGroupEditor.Model.UpdateAvailableAges model.Resort
                    }   
                    ::
                    (createInitialPriceLists model.Resort |> List.map (Model.CreatePriceListModel model.Resort.PriceListTemplates))                 
            }

        static member UpdateAvailableAges (model: Model) =
            { model with                
                PriceLists = model.PriceLists |> List.map (PriceListEditor.Model.UpdateAvailableAges model.Resort)
            }

        static member UpdateAvailableArrangementTypes (model: Model) =
            { model with                
                Arrangements =
                    model.Arrangements
                    |> ArrangementEditor.Model.UpdateAvailableArrangementTypes model.Resort
                ArrangementVariants =
                    model.ArrangementVariants
                    |> ArrangementVariantEditor.Model.UpdateAvailableTypes (model.Arrangements.Items |> List.map (fun a -> a.Type))
            }

    let priceListEditorPane(models: PriceListEditor.Model list) dispatch =
        div [ Class "nav-tabs-custom" ] [
            ul [Class "nav nav-tabs"] [
                for pl in models do
                    li [ Class (if pl.TemplateId = 0 then "active" else "") ] [                        
                        a [
                            Href <| sprintf "#priceList%d" pl.TemplateId;
                            Data("toggle", "tab"); AriaExpanded(pl.TemplateId = 0)
                        ] [
                            b [ if pl.HasErrors then Style [ Color "red" ] ] [
                                str (pl.Name + " ")
                                if pl.HasErrors then i [Class "fa fa-times-circle-o" ] []
                            ]                            
                        ]                        
                    ]
            ]
            div [ Class "tab-content" ] [
                for pl in models do
                    PriceListEditor.view pl (fun msg -> dispatch (PriceListMsg (pl.TemplateId, msg)))                    
            ]
        ]
        

    let view (model : Model) (dispatch : Msg -> unit) =
        
        JS.console.error(sprintf "ROOMTYPES: %A" (model.RoomTypes |> List.map (fun rt -> rt.Id, rt.SingularNominative, rt = model.RoomType)))

        div [ Class "box box-info" ] [
            div [ Class "box-header with-border" ] [
                h3 [ Class "box-title" ] [ str "Dane pokoju" ]
            ]
            form [ Class "form-horizontal"] [
                div [ Class "box-body" ] [

                    input [ Hidden true; Id "prevent-subscriptions" ]

                    Forms.formGroup false
                    <| Forms.selectWithLabel "col-sm-3" "col-sm-9" "roomType" "Typ"
                        (model.RoomTypes |> List.map (fun rt -> rt.Id, rt.SingularNominative))                                               
                        model.RoomType.Id
                        (Forms.errorsBelow [])
                        (Event.str dispatch RoomTypeChanged)

                    Forms.formGroup (not model.NameErrors.IsEmpty)
                    <| Forms.inputWithLabel "col-sm-3" "col-sm-9" "name" "Nazwa" 150.0                                               
                        (Value model.Name)
                        (Forms.errorsBelow model.NameErrors)
                        (Event.str dispatch NameChanged)

                    Forms.formGroup (not model.ShortDescriptionErrors.IsEmpty)
                    <| Forms.textareaWithLabel "col-sm-3" "col-sm-9" 3 "shortDescription" "Opis skrócony" 512.0
                        model.ShortDescription
                        (Forms.errorsBelow model.ShortDescriptionErrors)
                        (Event.str dispatch ShortDescriptionChanged)

                    Forms.formGroup (not model.DetailedDescriptionErrors.IsEmpty)
                    <| Forms.controlWithLabel "col-sm-3" "col-sm-9" "detailedDescription" "Opis szczegółowy"
                        (Forms.errorsBelow model.DetailedDescriptionErrors)
                        (Controls.htmlEditor "detailedDescription" 10 model.DetailedDescription (DetailedDescriptionChanged >> dispatch))

                    Forms.formGroup (not model.CapacityErrors.IsEmpty)
                    <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "capacity" "Max. liczba osób" 3.0
                        (Value model.Capacity)
                        (Forms.errorsRight "col-sm-4" model.CapacityErrors)
                        (Event.str dispatch CapacityChanged)

                    Forms.formGroup (not model.AdditionalCapacityErrors.IsEmpty)
                    <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "additionalCapacity" "Dodatkowe miejsce" 3.0
                        (Value model.AdditionalCapacity)
                        (Forms.errorsRight "col-sm-4" model.AdditionalCapacityErrors)
                        (Event.str dispatch AdditionalCapacityChanged)

                    Forms.formGroup (not model.PrepaymentPercentageErrors.IsEmpty)
                    <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "prepaymentPercentage" "Procent zaliczki" 3.0
                        (Derived.ToTag id "" model.PrepaymentPercentage)
                        (Forms.errorsRight "col-sm-4" model.PrepaymentPercentageErrors)
                        (Event.str dispatch PrepaymentPercentageChanged)

                    Forms.formGroup (not model.VolumeErrors.IsEmpty)
                    <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "volume" "Liczba lokali" 3.0
                        (Value model.Volume)
                        (Forms.errorsRight "col-sm-4" model.VolumeErrors)
                        (Event.str dispatch VolumeChanged)

                    br []
                    priceListEditorPane model.PriceLists dispatch

                    Forms.formGroup (not model.ArrangementErrors.IsEmpty)
                    <| Forms.controlWithLabel "col-sm-3" "col-sm-6" "arrangements" "Dodatkowe wyposażenie"
                        (Forms.errorsBelow model.ArrangementErrors)
                        (ArrangementEditor.view model.Arrangements (ArrangementMsg >> dispatch))

                    Forms.formGroup (not model.ArrangementErrors.IsEmpty)
                    <| Forms.controlWithLabel
                        "col-sm-3"
                        (sprintf "col-sm-%d" (Math.Min(model.Arrangements.Items.Length + 3, 9)))
                        "arrangementVariants"
                        "Warianty dodatkowego wyposażenia"
                        (Forms.errorsBelow model.ArrangementVariantErrors)
                        (ArrangementVariantEditor.view model.ArrangementVariants (ArrangementVariantMsg >> dispatch))

                    Forms.formGroup (not model.UnavailabilityErrors.IsEmpty)
                    <| Forms.controlWithLabel "col-sm-3" "col-sm-5" "unavailabilities" "Zamknięty w dniach"
                        (Forms.errorsBelow model.UnavailabilityErrors)
                        (UnavailabilityEditor.view model.Unavailabilities (UnavailabilityMsg >> dispatch))

                    Forms.formGroup (not model.Resort.HasPaymentMethod)
                    <| Forms.controlWithLabel "col-sm-3" "col-sm-5" "referenceLink" "Link do rezerwacji"
                        (Forms.errorsBelow
                            [ if not model.Resort.HasPaymentMethod then
                                "Nie wprowadzono danych do przelewu bankowego"
                            ])
                        (if model.Resort.HasPaymentMethod then
                            a [ Class "form-control"; Style [ Border "none" ]; Id "referenceLink"; Href model.ReferenceLink; Target "_blank" ] [ str model.ReferenceLink ]
                         else
                            label [ Class "control-label" ] [ str "Link do rezerwacji jest niedostępny" ])
                ]
            ]
            div [ Class "box-footer" ] [
                div [ Class "pull-right"] [                    
                    Controls.simpleButton [] "btn-primary" " Zapisz" "fa fa-save" dispatch ValidateReservations
                    str " "
                    Controls.simpleButton [] "btn-default" " Anuluj" "fa fa-times" dispatch Close
                ]
            ]

            Modals.confirm
                "reservationValidationInfo"
                "Ostrzeżenie"
                (p [] [
                    str "Po wprowadzeniu zmian następujące rezerwacje staną się błędne:"
                    table [ Class "table"] [
                        tbody [] [
                            for res, errors in model.ReservationValidation do
                                tr [] [
                                    td [] [ str (res.FirstName + " " + res.LastName) ]
                                    td [] [ str (res.From.ToString("dd.MM.yyyy")) ]
                                    td [] [ str (res.To.ToString("dd.MM.yyyy")) ]
                                    td [] [
                                        for err in errors do
                                            str err
                                            br []

                                    ]
                                ]
                        ]
                    ]
                    str "i nie będzie można ich modyfikować bez zmiany warunków tak, aby nie pojawiały się błędy."
                    br []
                    str "Czy na pewno chcesz zapisać zmiany?"
                ])
                dispatch Save
        ]


    let getResortCmd resortId =
        Cmd.OfAsync.either id (roomApi.GetResort resortId) ResortDetailsLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać danych ośrodka")
    
    let getRoomCmd roomId =        
        Cmd.OfAsync.either id (roomApi.GetRoom roomId) RoomDetailsLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać danych pokoju")

    let validateReservationsCmd room =
        Cmd.OfAsync.either id (roomApi.ValidateReservations room) ReservationsValidated (ErrorHandling.unpack ShowError "Nie udało się sprawdzić wpływu zmian na istniejące rezerwacje")

    let saveRoomCmd room =
        Cmd.OfAsync.either id (roomApi.SaveRoom room) (fun _ -> Close) (ErrorHandling.unpack ShowError "Nie udało się zapisać zmian")

    let replaceItem getKey ritem items =
        [ for item in items do
            if getKey item = getKey ritem then
                yield ritem
            else
                yield item
        ]

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | ResortDetailsLoaded (resort, roomTypes) ->
            { model with Resort = resort; RoomTypes = roomTypes } |> Model.AddInitialPriceLists, Cmd.none
        | RoomDetailsLoaded (room, resort, roomTypes) ->
            Model.FromRoom resort (recalcRoom resort room) roomTypes, Cmd.none
        | RoomTypeChanged roomTypeId ->
            { model with
                RoomType =
                    model.RoomTypes |> List.tryFind (fun rt -> rt.Id = roomTypeId)
                    |> Option.defaultValue model.RoomType
            }, Cmd.none
        | NameChanged name ->
            { model with Name = name }, Cmd.none
        | ShortDescriptionChanged desc ->
            { model with ShortDescription = desc }, Cmd.none
        | DetailedDescriptionChanged desc ->
            { model with DetailedDescription = desc }, Cmd.none
        | CapacityChanged cap ->
            { model with Capacity = cap }, Cmd.none
        | AdditionalCapacityChanged cap ->
            { model with AdditionalCapacity = cap }, Cmd.none  
        | PriceListMsg (tmplId, msg) ->
            let pl = model.PriceLists |> List.find (fun pl -> pl.TemplateId = tmplId)
            let plMdl, plCmd = PriceListEditor.update msg pl         
            { model with PriceLists = model.PriceLists |> replaceItem (fun pl -> pl.TemplateId) plMdl }
            |> Model.Recalc 
            |> Model.UpdateAvailableAges, Cmd.map (fun msg -> PriceListMsg(tmplId, msg)) plCmd
        | PrepaymentPercentageChanged pp ->
            { model with PrepaymentPercentage = Derived.OfString pp } |> Model.Recalc, Cmd.none
        | VolumeChanged vol ->
            { model with Volume = vol }, Cmd.none        
        | ArrangementMsg msg ->
            let arMdl, arCmd = ArrangementEditor.update msg model.Arrangements
            { model with Arrangements = arMdl } |> Model.UpdateAvailableArrangementTypes, Cmd.map ArrangementMsg arCmd
        | ArrangementVariantMsg msg ->
            let avMdl, avCmd = ArrangementVariantEditor.update msg model.ArrangementVariants
            { model with ArrangementVariants = avMdl }, Cmd.map ArrangementVariantMsg avCmd
        | UnavailabilityMsg msg ->
            let unMdl, unCmd = UnavailabilityEditor.update msg model.Unavailabilities
            { model with Unavailabilities = unMdl }, Cmd.map UnavailabilityMsg unCmd
        | ValidateReservations ->
            let errors = model |> Validation.combine Model.Validate (Model.ToRoom >> validateRoom) 
            if errors.Length > 0 then
                model |> Model.WithErrors errors, Cmd.none
            else
                model, validateReservationsCmd (model |> Model.ToRoom)
        | ReservationsValidated result ->
            if not result.IsEmpty then
                Modals.show "reservationValidationInfo"
                { model with ReservationValidation = result }, Cmd.none
            else
                model, Cmd.ofMsg Save
        | Save ->
            let errors = model |> Validation.combine Model.Validate (Model.ToRoom >> validateRoom) 
            if errors.Length > 0 then
                model |> Model.WithErrors errors, Cmd.none
            else
                model, saveRoomCmd (model |> Model.ToRoom)
        | _ ->
            model, Cmd.none

module RoomGallery =
    
    type Model =
        {
            RoomId              : int
            Pictures            : string list
            UploadsTotal        : int
            UploadsCompleted    : int
            Resort              : ResortRef
        }        
        member this.Percentage = int(100.0 * float(this.UploadsCompleted)/float(this.UploadsTotal))

    type Msg =
        | PicturesLoaded of string list
        | UploadFiles of Browser.Types.FileList
        | UploadCompleted of string * string
        | PictureAdded of string
        | MovePictureLeft of string
        | MovePictureRight of string
        | RemovePicture of string
        | UpdateCompleted
        | Close
        | ShowError of string * ErrorInfo

    let galleryApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IGalleryApi>

    let loadGalleryCmd roomId =
        Cmd.OfAsync.either id (galleryApi.Load roomId) PicturesLoaded (ErrorHandling.unpack ShowError "Nie udało się pobrać zdjęć")

    let addPictureCmd (roomId, name, base64) =
        Cmd.OfAsync.either id (galleryApi.Add (roomId, name, base64)) PictureAdded (ErrorHandling.unpack ShowError "Nie udało się dodać zdjęcia")

    let movePictureCmd (roomId, source, target) =
        Cmd.OfAsync.either id (galleryApi.Move (roomId, source, target)) (fun _ -> UpdateCompleted) (ErrorHandling.unpack ShowError "Nie udało się zmienić kolejności zdjęć")

    let removePictureCmd (roomId, pic) =
        Cmd.OfAsync.either id (galleryApi.Remove (roomId, pic)) (fun _ -> UpdateCompleted) (ErrorHandling.unpack ShowError "Nie udało się usunąć zdjęcia")

    let uploadFileCmd (file: Browser.Types.File) =
        let reader = Browser.Dom.FileReader.Create()
        Cmd.ofSub (fun dispatch ->
            reader.onload <- (fun _ -> dispatch <| UploadCompleted (file.name, unbox reader.result))
            reader.readAsBinaryString file)

    let init(roomId: int, resort: ResortRef): Model * Cmd<Msg> =
        { RoomId = roomId; Pictures = []; UploadsTotal = 0; UploadsCompleted = 0; Resort = resort },
        loadGalleryCmd roomId

    let view (model: Model) dispatch =
        div [ Class "box" ] [
            div [ Class "box-header" ] [
                h3 [ Class "box-title" ] [ str "Zdjęcia" ]
                div [Class "box-tools" ] [                    
                    Controls.fileUpload (UploadFiles >> dispatch)
                    str " "
                    Controls.simpleButton [] "" " Zamknij" "fa fa-times" dispatch Close
                    if model.UploadsTotal > 0 then
                        table [ Class "table" ] [
                            tbody [] [
                                tr [] [
                                    td [Style [ Width "40px" ] ] [ str "Pobieranie" ]
                                    td [ Style [ Width "100px" ] ] [ Controls.progressBar "blue" model.Percentage ]
                                ]
                            ]
                        ]
                ]
            ]
            div [ Class "box-body no-padding"] [
                table [ Class "table" ] [
                    tbody [] [
                        for row in model.Pictures |> List.chunkBySize 3 do
                            tr [] [
                                for pic in row do
                                    td [] [
                                        div [ Style [Width "312px"; Border "solid 1px silver"; Padding "5px 5px 5px 5px" ] ] [
                                            img [ Style [ Width "300px" ]; Src (sprintf "/gallery/room-%d/%s" model.RoomId pic) ]
                                            div [ Style [ Width "300px"; TextAlign TextAlignOptions.Right ] ] [
                                                Controls.actionButton [ Title "Przesuń w lewo" ] "fa fa-arrow-circle-left" dispatch (MovePictureLeft pic)
                                                str " "
                                                Controls.actionButton [ Title "Przesuń w prawo" ] "fa fa-arrow-circle-right" dispatch (MovePictureRight pic)
                                                str " "
                                                Controls.actionButton [ Title "Usuń" ] "fa fa-remove" dispatch (RemovePicture pic)
                                            ]  
                                        ]                                        
                                    ]
                            ]                            
                    ]
                ]
            ]
        ]        

    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | PicturesLoaded pictures ->
            { model with Pictures = pictures }, Cmd.none
        | UploadFiles fileList ->
            { model with UploadsTotal = model.UploadsTotal + fileList.length },
            Cmd.batch [
                for i in 0.. fileList.length - 1 do
                    uploadFileCmd fileList.[i]
            ]
        | UploadCompleted (name, bytes) ->
            let base64 = Browser.Dom.window.btoa bytes
            { model with UploadsCompleted = model.UploadsCompleted + 1 },
            addPictureCmd (model.RoomId, name, base64)
        | PictureAdded name ->
            let total, completed =
                if model.UploadsTotal = model.UploadsCompleted then
                    0, 0
                else
                    model.UploadsTotal, model.UploadsCompleted
            { model with
                Pictures = model.Pictures @ [ name ]
                UploadsTotal = total
                UploadsCompleted = completed
            }, Cmd.none
        | MovePictureLeft pic ->
            let prev = (model.Pictures |> List.findIndex ((=) pic)) - 1
            model, if prev >= 0 then movePictureCmd (model.RoomId, pic, model.Pictures |> List.item prev) else Cmd.none
        | MovePictureRight pic ->
            let prev = (model.Pictures |> List.findIndex ((=) pic)) + 1
            model, if prev < model.Pictures.Length then movePictureCmd (model.RoomId, pic, model.Pictures |> List.item prev) else Cmd.none
        | RemovePicture pic ->
            model, removePictureCmd (model.RoomId, pic)
        | UpdateCompleted ->
            model, loadGalleryCmd model.RoomId
        | ShowError _ 
        | Close -> failwith "Should be handled upper level"


module RoomList =

    type Msg =
        | RoomsLoaded of ResortRef * RoomSummary list
        | New
        | Edit of int
        | Gallery of int
        | Remove of int
        | RemovalPossibilityChecked of int * bool
        | RemoveConfirmed of int
        | ShowError of string * ErrorInfo
        | Close

    type RoomSummaryModel =
        {
            Id                  : int
            Name                : string
            Utilisation         : int
            UtilisationColor    : string
            Status              : string
            StatusColor         : string
        }
        static member FromSummary (rs: RoomSummary) =
            let utilisation = int(Math.Round(float(rs.PendingReservations)/float(rs.Volume) * 100.0))
            {
                Id = rs.Id
                Name = rs.Name
                Utilisation = utilisation
                UtilisationColor =
                    if utilisation < 25 then "red"
                    elif utilisation < 50 then "yellow"
                    elif utilisation < 75 then "blue"
                    else "green"
                Status =
                    match rs.Status with
                    | RoomStatus.Open -> "Czynny"
                    | RoomStatus.Closed -> "Zamknięty"
                    | _ -> "Nieznany"
                StatusColor = 
                    match rs.Status with
                    | RoomStatus.Open -> "success"
                    | RoomStatus.Closed -> "danger"
                    | _ -> "warning"
            }

    type Deletion =
        | ConfirmDelete of int * string
        | CanNotDelete of string
        | NoDeleteRequest
    
    type Model =
        {
            Resort      : ResortRef
            Rooms       : RoomSummaryModel list
            Deletion    : Deletion
        }
        static member Empty = { Resort = { Id = 0; Name = ""; HasPaymentMethod = false }; Rooms = []; Deletion = NoDeleteRequest }
        static member FromSummaryList (resort: ResortRef, rooms: RoomSummary list) =
            {
                Resort = resort
                Rooms = rooms |> List.map RoomSummaryModel.FromSummary
                Deletion = NoDeleteRequest 
            }

    let view (model: Model) dispatch =    
        div [ Class "box" ] [
            div [ Class "box-header" ] [
                h3 [ Class "box-title" ] [ str model.Resort.Name ]
                div [Class "box-tools"] [ 
                    Controls.simpleButton [] "" " Nowy" "fa fa-file-o" dispatch New
                    Controls.simpleButton [] "" " Zamknij" "fa fa-times" dispatch Close
                ]
            ]
            div [ Class "box-body no-padding"] [
                table [ Class "table" ] [
                    tbody [] [
                        yield tr [] [
                            th [] [ str "Nazwa" ]
                            th [] [ str "Obłożenie" ]
                            th [ Style [ Width "5%"] ] []
                            th [ Style [ Width "5%"] ] [ str "Status" ]
                            th [ Style [ Width "10%"] ] [ str "" ]
                        ]
                        for r in model.Rooms do
                            yield tr [] [
                                td [] [ str r.Name ]
                                td [] [ Controls.progressBar r.UtilisationColor r.Utilisation ]
                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                    str (sprintf "%d%%" r.Utilisation)
                                ]
                                td [ Style [ TextAlign TextAlignOptions.Center ] ] [
                                    Controls.label r.StatusColor r.Status
                                ]
                                td [ Style [TextAlign TextAlignOptions.Center ] ] [
                                    Controls.actionButton [ Title "Edytuj" ] "fa fa-edit" dispatch (Edit r.Id)
                                    str " "
                                    Controls.actionButton [ Title "Zdjęcia" ] "fa fa-image" dispatch (Gallery r.Id)
                                    str " "
                                    Controls.actionButton [ Title "Usuń"; Data("toggle", "modal"); Data("target", "#confirmRoomDelete") ] "fa fa-remove" dispatch (Remove r.Id)
                                    str " "
                                    if model.Resort.HasPaymentMethod then
                                        a [ Title "Link do rezerwacji"; Href <| sprintf "/make-reservation?room=%d" r.Id; Target "_blank" ] [ i [ Class "fa fa-calendar" ] [] ]
                                    else
                                        span [ Style [ Color "tomato" ]; Title "Link do rezerwacji jest niedostępny.\nNie wprowadzono danych do przelewu bankowego." ] [ i [ Class "fa fa-calendar" ] [] ]
                                ]
                            ]
                    ]
                ]
            ]
            match model.Deletion with
            | ConfirmDelete (id, name) ->
                Modals.confirm
                    "confirmRoomDelete" "Potwierdź"
                    (p [] [str (sprintf "Czy na pewno chcesz usunąć pokój %s?" name) ])
                    dispatch (RemoveConfirmed id)
            | CanNotDelete name ->
                Modals.info
                    "confirmRoomDelete"
                    "Błąd"
                    (p [] [str (sprintf "Nie można usunąć pokoju %s. Należy najpierw odrzucić wszystkie niezakończone rezerwacje." name)])
            | NoDeleteRequest ->
                Modals.info "confirmRoomDelete" "Info" (p [] [str "Nie ma nic do usunięcia" ])
        ]

    let getRoomSummariesCmd (resort: ResortRef) =
        Cmd.OfAsync.either id (roomApi.GetRoomSummaries resort.Id) (fun items -> RoomsLoaded (resort, items)) (ErrorHandling.unpack ShowError "Nie udało się wczytać listy pokojów.")

    let canDeleteRoomCmd roomId =
        Cmd.OfAsync.either id (roomApi.CanDeleteRoom roomId) (fun check -> RemovalPossibilityChecked (roomId, check)) (ErrorHandling.unpack ShowError "Nie udało się sprawdzić możliwości usunięcia pokoju.")

    let deleteRoomCmd (resort: ResortRef, roomId) =
        let performDelete = async {
            do! roomApi.DeleteRoom roomId
            return! roomApi.GetRoomSummaries resort.Id
        }
        Cmd.OfAsync.either id performDelete (fun items -> RoomsLoaded (resort, items)) (ErrorHandling.unpack ShowError "Nie udało się usunąć pokoju.")


    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | RoomsLoaded (id, items) ->
            Model.FromSummaryList (id, items), Cmd.none
        | Remove id ->
            model, canDeleteRoomCmd id
        | RemovalPossibilityChecked (id, possible) ->
            let newModel =
                { model with
                    Deletion = model.Rooms
                        |> List.tryFind (fun r -> r.Id = id)
                        |> Option.map (fun r -> if possible then ConfirmDelete (id, r.Name) else CanNotDelete r.Name)
                        |> Option.defaultValue NoDeleteRequest
                }
            newModel, Cmd.none
        | RemoveConfirmed id ->
            { model with Deletion = NoDeleteRequest }, deleteRoomCmd (model.Resort, id)
        | _ ->
            failwithf "Invalid function: %A, %A" msg model


type Msg =
    | FormMsg of RoomDetails.Msg
    | ListMsg of RoomList.Msg
    | GalleryMsg of RoomGallery.Msg
    | Close 
    | ShowError of string * ErrorInfo

type Model =
    | Details of RoomDetails.Model
    | Items of RoomList.Model
    | Gallery of RoomGallery.Model
    static member Empty = Items RoomList.Model.Empty 

let view (model : Model) (dispatch : Msg -> unit) =
    React.ofList [
        match model with
        | Items rooms -> RoomList.view rooms (ListMsg >> dispatch)
        | Details room -> RoomDetails.view room (FormMsg >> dispatch)
        | Gallery gallery -> RoomGallery.view gallery (GalleryMsg >> dispatch)
    ]


let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
    match msg, model with
    | ListMsg RoomList.Close, _ ->
        model, Cmd.ofMsg Close
    | ListMsg RoomList.New, Items rooms ->
        Details RoomDetails.Model.Empty, Cmd.map FormMsg (RoomDetails.getResortCmd rooms.Resort.Id)
    | ListMsg (RoomList.Edit roomId), _ ->
        Details RoomDetails.Model.Empty, Cmd.map FormMsg (RoomDetails.getRoomCmd roomId)
    | ListMsg (RoomList.Gallery roomId), Items items ->
        let gmdl, gcmd = RoomGallery.init (roomId, items.Resort)
        Gallery gmdl, Cmd.map GalleryMsg gcmd
    | ListMsg (RoomList.ShowError (msg, err)), _
    | FormMsg (RoomDetails.ShowError (msg, err)), _
    | GalleryMsg (RoomGallery.ShowError (msg, err)), _->
        model, Cmd.ofMsg (ShowError (msg, err))
    | FormMsg RoomDetails.Close, Details room ->
        Items RoomList.Model.Empty, Cmd.map ListMsg (RoomList.getRoomSummariesCmd <| ResortRef.FromResort room.Resort)
    | FormMsg msg, Details details ->
        let dtModel, dtMsg = RoomDetails.update msg details                                
        Details dtModel, Cmd.map FormMsg dtMsg
    | ListMsg msg, Items items ->
        let lsModel, lsMsg = RoomList.update msg items
        Items lsModel, Cmd.map ListMsg lsMsg
    | GalleryMsg RoomGallery.Close, Gallery gallery ->
        Items RoomList.Model.Empty, Cmd.map ListMsg (RoomList.getRoomSummariesCmd gallery.Resort)
    | GalleryMsg msg, Gallery gmdl ->
        let gModel, gMsg = RoomGallery.update msg gmdl
        Gallery gModel, Cmd.map GalleryMsg gMsg
    | _, _ ->
        failwith "Invalid function"