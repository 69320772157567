module Messages

open System
open Elmish
open Fable.React
open Fable.React.Props
open Fable.Remoting.Client
open Commons
open Shared
open Shared.Messages
open Shared.Utils
open Fable.Core
open Shared.ServerError


module MessagetList = 

    type Msg =
        | MessageListLoaded of Reservations.Parent * Message list
        | RefreshMessageList
        | StartResponse of int * DateTime
        | ContentChanged of string
        | SendResponse
        | ResponseSent
        | CancelResponse
        | ShowReservation of int
        | ShowError of string * ErrorInfo

    type Response =
        {
            ReservationId   : int
            AddedAt         : DateTime
            Content         : string
            Sent            : bool
        }

    type Model =
        {
            Parent      : Reservations.Parent
            Messages    : Message list
            Response    : Response
        }
        static member Empty =
            {
                Parent      = Reservations.Parent.Owner
                Messages    = []
                Response    = { ReservationId = 0; AddedAt = System.DateTime(0L); Content = ""; Sent = false }
            }

    let view (model: Model) dispatch =        
        div [ Class "box" ] [
            div [ Class "box-header" ] [
                h3 [ Class "box-title" ] [
                    match model.Parent with
                    | Reservations.Parent.Owner -> str "Wszystkie"
                    | Reservations.Parent.Resort (_, name)
                    | Reservations.Parent.Room (_, name) -> str name
                ]
            ]

            div [ Class "box-body no-padding"] [
                table [ Class "table" ] [
                    tbody [] [
                        for m in model.Messages do
                            let roomInfo =
                                match model.Parent with
                                | Reservations.Parent.Owner -> m.Resort + " / " + (m.Rooms |> concatRoomNames)
                                | Reservations.Parent.Resort _ -> m.Rooms |> concatRoomNames
                                | Reservations.Parent.Room _-> ""
                            tr [] [
                                td [] [
                                    div [ Style [ MarginBottom "10px" ] ] [
                                        i [ Class "icon fa fa-envelope-o";  Style [ Width "30px"; Display DisplayOptions.InlineBlock ] ] []
                                        b [ Style [ Width "150px"; Display DisplayOptions.InlineBlock ] ] [
                                            a [ Style [ Cursor "pointer" ]; OnClick (Event.none dispatch (ShowReservation m.ReservationId)) ] [ str m.Orderer ]
                                        ]
                                        span [] [str <| sprintf "%s, od %s do %s" roomInfo (m.From.ToString("dd.MM.yyyy")) (m.To.ToString("dd.MM.yyyy"))]
                                        small [ Class "pull-right"] [str (m.AddedAt.ToString("dd.MM.yyyy")) ]
                                    ]
                                    p [ Style [MarginLeft "30px"] ] [
                                        str m.Content
                                        if m.ReservationId <> model.Response.ReservationId then
                                            div [ Class "pull-right" ] [
                                                Controls.simpleButton [] "btn-default" " Odpowiedz" "fa fa-reply" dispatch (StartResponse (m.ReservationId, m.AddedAt))
                                            ]
                                    ]
                                    if m.ReservationId = model.Response.ReservationId && m.AddedAt = model.Response.AddedAt then
                                        if model.Response.Sent then
                                            div [ Class "form-group has-success" ] [
                                                label [ Class "control-label" ] [ str "Wiadomość została wysłana" ]
                                            ]
                                        else
                                            div [] [
                                                div [ Class "col-sm-10" ] [
                                                    input [ Class "form-control"; OnChange (Event.str dispatch ContentChanged); MaxLength 1024.0 ] 
                                                ]
                                                div [ Class "pull-right" ] [
                                                        Controls.simpleButton [] "btn-default" " Wyślij" "fa fa-send" dispatch SendResponse
                                                        str " "
                                                        Controls.simpleButton [] "btn-default" " Anuluj" "fa fa-times" dispatch CancelResponse
                                                ]                                                       
                                            ]
                                ]
                            ]
                    ]
                ]
            ]
        ]
                                   
                
    let messageApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IMessageApi>
    
    let getMessagesCmd parentId =
        match parentId with
        | Reservations.Parent.Owner ->
            Cmd.OfAsync.either id (messageApi.GetOwnerMessages ()) (fun items -> MessageListLoaded (parentId, items)) (ErrorHandling.unpack ShowError "Nie udało się wczytać wiadomości.")
        | Reservations.Parent.Resort (resortId, _) ->
            Cmd.OfAsync.either id (messageApi.GetResortMessages resortId) (fun items -> MessageListLoaded (parentId, items)) (ErrorHandling.unpack ShowError "Nie udało się wczytać wiadomości.")
        | Reservations.Parent.Room (_, _) ->
            Cmd.ofMsg (ShowError ("Nie udało się wczytać wiadomości.", ErrorInfo.Empty))  

    let saveResponseCmd (reservationId, responseText) =
        Cmd.OfAsync.either id (messageApi.Respond(reservationId, responseText)) (fun () -> ResponseSent) (ErrorHandling.unpack ShowError "Nie udało się wysłać wiadomości.")

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | MessageListLoaded (parentId, messages) ->
            { model with
                Parent = parentId;
                Messages = messages;
                Response =
                    if model.Response.Sent then
                        { ReservationId = 0; Content = ""; AddedAt = System.DateTime(0L); Sent = false }
                    else
                        model.Response
            }, Cmd.none
        | RefreshMessageList ->
            model, getMessagesCmd model.Parent
        | StartResponse (resId, date) ->
            { model with Response = { ReservationId = resId; AddedAt = date; Content = ""; Sent = false } }, Cmd.none
        | ContentChanged content ->
            { model with Response = { model.Response with Content = content } }, Cmd.none
        | SendResponse ->
            model, saveResponseCmd (model.Response.ReservationId, model.Response.Content)
        | ResponseSent ->
            { model with Response = { model.Response with Sent = true } }, getMessagesCmd model.Parent
        | CancelResponse ->
            { model with Response = { ReservationId = 0; Content = ""; AddedAt = System.DateTime(0L); Sent = false } }, Cmd.none
        | _ ->
            model, Cmd.none

    let init (parent: Reservations.Parent): Model * Cmd<Msg> =
        Model.Empty, getMessagesCmd parent

    let subscribe _ =
        let sub dispatch =
            Browser.Dom.window.setInterval (
                (fun _ ->
                    if Browser.Dom.document.getElementById("prevent-subscriptions") = null then
                        dispatch RefreshMessageList
                ),
                1000*60)
            |> ignore
        Cmd.ofSub sub

type Msg =
    | MessageListMsg of MessagetList.Msg
    | ReservationMsg of Reservations.ReservationDetails.Msg
    | ShowError of string * ErrorInfo

type Model =
    | MessageListModel of MessagetList.Model
    | ReservationModel of Reservations.Parent * Reservations.ReservationDetails.Model


let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
    match msg, model with
    | MessageListMsg (MessagetList.ShowError (msg, err)), _
    | ReservationMsg (Reservations.ReservationDetails.ShowError (msg, err)), _ ->
        model, Cmd.ofMsg (ShowError (msg, err))
    | ReservationMsg Reservations.ReservationDetails.Close, ReservationModel (parent, _) ->
        let cmMdl, cmCmd = MessagetList.init parent
        MessageListModel cmMdl, Cmd.map MessageListMsg cmCmd
    | MessageListMsg (MessagetList.ShowReservation resId), MessageListModel model ->
        ReservationModel (model.Parent, Reservations.ReservationDetails.Model.Empty),
        Cmd.map ReservationMsg (Reservations.ReservationDetails.getReservationCmd resId)
    | MessageListMsg msg, MessageListModel model ->
        let clMdl, clCmd = MessagetList.update msg model
        MessageListModel clMdl, Cmd.map MessageListMsg clCmd
    | ReservationMsg msg, ReservationModel (parent, model) ->
        let rsMdl, rsCmd = Reservations.ReservationDetails.update msg model
        ReservationModel (parent, rsMdl), Cmd.map ReservationMsg rsCmd


let init (parent: Reservations.Parent): Model * Cmd<Msg> =
    let clMdl, clCmd = MessagetList.init parent
    MessageListModel clMdl, Cmd.map MessageListMsg clCmd

let view (model: Model) dispatch =
    React.ofList [
        Components.contentHeader "Wiadomości" "" 
        div [ Class "content" ] [
            div [ Class "row" ] [
                div [ Class "col-xs-12" ] [
                    match model with
                    | MessageListModel mdl ->
                        MessagetList.view mdl (MessageListMsg >> dispatch)
                    | ReservationModel (_, mdl) ->
                        Reservations.ReservationDetails.view mdl (ReservationMsg >> dispatch)
                ]
            ]
        ]
    ]

let subscribe () = Cmd.map MessageListMsg (MessagetList.subscribe ())
    
    