namespace Shared

open System
open System.Text.RegularExpressions

module Validation =

    type ValidationException(message: string) = 
        inherit Exception(message)

    let ioptstr x = x |> Option.map string |> Option.defaultValue ""
    let foptstr = Option.map (sprintf "%.2M") >> Option.defaultValue ""

    let range opt1 opt2 toString =
        match opt1, opt2 with
        | Some v1, Some v2 -> sprintf "pomiędzy %s a %s" (toString v1) (toString v2)
        | Some v1, None -> sprintf "%s lub więcej" (toString v1)
        | None, Some v2 -> sprintf "%s lub mniej" (toString v2)
        | None, None -> failwithf "Invalid range: None - None"

    let checkEmpty label suffix value =
        [ if System.String.IsNullOrEmpty value then
            yield sprintf "%s nie może pozostać pust%s" label suffix
        ]

    let checkNone label suffix value =
        [ if Option.isNone value then
            yield sprintf "%s nie może pozostać pust%s" label suffix
        ]

    let checkInt label minVal maxVal value = 
        [ if (minVal |> Option.map ((<) value) |> Option.defaultValue false) || (maxVal |> Option.map ((>) value) |> Option.defaultValue false) then
            yield sprintf "%s poza dopuszczalnym zakresem %s: %d" label (range minVal maxVal string) value
        ]

    let checkMoney label (maxVal: Decimal option) (money: Decimal) = 
        [ if money < 0.0m || (maxVal |> Option.map ((>) money) |> Option.defaultValue false) then
            yield sprintf "%s poza dopuszczalnym zakresem %s: %.2M" label (range (Some 0.0M) maxVal (sprintf "%.2M")) money
        ]

    let checkPercentage label percentage = 
        [ if percentage < 0 || percentage > 100 then
            yield sprintf "%s poza dopuszczalnym zakresem (0 - 100): %d" label percentage
        ]

    let checkDuplicates groupingKey createMessage l = 
        l   
        |> List.groupBy groupingKey
        |> List.filter (snd >> List.length >> (<=) 2)  
        |> List.map (fun (k, l) -> createMessage k (List.length l))

    let checkOverlaps getFrom getTo format createMessage l =
        if List.isEmpty l then
            []
        else
            let sl = l |> List.sortBy getFrom
            List.zip (List.take (sl.Length - 1) sl) (List.tail sl)
            |> List.filter (fun (p1, p2) -> getTo p1 >= getFrom p2)
            |> List.map (fun (p1, p2) -> createMessage (format p1) (format p2))

    
    let checkAggregatedOverlaps getFrom getTo format createMessage maxOverlap l =
        if List.isEmpty l then
            []
        else
            let sl = l |> List.sortBy getFrom
            sl
            |> List.map (fun e -> e :: sl |> List.filter (fun ep -> getFrom e > getFrom ep && getFrom e < getTo ep))
            |> List.filter (List.length >> (<) maxOverlap)
            |> List.map (List.map format >> createMessage)

    let checkPasswordStrength pwd =
        [
            if String.length pwd < 8 then
                "Hasło jest za krótkie (powinno mieć co najmniej 8 znaków)"
            if not (Regex.Match(pwd, "[^A-Z^a-z^0-9]").Success && Regex.Match(pwd, "[0-9]").Success) then
                "Hasło powinno zawierać litery, cyfry i znaki specjalne"
        ] 

    let forField (name: string) (messages: string list) =
        messages |> List.map (fun msg -> name, msg)

    let getErrorsOf name errors =
        errors |> List.filter (fst >> (=) name) |> List.map snd

    let raiseOnFail errors =
        if errors|> List.length > 0 then
            raise <| ValidationException (errors |> List.map snd |> String.concat "\n")


    let combine (phase1: 't -> (string * string) list) (phase2: 't -> (string * string) list) (model: 't) =
        match phase1 model with
        | [] -> phase2 model
        | errors -> errors
