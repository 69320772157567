namespace Shared

open System
open Resorts
open Calculations
open Validation
#if !FABLE_COMPILER
open SqlFun
#endif


module Rooms =

    type RoomStatus =
        | Open      = 1
        | Closed    = 2

    type RoomSummary =
        {
            Id                  : int
            Name                : string
            Volume              : int
            PendingReservations : int
            Status              : RoomStatus
        }
    
    type AgeGroupPricing = 
        {
            AgeTo           : int
            ShortTimePrice  : Derived<decimal>
            LongTimePrice   : Derived<decimal>
        }
        static member Zeros (ageGroup: AgeGroupPricing) = 
            {
                AgeTo           = ageGroup.AgeTo
                ShortTimePrice  = ageGroup.ShortTimePrice |> Derived.Recalc 0M
                LongTimePrice = ageGroup.LongTimePrice |> Derived.Recalc 0M
            }

    type PayType = 
#if FABLE_COMPILER
    | Once = 1
    | PerDay = 2
#else
    | [<EnumValue("O")>] Once = 1
    | [<EnumValue("D")>] PerDay = 2
#endif

    type Arrangement = 
        {
            Type        : ArrangementType
            ExtraCharge : decimal
            PayType     : PayType
        }

    type ArrangementAvailability = 
        {
            Type            : ArrangementType
            AvailableUnits  : int
        }

    type ArrangementVariant = 
        {
            No              : int
            Availabilities  : ArrangementAvailability list
        }

    type PriceList =
        {
#if !FABLE_COMPILER
            [<Id>]
#endif
            TemplateId              : int
            ShortTimePrice          : Derived<decimal>
            LongTimePrice           : Derived<decimal>
            ShortEmptySpacePrice    : Derived<decimal>
            LongEmptySpacePrice     : Derived<decimal>
            AgeGroupPricings        : AgeGroupPricing list
        }

    type Occupancy =
        {
            ReservationId   : int
            From            : DateTime
            To              : DateTime
        }

    type RoomType =
        {
#if !FABLE_COMPILER
            [<Prefixed("RoomType")>]
#endif
            Id                  : string
            SingularNominative  : string
            PluralNominative    : string
            SingularGenitive    : string
            PluralGenitive      : string
        }
        static member Empty =
            {
                Id                  = ""
                SingularNominative  = ""
                PluralNominative    = ""
                SingularGenitive    = ""
                PluralGenitive      = ""
            }

    type Room = 
        {
            Id                      : int
            ResortId                : int
            Name                    : string
            ShortDescription        : string
            DetailedDescription     : string
            Capacity                : int
            AdditionalCapacity      : int
            ShortTimePrice          : decimal
            LongTimePrice           : decimal
            ShortEmptySpacePrice    : Derived<decimal>
            LongEmptySpacePrice     : Derived<decimal>
            PrepaymentPercentage    : Derived<int>
            Volume                  : int
            AgeGroupPricings        : AgeGroupPricing list
            Arrangements            : Arrangement list
            ArrangementVariants     : ArrangementVariant list
            Unavailabilities        : Unavailability list
            PriceLists              : PriceList list
            Occupancies             : Occupancy list
            RoomType                : RoomType
        }
        static member Empty =
            {
                Id                      = 0
                ResortId                = 0
                Name                    = ""
                ShortDescription        = ""
                DetailedDescription     = ""
                Capacity                = 0
                AdditionalCapacity      = 0
                ShortTimePrice          = 0m
                LongTimePrice           = 0m
                ShortEmptySpacePrice    = Uninitialised
                LongEmptySpacePrice     = Uninitialised
                PrepaymentPercentage    = Uninitialised
                Volume                  = 0
                AgeGroupPricings        = []
                Arrangements            = []
                ArrangementVariants     = []
                Unavailabilities        = []
                PriceLists              = []
                Occupancies             = []
                RoomType                = RoomType.Empty
            }

    type RoomCalculationData =
        {
            ShortTimePrice          : Derived<decimal>
            LongTimePrice           : Derived<decimal>
            ShortEmptySpacePrice    : Derived<decimal>
            LongEmptySpacePrice     : Derived<decimal>
            PrepaymentPercentage    : Derived<int>
            AgeGroupPricings        : AgeGroupPricing list
            PriceLists              : PriceList list
        }
        static member FromRoom (room: Room) =
            {
                ShortTimePrice          = Overwritten room.ShortTimePrice
                LongTimePrice           = Overwritten room.LongTimePrice
                ShortEmptySpacePrice    = room.ShortEmptySpacePrice
                LongEmptySpacePrice     = room.LongEmptySpacePrice
                PrepaymentPercentage    = room.PrepaymentPercentage
                AgeGroupPricings        = room.AgeGroupPricings
                PriceLists              = room.PriceLists
            }
        static member UpdateRoom (room: Room) (calcData: RoomCalculationData) =
            { room with
                ShortEmptySpacePrice    = room.ShortEmptySpacePrice |> Derived.Update calcData.ShortEmptySpacePrice
                LongEmptySpacePrice     = room.LongEmptySpacePrice |> Derived.Update calcData.LongEmptySpacePrice
                PrepaymentPercentage    = room.PrepaymentPercentage |> Derived.Update calcData.PrepaymentPercentage
                AgeGroupPricings        = calcData.AgeGroupPricings
                PriceLists              = calcData.PriceLists
            }

    let joinMatching rightList matches leftList = 
        [ for left in leftList do
            yield left, rightList |> List.tryFind (matches left)
        ]         

    let recalcPriceList (resort: Resort) (room: RoomCalculationData)  (pl: PriceList, tmplOpt: PriceListTemplate option) =
        match tmplOpt with
        | Some template ->
            let initial = 
                { pl with
                    ShortTimePrice  = pl.ShortTimePrice |> Derived.Recalc (room.ShortTimePrice, (*) (1M + decimal(template.PriceChangePercentage)/100M))
                    LongTimePrice   = pl.LongTimePrice |> Derived.Recalc (room.LongTimePrice, (*) (1M + decimal(template.PriceChangePercentage)/100.0M))
                }
            let initial =
                { initial with
                    ShortEmptySpacePrice    = initial.ShortEmptySpacePrice |> Derived.Recalc (initial.ShortTimePrice, (*) (decimal(resort.EmptySpacePricePercentage)/100.0M))
                    LongEmptySpacePrice     = initial.LongEmptySpacePrice |> Derived.Recalc (initial.LongTimePrice, (*) (decimal(resort.EmptySpacePricePercentage)/100.0M))
                }
            { initial with
                AgeGroupPricings    =
                    initial.AgeGroupPricings
                    |> joinMatching resort.AgeGroups (fun pg tmpl -> pg.AgeTo = tmpl.AgeTo)
                    |> List.map (fun (p, ppo) -> 
                        ppo |> Option.map (fun pp ->  
                            { p with 
                                ShortTimePrice = p.ShortTimePrice |> Derived.Recalc (initial.ShortTimePrice, (*) (decimal(pp.PricePercentage)/100m))
                                LongTimePrice = p.LongTimePrice |> Derived.Recalc (initial.LongTimePrice, (*) (decimal(pp.PricePercentage)/100m))
                       }) |> Option.defaultValue p)                    
            }
        | None -> pl

    let calculateRoom (resort: Resort) (room: RoomCalculationData) =
        let roomInitial = 
            { room with
                ShortEmptySpacePrice    = room.ShortEmptySpacePrice |> Derived.Recalc (room.ShortTimePrice, (*) (decimal(resort.EmptySpacePricePercentage)/100m))
                LongEmptySpacePrice     = room.LongEmptySpacePrice |> Derived.Recalc (room.LongTimePrice, (*) (decimal(resort.EmptySpacePricePercentage)/100m))
                PrepaymentPercentage    = room.PrepaymentPercentage |> Derived.Recalc resort.PrepaymentPercentage
                AgeGroupPricings        = 
                    room.AgeGroupPricings 
                    |> joinMatching resort.AgeGroups (fun p pp -> p.AgeTo = pp.AgeTo)
                    |> List.map (fun (p, ppo) -> 
                        ppo |> Option.map (fun pp ->  
                            { p with 
                                ShortTimePrice = p.ShortTimePrice |> Derived.Recalc (room.ShortTimePrice, (*) (decimal(pp.PricePercentage)/100m))
                                LongTimePrice = p.LongTimePrice |> Derived.Recalc (room.LongTimePrice, (*) (decimal(pp.PricePercentage)/100m))
                       }) |> Option.defaultValue (AgeGroupPricing.Zeros p))
            }
        { roomInitial with
            PriceLists =
                room.PriceLists
                |> joinMatching resort.PriceListTemplates (fun pl tmpl -> pl.TemplateId = tmpl.Id)
                |> List.map (recalcPriceList resort roomInitial)
        }

    let recalcRoom (resort: Resort) (room: Room) =
        room
        |> RoomCalculationData.FromRoom
        |> calculateRoom resort
        |> RoomCalculationData.UpdateRoom room

    let createInitialAgeGroupPricings (resort: Resort) = 
        resort.AgeGroups
        |> List.map (fun pp ->
            {   AgeTo = pp.AgeTo
                ShortTimePrice  = Uninitialised 
                LongTimePrice   = Uninitialised 
            })

    let createInitialPriceLists (resort: Resort) =
        resort.PriceListTemplates
        |> List.map (fun tmpl ->
            {   TemplateId = tmpl.Id
                ShortTimePrice          = Uninitialised 
                LongTimePrice           = Uninitialised
                ShortEmptySpacePrice    = Uninitialised
                LongEmptySpacePrice     = Uninitialised 
                AgeGroupPricings        =
                    resort.AgeGroups
                    |> List.map (fun pp ->
                        {   AgeTo = pp.AgeTo
                            ShortTimePrice  = Uninitialised 
                            LongTimePrice   = Uninitialised 
                        })
            })

    let validateAgeGroupPricings (postfix: string) (ageGroups: AgeGroupPricing list) =
        [
            yield!
                ageGroups
                |> checkDuplicates (fun p -> p.AgeTo) (sprintf "Grupa wiekowa występuje wielokrotnie na liście: do %d lat (%d)")
                |> forField ("AgeGroupPricings" + postfix)
            for p in ageGroups do
                yield! checkInt "Wiek dla grupy" (Some 0) None p.AgeTo |> forField "AgeGroupPricings"
                yield!
                    checkMoney (sprintf "Cena wynajmu długoterminowego dla grupy do %d lat" p.AgeTo) None p.LongTimePrice.Value
                    |> forField ("AgeGroupPricings" + postfix)
                yield!
                    checkMoney (sprintf "Cena wynajmu krótkoterminowego dla grupy do %d lat" p.AgeTo) None p.ShortTimePrice.Value
                    |> forField ("AgeGroupPricings" + postfix)
        ]

    let validateRoom (room: Room) = 
        [
            yield! checkEmpty "Nazwa" "a" room.Name |> forField "Name"
            yield! checkEmpty "Opis" "y" room.ShortDescription |> forField "ShortDescription"
            yield! checkMoney "Cena wynajmu długoterminowego" None room.LongTimePrice |> forField "LongTimePrice"
            yield! checkMoney "Cena wynajmu krótkoterminowego" None room.ShortTimePrice |> forField "ShortTimePrice"
            yield! checkMoney "Cena niewykorzystanego miejsca" None room.LongEmptySpacePrice.Value |> forField "EmptySpacePrice"
            yield! checkPercentage "Zaliczka" room.PrepaymentPercentage.Value |> forField "PrepaymentPercentage"
            yield! checkInt "Maks. liczba osób" (Some 1) None room.Capacity |> forField "Capacity"
            yield! checkInt "Dodatkowe miejsce" (Some 0) None room.Capacity |> forField "AdditionalCapacity"
            yield! checkInt "Ilość (liczba takich samych lokali)" (Some 1) None room.Volume |> forField "Volume"
            yield! validateAgeGroupPricings "" room.AgeGroupPricings
            for pl in room.PriceLists do
                yield!
                    checkMoney "Cena niewykorzystanego miejsca" None pl.LongEmptySpacePrice.Value
                    |> forField ("EmptySpacePrice" + pl.TemplateId.ToString())
                yield!
                    checkMoney "Cena wynajmu długoterminowego" None pl.LongTimePrice.Value
                    |> forField ("LongTimePrice" + pl.TemplateId.ToString())
                yield!
                    checkMoney "Cena wynajmu krótkoterminowego" None pl.ShortTimePrice.Value
                    |> forField ("ShortTimePrice" + pl.TemplateId.ToString())
                yield! validateAgeGroupPricings (pl.TemplateId.ToString()) pl.AgeGroupPricings
            yield!
                room.ArrangementVariants
                |> checkDuplicates (fun v -> v.No) (sprintf "Numer wariantu występuje wielokrotnie: %d (%d)")
                |> forField "ArrangementVariants"
            for a in room.Arrangements do
                yield!
                    checkMoney (sprintf "Dodatkowa opłata za %s" a.Type.Description) None a.ExtraCharge
                    |> forField "Arrangements"
            for v in room.ArrangementVariants do
                for a in v.Availabilities do
                    yield!
                        checkInt (sprintf "Liczba dostępnych elementów %s w wariancie %d" a.Type.Description v.No) (Some 1) None a.AvailableUnits
                        |> forField "ArrangementVariants"
            yield!
                room.Unavailabilities
                |> checkAggregatedOverlaps
                     (fun u -> u.From) (fun u -> u.To) (fun u -> u.From.ToString("dd.MM.yyyy") + "-" + u.To.ToString("dd.MM.yyyy"))
                     (String.concat ", " >> sprintf "Za dużo nakładających się okresów niedostępności: %s")
                     room.Volume 
                |> forField "Unavailabilities"
        ]

    type IRoomApi =
        {
            GetRoomSummaries: int -> Async<RoomSummary list>
            CanDeleteRoom: int -> Async<bool>
            DeleteRoom: int -> Async<unit>
            GetRoom: int -> Async<Room * Resort * RoomType list>
            GetResort: int -> Async<Resort * RoomType list>
            ValidateReservations: Room -> Async<(ReservationRef * string list) list>
            SaveRoom: Room -> Async<int>
        }

    type IGalleryApi =
        {
            Load: int -> Async<string list>
            Add: int * string * string -> Async<string>
            Remove: int * string -> Async<unit>
            Move: int * string * string -> Async<unit>
        }