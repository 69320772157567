module HomePage


open Fable.Core
open Commons
open Shared.ServerError
open SidebarCommons
open Elmish
open Fable.Remoting.Client
open Shared
open Fable.React
open Fable.React.Props
type Model = SidebarCommons.Model

type Msg = SidebarCommons.Msg

let menuItem name icon ifNothing href =
    let active = Browser.Dom.window.location.href = href
    li [ if active then Class "active" ] [
        a [ Href href ] [
            i [ Class icon ] ifNothing
            span [] [ str (" " + name) ]
        ]
    ]

let sidebarMenu (model: Model) =    
    ul [ Class "sidebar-menu tree"; Data("widget", "tree") ] [
        li [ Class "header" ] [ str "PRZEJDŹ DO" ]
        menuItem "O nas" "fa fa-industry" [] "#"
        menuItem "O Quaterze" "fa fa-globe" [] "#"
        menuItem "Poradniki i instrukcje" "fa fa-mortar-board" [] "#"
        menuItem "Często zadawane pytania" "fa fa-info-circle" [] "#"
        menuItem "Regulamin" "fa fa-nothing" [ b [ DangerouslySetInnerHTML { __html = "&sect;" }] [] ] "#"
        menuItem "Cennik" "fa fa-money" [] "#"
        if model.IsLoggedIn then
            menuItem "Panel właściciela" "fa fa-tachometer" [] "/owner-panel"
        else
            menuItem "Zarajestruj się i dodaj obiekt" "fa fa-user-plus" [] "/owner-auth"
    ]

let init = SidebarCommons.init

let view (model : Model) (dispatch : Msg -> unit) =
    SidebarCommons.view (sidebarMenu model) model dispatch

let update = SidebarCommons.update