module OwnerPanel

open Fable.Core
open Commons
open Shared.ServerError

type Parameters =
    | ReservationId of int
    | PartnerKey of string * string
    | Empty


module Header = 
    
    open System
    open Elmish
    open Fable.Remoting.Client
    open Shared
    open Fable.React
    open Fable.React.Props
    
    open Commons

    type Msg =
        | CommonsMsg of HeaderCommons.Msg
        | ShowError of string * ErrorInfo
        | Msg
    
    type MessageInfo =
        {
            Id      : int
            Icon    : string
            Sender  : string
            Subject : string
        }
    
    type Model =
        {
            Commons     : HeaderCommons.Model
            Messages    : MessageInfo list
        }
    
    let headerMenu model dispatch =
        div [ Class "navbar-custom-menu" ] [
            ul [ Class "nav navbar-nav" ] [
                if not model.Messages.IsEmpty then
                    HeaderCommons.messageMenu [
                        for m in model.Messages do
                            HeaderCommons.messageItem m.Sender m.Icon m.Subject dispatch Msg
                    ]
                HeaderCommons.userMenu model.Commons (CommonsMsg >> dispatch)
            ]
        ]
    
    let mapCommonsMsg = function HeaderCommons.ShowError (m, e) -> ShowError (m, e) | m -> CommonsMsg m
    
    let init(): Model * Cmd<Msg> =
        let cmModel, cmCmd = HeaderCommons.init "owner-auth"
        { Commons = cmModel; Messages = [] }, Cmd.map mapCommonsMsg cmCmd
    
    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | CommonsMsg msg ->
            let cmModel, cmCmd = HeaderCommons.update msg model.Commons 
            { model with Commons = cmModel }, Cmd.map mapCommonsMsg cmCmd
        | Msg ->
            model, Cmd.none
    
    let view (model : Model) (dispatch : Msg -> unit) =
        HeaderCommons.view (headerMenu model dispatch) model.Commons (CommonsMsg >> dispatch)


module Sidebar = 
    
    open Elmish
    open Fable.Remoting.Client
    open Shared
    
    open Shared.Resorts
    
    let resortApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IResortApi>
    
    type Msg =
        | CommonsMsg of SidebarCommons.Msg
        | ResortListLoaded of ResortUsageInfo list
        | ReloadResortList
        | ShowError of string * ErrorInfo
        | AccommodationBase
        | Reservations of (int * string) option
        | Messages of (int * string) option
        | ChangePassword
        | AccountDetails
        | BankAccount
    
    type Model =
        {
            Commons     : SidebarCommons.Model
            Resorts     : ResortUsageInfo list
            LastMenuMsg : Msg
        }
    
    let mapCommonsMsg = function SidebarCommons.ShowError (m, e) -> ShowError (m, e) | m -> CommonsMsg m

    let getResortsCmd =
        Cmd.OfAsync.either id (resortApi.GetResortUsageInfos()) ResortListLoaded (ErrorHandling.unpack ShowError "Nie udało się odczytać listy ośrodków.")

    let init(): Model * Cmd<Msg> =
        let cmModel, cmCmd = SidebarCommons.init()
        {   Commons = cmModel
            Resorts = []
            LastMenuMsg = Reservations None
        }, Cmd.batch [
            Cmd.map mapCommonsMsg cmCmd   
            getResortsCmd
        ]
            
    
    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | CommonsMsg msg ->
            let cmModel, cmCmd = SidebarCommons.update msg model.Commons
            { model with Commons = cmModel }, Cmd.map mapCommonsMsg cmCmd
        | ResortListLoaded resorts ->
            { model with Resorts = resorts }, Cmd.none
        | ReloadResortList ->
            model, getResortsCmd
        | m ->
            { model with LastMenuMsg = m }, Cmd.none
    
    open Fable.React
    open Fable.React.Props
    
    let menuItem model name icon dispatch msg =
        SidebarCommons.menuItem model name icon (model.LastMenuMsg = msg) dispatch msg
    
    let menuItemWithLabels model name icon labels dispatch msg =
        SidebarCommons.menuItemWithLabels model name icon (model.LastMenuMsg = msg) labels dispatch msg
    
    let sidebarMenu (model: Model) (dispatch: Msg -> unit) =    
        ul [ Class "sidebar-menu tree"; Data("widget", "tree") ] [
            li [ Class "header" ] [ str "PRZEJDŹ DO" ]
            menuItem model "Baza noclegowa" "fa fa-home" dispatch AccommodationBase
            SidebarCommons.submenu "Rezerwacje" "fa fa-calendar" [
                yield menuItem model "Wszystkie" "fa fa-circle-o" dispatch (Reservations None)
                for res in model.Resorts ->
                    menuItemWithLabels
                        model
                        res.Name
                        "fa fa-circle-o"
                        [   if res.PrepDelayedReservations > 0 then
                                yield small [ Class "label pull-right bg-red" ] [
                                    str (res.PrepDelayedReservations.ToString())
                                ]
                            if res.StartingReservations > 0 then
                                yield small [ Class "label pull-right bg-yellow" ] [
                                    str (res.StartingReservations.ToString())
                                ]
                            if res.NewReservations > 0 then
                                yield small [ Class "label pull-right bg-green" ] [
                                    str (res.NewReservations.ToString())
                                ]
                        ]
                        dispatch
                        (Reservations (Some (res.Id, res.Name)))
            ]
            SidebarCommons.submenu "Wiadomości" "fa fa-envelope" [
                yield menuItem model "Wszystkie" "fa fa-circle-o" dispatch (Messages None)
                for res in model.Resorts ->
                    menuItemWithLabels
                        model
                        res.Name
                        "fa fa-circle-o"
                        [   if res.UnrepliedMessages > 0 then
                                small [ Class "label pull-right bg-yellow" ] [
                                    str (res.UnrepliedMessages.ToString())
                                ]
                        ]
                        dispatch
                        (Messages (Some (res.Id, res.Name)))
            ]
            SidebarCommons.submenu "Profil" "fa fa-user" [
                menuItem model "Zmień hasło" "fa fa-circle-o" dispatch ChangePassword
                menuItem model "Aktualizuj dane osobowe" "fa fa-circle-o" dispatch AccountDetails
                menuItem model "Aktualizuj rachunek bankowy" "fa fa-circle-o" dispatch BankAccount
            ]
            
        ]
    
    let view (model : Model) (dispatch : Msg -> unit) =
        SidebarCommons.view (sidebarMenu model dispatch) model.Commons (CommonsMsg >> dispatch)

    let subscribe _ =
        let sub dispatch =
            Browser.Dom.window.setInterval (
                (fun _ ->
                    if Browser.Dom.document.getElementById("prevent-subscriptions") = null then
                        dispatch ReloadResortList
                ),
                1000*60)
            |> ignore
        Cmd.ofSub sub
        

module Content = 
    
    open Elmish
    open Shared
    
    open Commons
    open UserProfile
    
    type Msg =
        | ShowAccommodationBase of (string * string) option
        | AccommodationBaseMsg of AccommodationBase.Msg
        | ShowReservations of (int * string) option
        | ShowMessages of (int * string) option
        | ShowAccountDetails
        | ShowChangePasswordForm
        | ShowBankAccount
        | ReservationMsg of Reservations.Msg
        | MessagesMsg of Messages.Msg
        | AccountDetailsMsg of AccountDetails.Msg
        | ChangePasswordMsg of ChangePassword.Msg
        | BankAccountMsg of BankAccount.Msg
        | Other
        | ShowError of string * ErrorInfo
        | ClearError
    
    type Submodel =
        | Empty
        | AccommodationBase of AccommodationBase.Model
        | Reservations of Reservations.Model
        | Messages of Messages.Model
        | ChangePassword of ChangePassword.Model
        | AccountDetails of AccountDetails.Model
        | BankAccount of BankAccount.Model
    
    
    type Model =
        {
            Submodel        : Submodel
            ErrorMessage    : string
        }
    
    let init(parameters: Parameters): Model * Cmd<Msg> =
        match parameters with
        | ReservationId id ->
            {   Submodel = Reservations (Reservations.Items ({ Reservations.ReservationList.Model.Empty with Parent = Reservations.Parent.Owner }))
                ErrorMessage = ""
            }, id |> Reservations.ReservationList.Edit |> Reservations.ListMsg |> ReservationMsg |> Cmd.ofMsg
        | PartnerKey (partner, key) ->
            {   Submodel = Empty;
                ErrorMessage = ""
            }, Cmd.ofMsg <| ShowAccommodationBase (Some (partner, key))
        | Parameters.Empty ->
            {   Submodel = Empty;
                ErrorMessage = ""
            }, Cmd.ofMsg (ShowReservations None)
    
    let mapAccommodationBaseMsg = function AccommodationBase.ShowError (m, e) -> ShowError (m, e) | m -> AccommodationBaseMsg m
    
    let mapReservationMsg = function Reservations.ShowError (m, e) -> ShowError (m, e) | m -> ReservationMsg m

    let mapMessagesMsg = function Messages.MessageListMsg (Messages.MessagetList.ShowError (m, e)) -> ShowError (m, e) | m -> MessagesMsg m

    let mapAccountDetailsMsg = function AccountDetails.ShowError (m, e) -> ShowError (m, e) | m -> AccountDetailsMsg m

    let mapChangePasswordMsg = function ChangePassword.ShowError (m, e) -> ShowError (m, e) | m -> ChangePasswordMsg m

    let mapBankAccountMsg = function BankAccount.ShowError (m, e) -> ShowError (m, e) | m -> BankAccountMsg m
    
    
    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg, model.Submodel with
        | ShowAccommodationBase partnerKey, _ ->
            let acmModel, acmCmd = AccommodationBase.init partnerKey
            { model with Submodel = AccommodationBase acmModel }, Cmd.map mapAccommodationBaseMsg acmCmd
        | AccommodationBaseMsg submsg, Submodel.AccommodationBase submodel ->
            let newSubmodel, subcmd = AccommodationBase.update submsg submodel
            { model with Submodel = Submodel.AccommodationBase newSubmodel }, Cmd.map mapAccommodationBaseMsg subcmd
        | ShowReservations resortIdOpt, _ ->
            let parentId = resortIdOpt
                            |> Option.map Reservations.Parent.Resort
                            |> Option.defaultValue Reservations.Parent.Owner
            let rsModel, rsCmd = Reservations.init parentId
            { model with Submodel = Submodel.Reservations rsModel }, Cmd.map mapReservationMsg rsCmd
        | ReservationMsg msg, Submodel.Reservations submodel ->
            let rsModel, rsCmd = Reservations.update msg submodel
            { model with Submodel = Submodel.Reservations rsModel }, Cmd.map mapReservationMsg rsCmd
        | ShowMessages resortIdOpt, _ ->
            let parentId = resortIdOpt
                            |> Option.map Reservations.Parent.Resort
                            |> Option.defaultValue Reservations.Parent.Owner
            let cmModel, cmCmd = Messages.init parentId
            { model with Submodel = Submodel.Messages cmModel }, Cmd.map mapMessagesMsg cmCmd
        | MessagesMsg msg, Submodel.Messages submodel ->
            let cmModel, cmCmd = Messages.update msg submodel
            { model with Submodel = Submodel.Messages cmModel }, Cmd.map mapMessagesMsg cmCmd
        | ShowAccountDetails, _ ->
            let adMdl, adCmd = AccountDetails.init()
            { model with Submodel = AccountDetails adMdl }, Cmd.map mapAccountDetailsMsg adCmd
        | AccountDetailsMsg msg, AccountDetails mdl ->
            let adMdl, adCmd = AccountDetails.update msg mdl
            { model with Submodel = AccountDetails adMdl }, Cmd.map mapAccountDetailsMsg adCmd
        | ShowChangePasswordForm, _ ->
            let cpMdl, cpCmd = ChangePassword.init()
            { model with Submodel = ChangePassword cpMdl}, Cmd.none
        | ChangePasswordMsg msg, ChangePassword submodel ->
            let cpMdl, cpCmd = ChangePassword.update msg submodel
            { model with Submodel = ChangePassword cpMdl}, Cmd.map mapChangePasswordMsg cpCmd
        | ShowBankAccount, _ ->
            let baMdl, baCmd = BankAccount.init()
            { model with Submodel = BankAccount baMdl}, Cmd.map mapBankAccountMsg baCmd
        | BankAccountMsg msg, BankAccount submodel ->
            let baMdl, baCmd = BankAccount.update msg submodel
            { model with Submodel = BankAccount baMdl}, Cmd.map mapBankAccountMsg baCmd
        | ShowError (m, e), _ ->
            match e with
            | { errorType = ErrorType.Other; errorMessage = msg } ->
                { model with ErrorMessage = sprintf "%s %s" m msg }, Cmd.none
            | { errorType = ErrorType.SessionExpired } ->
#if DEBUG
                Browser.Dom.window.location.href <- "/owner-auth"                 
#else
                Browser.Dom.window.location.reload()                
#endif
                { model with ErrorMessage = sprintf "%s Sesja wygasła. Za chwilę nastąpi przekierowanie do strony logowania." m }, Cmd.none
        | ClearError, _ ->
            { model with ErrorMessage = "" }, Cmd.none        
        | _, _ ->
            model, Cmd.none
    
    
    open Fable.React
    open Fable.React.Props
    
    let view (model : Model) (dispatch : Msg -> unit) =
        div [ Class "content-wrapper" ] [        
                div [
                    yield Class "alert alert-danger alert-dismissible"
                    if System.String.IsNullOrEmpty model.ErrorMessage then
                        yield Style [ Display DisplayOptions.None ]
                    yield Role "alert"
                ] [
                    button [ Type "button"; Class "close"; OnClick (Event.none dispatch ClearError) ] [ str "x" ]
                    h4 [] [
                        i [ Class "icon fa fa-ban" ] []
                        str "UWAGA!"
                    ]
                    str model.ErrorMessage
                ]
                match model.Submodel with
                | AccommodationBase model ->
                    AccommodationBase.view model (AccommodationBaseMsg >> dispatch)
                | Reservations model ->
                    Reservations.view model (ReservationMsg >> dispatch)
                | Messages model ->
                    Messages.view model (MessagesMsg >> dispatch)
                | AccountDetails model ->
                    AccountDetails.view model (AccountDetailsMsg >> dispatch)
                | ChangePassword model ->
                    ChangePassword.view model (ChangePasswordMsg >> dispatch)
                | BankAccount model ->
                    BankAccount.view model (BankAccountMsg >> dispatch)
                | Submodel.Empty ->
                    section [ Class "content" ] [ str "Czekaj..." ]
        ]

    let subscribe (model: Model) =
        Cmd.batch [
            Cmd.map MessagesMsg (Messages.subscribe ())
            Cmd.map ReservationMsg (Reservations.subscribe ())
        ]


open Elmish
open Commons

type Msg =
    | HeaderMsg of Header.Msg
    | SidebarMsg of Sidebar.Msg
    | ContentMsg of Content.Msg
    | ShowError of string * ErrorInfo

type Model =
    {
        Header      : Header.Model
        Sidebar     : Sidebar.Model
        Content     : Content.Model
        PartnerKey  : (string * string) option
    }

let mapHeaderMsg = function Header.ShowError (m, e) -> ShowError (m, e) | m -> HeaderMsg m
let mapSidebarMsg = function Sidebar.ShowError (m, e) -> ShowError (m, e) | m -> SidebarMsg m
let mapContentMsg = function Content.ShowError (m, e) -> ShowError (m, e) | m -> ContentMsg m

let init(parameters: Parameters): Model * Cmd<Msg> =
    let mhModel, mhCmd = Header.init ()
    let sbModel, sbCmd = Sidebar.init ()
    let ctModel, ctCmd = Content.init parameters
    {
        Header = mhModel
        Sidebar = sbModel
        Content = ctModel
        PartnerKey = match parameters with ReservationId _ | Empty -> None | PartnerKey (p, k) -> Some (p, k)
    }, Cmd.batch [ Cmd.map mapHeaderMsg mhCmd; Cmd.map mapSidebarMsg sbCmd; Cmd.map ContentMsg ctCmd ] 


let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | ShowError (m, e) ->
        model, Cmd.ofMsg (ContentMsg (Content.ShowError (m, e)))
    | HeaderMsg mhMsg ->
        let ctModel, ctCmd =
            match mhMsg with
            | Header.CommonsMsg HeaderCommons.AccountDetails ->
                Content.update Content.ShowAccountDetails model.Content
            | Header.CommonsMsg HeaderCommons.ChangePassword ->
                Content.update Content.ShowChangePasswordForm model.Content
            | Header.CommonsMsg HeaderCommons.BankAccount ->
                Content.update Content.ShowBankAccount model.Content
            | _ -> model.Content, Cmd.none
        let mhModel, mhCmd = Header.update mhMsg model.Header
        { model with Header = mhModel; Content = ctModel },
        Cmd.batch [
            Cmd.map mapHeaderMsg mhCmd
            Cmd.map mapContentMsg ctCmd
        ]
    | SidebarMsg sbMsg ->
        let ctModel, ctCmd =
            match sbMsg with
            | Sidebar.AccommodationBase ->
                Content.update (Content.ShowAccommodationBase model.PartnerKey) model.Content
            | Sidebar.Reservations p ->
                Content.update (Content.ShowReservations p) model.Content
            | Sidebar.Messages p ->
                Content.update (Content.ShowMessages p) model.Content
            | Sidebar.AccountDetails ->
                Content.update Content.ShowAccountDetails model.Content
            | Sidebar.ChangePassword ->
                Content.update Content.ShowChangePasswordForm model.Content
            | Sidebar.BankAccount ->
                Content.update Content.ShowBankAccount model.Content
            | _ -> model.Content, Cmd.none
        let sbModel, sbCmd = Sidebar.update sbMsg model.Sidebar
        { model with Sidebar = sbModel; Content = ctModel },
        Cmd.batch [
            Cmd.map mapSidebarMsg sbCmd
            Cmd.map mapContentMsg ctCmd
        ]        
    | ContentMsg cnMsg ->
        let sbModel, sbCmd = 
            match cnMsg with
            | Content.ReservationMsg (Reservations.FormMsg Reservations.ReservationDetails.Save) 
            | Content.MessagesMsg (Messages.MessageListMsg Messages.MessagetList.ResponseSent) ->
                Sidebar.update Sidebar.ReloadResortList model.Sidebar 
            | _ ->
                model.Sidebar, Cmd.none
        let cnModel, cmd = Content.update cnMsg model.Content
        { model with
            Content = cnModel
            Sidebar = sbModel
        }, Cmd.batch [
            Cmd.map mapContentMsg cmd
            Cmd.map mapSidebarMsg sbCmd
        ]

open Fable.React
open Fable.React.Props
open Fulma

let footer =
    footer [ Class "main-footer" ] [
        div [Class "pull-right hidden-xs" ] [
            b [] [ str "Wersja" ]; str " 1.0.0"
        ]
        strong [] [ str "Copyright "; i [ Class "fa fa-copyright" ] []; str " 2021 Helix " ]        
        str " Wszystkie prawa zastrzeżone."
    ]

let view (model : Model) (dispatch : Msg -> unit) =
    React.ofList [
        Header.view model.Header (HeaderMsg >> dispatch)
        Sidebar.view model.Sidebar (SidebarMsg >> dispatch)
        Content.view model.Content (ContentMsg >> dispatch)
        footer
    ]

let subscribe (model: Model) =
    Cmd.batch [
        Cmd.map ContentMsg (Content.subscribe model.Content)
        Cmd.map SidebarMsg (Sidebar.subscribe model.Sidebar)
    ]
    