module Reservations

open System
open Elmish
open Fable.React
open Fable.React.Props
open Fable.Remoting.Client
open Commons
open Commons.Reservations
open Shared
open Shared.Reservations
open Shared.Resorts
open Shared.Rooms
open Calculations
open Fable.Core
open Shared.ServerError


let reservationApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IOwnerReservationApi>


[<RequireQualifiedAccess>]
type Parent =
    | Owner
    | Room of int * string
    | Resort of int * string


module ReservationDetails =

    type Msg =
        | ReservationDetailsLoaded of Reservation * Resort * Room list
        | PaymentVisibilityChanged
        | PriceChanged of string
        | PrepaymentChanged of string
        | NewPaymentChanged of string
        | NewPaymentDateChanged of DateTime
        | RemovePayment of Payment
        | NewMessageChanged of string
        | NotesChanged of string
        | Save
        | Close
        | Confirm
        | AcceptPrepayment
        | Refuse
        | ShowError of string * ErrorInfo

    type Model =
        {
            Reservation         : Reservation
            Resort              : Resort
            Rooms               : Room list
            Price               : Derived<string>
            PriceErrors         : string list
            Prepayment          : Derived<string>
            PrepaymentErrors    : string list
            ShowPayments        : bool
            Payments            : Payment list
            NewPaymentDate      : DateTime
            NewPayment          : string
            NewPaymentErrors    : string list
            Notes               : string
            NewMessage          : string
            NewMessageErrors    : string list
        }
        // TODO: restoring single-room reservations
        member this.Adults =
            Derived.Calc(this.Reservation.Allocations |> List.map (fun a -> a.Adults) |> Derived.ofList, List.sum)
        member this.Children =
            this.Reservation.Allocations |> List.collect (fun a -> a.Children)
        member this.Arrangements =
            this.Reservation.Allocations |> List.collect (fun a -> a.Arrangements)
        static member Empty =
            {
                Reservation         = Reservation.Empty
                Resort              = Resort.Empty
                Rooms               = []
                Price               = Calculated ""
                PriceErrors         = []
                Prepayment          = Calculated ""
                PrepaymentErrors    = []
                ShowPayments        = false
                Payments            = []
                NewPaymentDate      = DateTime.Today
                NewPayment          = ""
                NewPaymentErrors    = []
                Notes               = ""
                NewMessage          = ""
                NewMessageErrors    = []
            }
        static member init (reservation, resort, rooms) =            
            {
                Reservation         = reservation
                Resort              = resort
                Rooms               = rooms |> List.map (recalcRoom resort)
                Price               = (if reservation.PriceIsFixed then Overwritten else Calculated) (Decimal.format 2 reservation.Price.Value)
                PriceErrors         = []
                Prepayment          = (if reservation.PrepaymentIsFixed then Overwritten else Calculated) (Decimal.format 2 reservation.Prepayment.Value)
                PrepaymentErrors    = []
                ShowPayments        = false
                Payments            = reservation.Payments
                NewPaymentDate      = DateTime.Today
                NewPayment          = ""
                NewPaymentErrors    = []
                Notes               = reservation.Notes
                NewMessage          = ""
                NewMessageErrors    = []
            }
        static member Recalc (model: Model) =
            let prices =                
                { model.Reservation with Price = Calculated 0.0m }
                |> ReservationCalculationData.FromReservation 
                |> calculateAllocationPrices (model.Resort, model.Rooms)                   
            { model with
                Price       =
                    model.Price |> Derived.Update (
                        Derived.Calc(prices |> List.map snd |> Derived.ofList, List.sum)|> Derived.Format)                    
                Prepayment  =
                    model.Prepayment |> Derived.Update (
                        prices                        
                        |> calculatePrepayment (model.Resort, model.Rooms)
                        |> Derived.Format)
            }
        static member Validate (model: Model) =
            [
                yield! Validation.checkIfDerivedValidDecimal "Cena" model.Price |> Validation.forField "Price"
                yield! Validation.checkIfDerivedValidDecimal "Zaliczka" model.Prepayment |> Validation.forField "Prepayment"
                yield! Validation.checkIfValidOrEmptyDecimal "Nowa wpłata" model.NewPayment |> Validation.forField "NewPayment"
            ]
        static member WithErrors (errors: (string * string) list) (model: Model) =
            { model with
                PriceErrors         = errors |> Validation.getErrorsOf "Price"
                PrepaymentErrors    = errors |> Validation.getErrorsOf "Prepayment"
                NewPaymentErrors    = errors |> Validation.getErrorsOf "NewPayment"
                NewMessageErrors    = errors |> Validation.getErrorsOf "NewMessage"
            }

    let getReservationCmd reservationId =
        Cmd.OfAsync.either id (reservationApi.GetReservation reservationId) ReservationDetailsLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać rezerwacji.")

    let saveReservationCmd reservation =
        Cmd.OfAsync.either id (reservationApi.SaveReservation reservation) (fun _ -> Close) (ErrorHandling.unpack ShowError "Nie udało się zapisać zmian.")

    let refuseReservationCmd reservation =
        Cmd.OfAsync.either id (reservationApi.RefuseReservation reservation) (fun _ -> Close) (ErrorHandling.unpack ShowError "Nie udało się odrzucić rezerwacji.")

    let acceptPrepaymentCmd reservation =
        Cmd.OfAsync.either id (reservationApi.AcceptPrepayment reservation) (fun _ -> Close) (ErrorHandling.unpack ShowError "Nie udało się zaakceptować zaliczki.")
    
    let confirmReservationCmd waitPeriod reservation =
        Cmd.OfAsync.either id (reservationApi.ConfirmReservation(reservation, waitPeriod)) (fun _ -> Close) (ErrorHandling.unpack ShowError "Nie udało się zatwierdzić rezerwacji.")


    let updateReservation (model: Model) =
        { model.Reservation with
            DeclaredArrival = model.Reservation.DeclaredArrival |> Option.map (fun d -> System.DateTime(d.Ticks, DateTimeKind.Utc))
            Price = Overwritten (model.Price.Value |> Decimal.Parse)
            PriceIsFixed = Derived.IsOverwritten model.Price
            Prepayment = Overwritten (model.Prepayment.Value |> Decimal.Parse)
            PrepaymentIsFixed = Derived.IsOverwritten model.Prepayment
            Payments =
                model.Payments @ 
                if Validation.isValidDecimal model.NewPayment then
                    [ { PaidAt = model.NewPaymentDate; Amount = Decimal.Parse model.NewPayment } ]
                else
                    []
            Messages =
                model.Reservation.Messages @
                if not (String.IsNullOrWhiteSpace model.NewMessage) then
                    [ { AddedAt = DateTime.Now; Party = Party.Resort; Content = model.NewMessage } ]
                else
                    []
            Notes = model.Notes
        }

    let validateAndRun (model: Model) cmd =
        let errors = Model.Validate model
        if not errors.IsEmpty then
            model |> Model.WithErrors errors, Cmd.none
        else
            model, cmd (updateReservation model)

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | ReservationDetailsLoaded (reservation, resort, rooms) ->
            let rrooms = rooms |> List.map (recalcRoom resort)
            let rres = reservation |> recalcReservation(resort, rrooms, DateTime.Today)
            Model.init (rres, resort, rooms), Cmd.none 
        | PaymentVisibilityChanged ->
            { model with ShowPayments = not model.ShowPayments }, Cmd.none
        | PriceChanged pr ->
            { model with Price = pr |> Derived.OfString                        
            } |> Model.Recalc, Cmd.none
        | PrepaymentChanged prp ->            
            { model with Prepayment = prp |> Derived.OfString    
            } |> Model.Recalc, Cmd.none
        | NewPaymentChanged pmt ->
            { model with NewPayment = pmt }, Cmd.none
        | NewPaymentDateChanged dt ->
            { model with NewPaymentDate = dt }, Cmd.none
        | RemovePayment p ->
            { model with Payments = model.Payments |> List.filter ((<>) p)
            } |> Model.Recalc, Cmd.none
        | NewMessageChanged cmt ->
            { model with NewMessage = cmt }, Cmd.none
        | NotesChanged notes ->
            { model with Notes = notes }, Cmd.none
        | Save ->
            validateAndRun model saveReservationCmd 
        | Confirm ->
            validateAndRun model (confirmReservationCmd model.Resort.PrepaymentWaitPeriod)
        | AcceptPrepayment ->
            validateAndRun model acceptPrepaymentCmd
        | Refuse ->
            if String.IsNullOrEmpty model.NewMessage then
                { model with NewMessageErrors = ["Odrzucenie rezerwacji wymaga wyjaśnienia"] }, Cmd.none
            else
                validateAndRun model refuseReservationCmd


    let adultsLabel num =
        match num % 10 with
        | 1 when num = 1 -> "osoba dorosła"
        | 2 | 3 | 4 -> "osoby dorosłe"
        | _ -> "osób doroslych"

    let childrenLabel ageList =
        match ageList |> List.last with
        | 1 -> "rok"
        | 2 | 3 | 4 -> "lata"
        | _ -> "lat"

    let reservationInfo model =
        div [ Class "col-xs-6" ] [
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Termin:" ]
                            td [] [ str <| model.Reservation.From.ToString("dd.MM.yyyy") + " - " + model.Reservation.To.ToString("dd.MM.yyyy") ]
                        ]
                        for a in model.Reservation.Allocations do
                            let room = model.Rooms |> roomById a.RoomId
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| room.RoomType.SingularNominative + ":" ]
                                td [] [ str room.Name ]
                            ]
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Liczba gości:" ]
                                td [] [
                                    span [] [str <| sprintf "%s %s" (Derived.ToString a.Adults) (Derived.ToString (Derived.Calc(a.Adults, adultsLabel))) ]
                                    if not a.Children.IsEmpty then
                                        span [] [ str <| sprintf ", dzieci: %s %s" (a.Children |> List.map string |> String.concat ", ") (childrenLabel a.Children) ]
                                ]
                            ]
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Dodatki:" ]
                                td [] [
                                    if a.Arrangements.IsEmpty then
                                        str "Nic nie wybrano"
                                    else
                                        for ar in a.Arrangements do
                                            span [] [ str <| sprintf "%s (%d)" ar.Type.Description ar.Units ]
                                            br []
                                ]
                            ]
                    ]
                ]
            ]                
        ]

    let ordererInfo model =
        div [ Class "col-xs-6" ] [
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        let orderer = model.Reservation.Orderer
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Zamawiający:" ]
                            td [] [ str <| orderer.FirstName + " " + orderer.LastName ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Telefon:" ]
                            td [] [ a [ Href ("callto:" + orderer.Phone) ] [str orderer.Phone ] ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Email:" ]
                            td [] [ a [Href ("mailto:" + orderer.Email) ] [ str orderer.Email ] ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Godzina przyjazdu:" ]
                            td [] [ str (model.Reservation.DeclaredArrival |> Option.map (fun t -> t.ToString("HH:mm")) |> Option.defaultValue "")  ]
                        ]
                    ]
                ]
            ]
        ]

    let paymentForm model dispatch =
        div [ Class "col-xs-6" ] [
            form [ Class "form-horizontal"] [

                div [Class "row"] [
                    div [ Class "col-xs-6" ] [
                        Forms.formGroup (not model.PriceErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-6" "col-sm-6" "price" "Cena:" 9.0                                         
                            (Derived.ToTag id "" model.Price)
                            (Forms.errorsBelow model.PriceErrors)
                            (Event.str dispatch PriceChanged)
                        Forms.formGroup (not model.PrepaymentErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-6" "col-sm-6" "prepayment" "Zaliczka:" 9.0                                             
                            (Derived.ToTag id "" model.Prepayment)
                            (Forms.errorsBelow model.PrepaymentErrors)
                            (Event.str dispatch PrepaymentChanged)
                    ]
                    
                    div [ Class "col-xs-6" ] [

                        table [Class "table"] [
                            tbody [] [
                                tr [] [
                                    th [] [ str "Wpłacono:" ]
                                    td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                        str <| sprintf "%s zł" (Decimal.format 2 (model.Payments |> List.sumBy (fun p -> p.Amount)))
                                    ]
                                    td [] [
                                        Controls.actionButton []
                                            (if model.ShowPayments then "fa fa-minus-square-o" else "fa fa-plus-square-o")
                                            dispatch
                                            PaymentVisibilityChanged
                                    ]
                                ]
                                if model.ShowPayments then
                                    for p in model.Payments do
                                        tr [] [
                                            td [] [ str <| p.PaidAt.ToString("dd.MM.yyyy") ]
                                            td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                                str <| sprintf "%s zł" (Decimal.format 2 p.Amount)
                                            ]
                                            td [] [
                                                Controls.actionButton [] "fa fa-remove" dispatch (RemovePayment p)
                                            ]
                                        ]
                            ]
                        ]
                    ]
                ]                
                
                div [Class "row"] [
                    div [Class "col-xs-12"] [
                        Forms.formGroup (not model.NewPaymentErrors.IsEmpty)                             
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-3" "newPayment" "Nowa wpłata:" 9.0
                            (Value model.NewPayment)
                            (Forms.errorsBelow [])
                            (Event.str dispatch NewPaymentChanged)
                         @ Forms.controlWithLabel "col-sm-2" "col-sm-4" "newPaymentDate" "Na dzień:"
                            (Forms.errorsBelow model.NewPaymentErrors)
                            (Controls.datePickerInput "newPaymentDate" model.NewPaymentDate dispatch NewPaymentDateChanged)
                    ]                    
                ]
                
            ]
        ]

    let notesEditor model dispatch =
        div [ Class "col-xs-6" ] [
            form [ Class "form-horizontal"] [    
                Forms.formGroup false
                <| Forms.textareaWithLabel "col-sm-3" "col-sm-9" 6 "notes" "Notatki:" 1024.0                                         
                    model.Notes
                    (Forms.errorsBelow [])
                    (Event.str dispatch NotesChanged)
            ]
        ]
    
    let messageEditor model dispatch =
        div [ Class "col-xs-11" ] [
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        for cmt in model.Reservation.Messages do
                            tr [] [
                                td [] [
                                    div [] [
                                        b [] [ str (match cmt.Party with Party.Guest -> "Zamawiający" | Party.Resort -> "My") ]
                                        span [ Class "pull-right" ] [ str <| cmt.AddedAt.ToString("dd.MM.yyyy") ]
                                    ]
                                    div [] [ str cmt.Content ]                                    
                                ]                                
                            ]                            
                    ]
                ]

                Forms.formGroup (not model.NewMessageErrors.IsEmpty)
                <| Forms.textareaWithLabel "col-sm-2" "col-sm-12" 3 "newMessage" "Nowa wiadomość:" 1024.0
                    model.NewMessage
                    (Forms.errorsBelow model.NewMessageErrors)
                    (Event.str dispatch NewMessageChanged)
            ]
        ]

    let transitionButtons dispatch =
        div [ Class "pull-right"] [
            Controls.simpleButton [] "btn-primary" " Zapisz" "fa fa-save" dispatch Save
            str " "
            Controls.simpleButton [] "btn-default" " Zatwierdź" "fa fa-check" dispatch Confirm
            str " "
            Controls.simpleButton [] "btn-default" " Odrzuć" "fa fa-ban" dispatch Refuse
            str " "
            Controls.simpleButton [] "btn-default" " Zaakceptuj zaliczkę" "fa fa-credit-card" dispatch AcceptPrepayment
            str " "
            Controls.simpleButton [] "btn-default" " Anuluj" "fa fa-times" dispatch Close
        ]

    let view (model : Model) (dispatch : Msg -> unit) =
        div [ Class "box box-info" ] [
            div [ Class "box-header with-border" ] [
                h3 [ Class "box-title" ] [ str model.Resort.Name ]
                h3 [ Class "box-title pull-right" ] [
                    Controls.label (getStateColor <| Calculated model.Reservation.DetailedState) (getStateName <| Calculated model.Reservation.DetailedState)
                ]
            ]            

            div [ Class "box-body no-padding" ] [

                div [ Class "col-xs-12" ] [

                    div [ Class "row" ] [
                        reservationInfo model
                        ordererInfo model
                    ]

                    div [ Class "row" ] [                        
                        paymentForm model dispatch
                        notesEditor model dispatch
                    ]

                    div [ Class "box-header with-border" ] [
                        h3 [ Class "box-title" ] [ str "Korespondencja z zamawiającym" ]
                    ]
                    div [Class "box-body no-padding" ] [
                        messageEditor model dispatch
                    ]
                    
                   
                    div [ Class "box-footer" ] [
                        transitionButtons dispatch
                    ]
                ]
            ]
        ]
        


module ReservationList =

    type Msg =
        | ReservationsLoaded of Parent * ReservationSummary list
        | RefreshReservationList
        | ShowAll of bool
        | Edit of int
        | WarnOnPrepaymentDelay of int
        | PrepaymentDelayWarningSent
        | ShowError of string * ErrorInfo
        | Close
    
    type ReservationSummaryModel =
        {
            Id              : int
            Resort          : string
            Rooms           : (string * int) list
            From            : DateTime
            To              : DateTime
            FirstName       : string
            LastName        : string
            Email           : string
            Phone           : string
            Adults          : int
            Children        : int
            Price           : decimal option
            DeclaredArrival : DateTime option
            State           : string
            StateColor      : string
            WarningButton   : bool
        }
        static member FromSummary (summary: ReservationSummary) =
            {
                Id              = summary.Id
                Resort          = summary.Resort
                Rooms           = summary.Rooms
                From            = summary.From
                To              = summary.To
                FirstName       = summary.FirstName
                LastName        = summary.LastName
                Email           = summary.Email
                Phone           = summary.Phone
                Adults          = summary.Adults
                Children        = summary.Children
                Price           = summary.Price
                DeclaredArrival = summary.DeclaredArrival
                State           = getStateName summary.DetailedState
                StateColor      = getStateColor summary.DetailedState
                WarningButton   = summary.DetailedState = Calculated DetailedState.PrepaymentDelayed
            }

    type Model =
        {
            Parent          : Parent
            ShowAll         : bool
            Reservations    : ReservationSummaryModel list
        }
        static member Empty = { Parent = Parent.Owner; ShowAll = false; Reservations = [] }
        static member FromSummaryList (parent: Parent, showAll: bool, reservations: ReservationSummary list) =
            {
                Parent = parent
                ShowAll = showAll
                Reservations = reservations |> List.map (updateState DateTime.Today >> ReservationSummaryModel.FromSummary)
            }

    let getRoomList (r: ReservationSummaryModel) =
        [   match r.Rooms with
            | [ room, 1 ] -> str room
            | _ ->
                for room, cnt in r.Rooms do
                    str <| sprintf "%s (%d)" room cnt
                    br []
        ]

    let view (model: Model) dispatch =    
        div [ Class "box" ] [
            div [ Class "box-header" ] [
                h3 [ Class "box-title" ] [
                    match model.Parent with
                    | Parent.Owner -> str "Wszystkie"
                    | Parent.Resort (_, name)
                    | Parent.Room (_, name) -> str name
                ]
                div [ Class "box-tools pull-right" ] [
                    Controls.checkbox "showAll" [] "Pokaż zakończone" model.ShowAll dispatch ShowAll
                ]
            ]
            
            div [ Class "box-body no-padding"] [
                table [ Class "table" ] [
                    tbody [] [
                        tr [] [
                            match model.Parent with
                            | Parent.Owner ->
                                th [] [ str "Obiekt" ]
                                th [] [ str "Pokój" ]
                            | Parent.Resort _ ->
                                th [] [ str "Pokój" ]
                            | Parent.Room _ -> ()
                            th [] [ str "Termin" ]
                            th [] [ str "Opłata" ]
                            th [ Style [ Width "5%"] ] [ str "Dorośli" ]
                            th [ Style [ Width "5%"] ] [ str "Dzieci" ]
                            th [] [ str "Etap" ]
                            th [ Style [ Width "5%"] ] [ str "" ]
                        ]
                        for r in model.Reservations do
                            tr [] [
                                match model.Parent with
                                | Parent.Owner ->
                                    td [] [ str r.Resort ]
                                    td [] (getRoomList r)
                                | Parent.Resort _ ->
                                    td [] (getRoomList r)
                                | Parent.Room _-> ()
                                td [] [ str (r.From.ToString("dd.MM.yyyy") + " - " + r.To.ToString("dd.MM.yyyy")) ]
                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                    str (r.Price |> Option.map  (Decimal.format 2) |> Option.defaultValue "-")
                                ]
                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                    str (sprintf "%d" r.Adults)
                                ]
                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                    str (sprintf "%d" r.Children)
                                ]
                                td [ Style [ TextAlign TextAlignOptions.Center ] ] [
                                    Controls.label r.StateColor r.State
                                ]
                                td [ Style [TextAlign TextAlignOptions.Center ] ] [
                                    Controls.actionButton [ Title "Edytuj" ] "fa fa-edit" dispatch (Edit r.Id)
                                    if r.WarningButton then 
                                        str " "
                                        Controls.actionButton [ Title "Wyślij ponaglenie" ] "fa fa-bell" dispatch (WarnOnPrepaymentDelay r.Id)
                                ]
                            ]
                    ]
                ]
            ]

            Modals.info "prepaymentDelayWarningConfirmation" "Informacja"
                (p [] [ str "Ponaglenie w związku z opóźnieniem wpłaty zaliczki zostało wysłane." ])
        ]

    
    let getReservationSummariesCmd parentId showAll =
        match parentId with
        | Parent.Owner ->
            Cmd.OfAsync.either id (reservationApi.GetOwnerReservationSummaries showAll) (fun items -> ReservationsLoaded (parentId, items)) (ErrorHandling.unpack ShowError "Nie udało się wczytać listy rezerwacji.")
        | Parent.Resort (resortId, _) ->
            Cmd.OfAsync.either id (reservationApi.GetResortReservationSummaries (resortId, showAll)) (fun items -> ReservationsLoaded (parentId, items)) (ErrorHandling.unpack ShowError "Nie udało się wczytać listy rezerwacji.")
        | Parent.Room (roomId, _) ->
            Cmd.OfAsync.either id (reservationApi.GetRoomReservationSummaries (roomId, showAll)) (fun items -> ReservationsLoaded (parentId, items)) (ErrorHandling.unpack ShowError "Nie udało się wczytać listy rezerwacji.")

    let warnOnPrepaymentDelayCmd reservationId =
        Cmd.OfAsync.either id (reservationApi.WarnOnPrepaymentDelay reservationId) (fun () -> PrepaymentDelayWarningSent) (ErrorHandling.unpack ShowError "Nie udało się wysłać ponaglenia.")

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | ReservationsLoaded (parentId, reservations) ->
            Model.FromSummaryList(parentId, model.ShowAll, reservations), Cmd.none
        | RefreshReservationList ->
            model, getReservationSummariesCmd model.Parent model.ShowAll
        | ShowAll toggle ->
            { model with ShowAll = toggle }, getReservationSummariesCmd model.Parent toggle
        | WarnOnPrepaymentDelay reservationId ->
            model, warnOnPrepaymentDelayCmd reservationId
        | PrepaymentDelayWarningSent ->
            Modals.show "prepaymentDelayWarningConfirmation"
            model, Cmd.none
        | _ ->
            model, Cmd.none

    let subscribe () =
        let sub dispatch =
            Browser.Dom.window.setInterval (
                    (fun _ ->
                        if Browser.Dom.document.getElementById("prevent-subscriptions") = null then
                            dispatch RefreshReservationList
                    ),
                    1000*60)
            |> ignore
        Cmd.ofSub sub

type Msg =
    | FormMsg of ReservationDetails.Msg
    | ListMsg of ReservationList.Msg
    | Close 
    | ShowError of string * ErrorInfo

type Model =
    | Details of Parent * bool * ReservationDetails.Model
    | Items of ReservationList.Model 
    static member Empty = Items ReservationList.Model.Empty

let init (parent: Parent): Model * Cmd<Msg> =
    Items ReservationList.Model.Empty, Cmd.map ListMsg (ReservationList.getReservationSummariesCmd parent false)

let view (model : Model) (dispatch : Msg -> unit) =
    React.ofList [
        Components.contentHeader "Rezerwacje" "" //[] dispatch
        div [ Class "content" ] [
            div [ Class "row" ] [
                div [ Class "col-xs-12" ] [
                    match model with
                    | Items reservations -> ReservationList.view reservations (ListMsg >> dispatch)
                    | Details (_, _, reservation) -> ReservationDetails.view reservation (FormMsg >> dispatch)
                ]
            ]
        ]
    ]

let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
    match msg, model with
    | ListMsg (ReservationList.Edit resId), Items model ->
        Details (model.Parent, model.ShowAll, ReservationDetails.Model.Empty), Cmd.map FormMsg (ReservationDetails.getReservationCmd resId)
    | ListMsg (ReservationList.ShowError (msg, err)), _
    | FormMsg (ReservationDetails.ShowError (msg, err)), _ ->
        model, Cmd.ofMsg (ShowError (msg, err))
    | FormMsg ReservationDetails.Close, Details (parent, showAll, _) ->
        Items { ReservationList.Model.Empty with Parent = parent; ShowAll = showAll }, Cmd.map ListMsg (ReservationList.getReservationSummariesCmd parent showAll) 
    | ListMsg msg, Items items ->
        let lsModel, lsMsg = ReservationList.update msg items
        Items lsModel, Cmd.map ListMsg lsMsg
    | FormMsg msg, Details (parent, showAll, details) ->
        let frmModel, frmCmd = ReservationDetails.update msg details
        Details (parent, showAll, frmModel), Cmd.map FormMsg frmCmd
    | _ ->
        JS.console.error (sprintf "UNHADLED MESSAGE: %A" msg)
        model, Cmd.none


let subscribe () = Cmd.map ListMsg (ReservationList.subscribe ())