module App

open Elmish
open Elmish.React

#if DEBUG
open Elmish.Debug
#endif
open Elmish.HMR
open Elmish.Navigation


Program.mkProgram Pages.init Pages.update Pages.view
|> Program.withSubscription Pages.subscribe
|> Program.toNavigable Pages.urlParser Pages.urlUpdate
#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.withReactSynchronous "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run

