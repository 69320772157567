module MakeReservation

open System
open Elmish
open Fable.React
open Fable.React.Props
open Commons
open Commons.Reservations
open Shared.Resorts
open Shared.Rooms
open Shared.Calculations
open Shared.Reservations
open Fable.Core
open Fable.Remoting.Client
open Shared
open Shared.Validation
open Shared.PaymentMethods
open Shared.ServerError


type ReservationParams =
    | ReservationId of int * Guid
    | RoomId of int
    | ResortId of int
    | PartnerKey of string * string
    member this.IdentifiesReservation =
        match this with
        | ReservationId _ -> true
        | _ -> false
    member this.IdentifiesRoom =
        match this with
        | ReservationId _ 
        | RoomId _ -> true
        | _ -> false

let reservationApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IOrdererReservationApi>

module Header =

    type Msg =
        | CommonsMsg of HeaderCommons.Msg
        | ShowError of string * ErrorInfo

    type Model = HeaderCommons.Model

    let mapCommonsMsg = function HeaderCommons.ShowError (m, e) -> ShowError (m, e) | m -> CommonsMsg m

    let init(): Model * Cmd<Msg> =
        let cmModel, cmCmd = HeaderCommons.init "orderer-panel.html"
        cmModel, Cmd.map mapCommonsMsg cmCmd

    let update(msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | CommonsMsg msg ->
            let cmModel, cmCmd = HeaderCommons.update msg model 
            model, Cmd.map mapCommonsMsg cmCmd
    
    let view (model : Model) (dispatch : Msg -> unit) =
        HeaderCommons.view (React.ofList []) model (CommonsMsg >> dispatch)


module Sidebar =

    type Msg =
        | CommonsMsg of SidebarCommons.Msg
        | ShowReservation
        | ShowRooms
        | ShowError of string * ErrorInfo
    
    type Model =
        {
            Commons     : SidebarCommons.Model
            LastMenuMsg : Msg
        }

    let mapCommonsMsg = function SidebarCommons.ShowError (m, e) -> ShowError (m, e) | m -> CommonsMsg m

    let init(p: ReservationParams): Model * Cmd<Msg> =
        let cmModel, cmCmd = SidebarCommons.init()
        {   Commons = cmModel
            LastMenuMsg =
                match p with
                | ReservationId _
                | RoomId _ -> ShowReservation
                | PartnerKey _
                | ResortId _ -> ShowRooms
        }, Cmd.map mapCommonsMsg cmCmd   

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | CommonsMsg msg ->
            let cmModel, cmCmd = SidebarCommons.update msg model.Commons
            { model with Commons = cmModel }, Cmd.map mapCommonsMsg cmCmd
        | m ->
            { model with LastMenuMsg = m }, Cmd.none

    let menuItem model name icon dispatch msg =
        SidebarCommons.menuItem model name icon (model.LastMenuMsg = msg) dispatch msg
    
    let menuItemWithLabels model name icon labels dispatch msg =
        SidebarCommons.menuItemWithLabels model name icon (model.LastMenuMsg = msg) labels dispatch msg

    let sidebarMenu (model: Model) (dispatch: Msg -> unit) =    
        ul [ Class "sidebar-menu tree"; Data("widget", "tree") ] [
            li [ Class "header" ] [ str "PRZEJDŹ DO" ]
            menuItem model "Rezerwacja" "fa fa-calendar" dispatch ShowReservation
            menuItem model "Wolne terminy" "fa fa-bed" dispatch ShowRooms
        ]

    let view (model : Model) (dispatch : Msg -> unit) =
        SidebarCommons.view (sidebarMenu model dispatch) model.Commons (CommonsMsg >> dispatch)

module RoomDetails =

    let galleryApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IGalleryApi>

    type Msg =
        | PicturesLoaded of string list
        | Close
        | ShowError of string * ErrorInfo

    type Model =
        {
            Resort          : Resort
            Room            : Room
            Pictures        : string list
            PriceLists      : (PriceList * ApplicationScope) list
        }
        static member Empty =
            {
                Resort = Resort.Empty
                Room = Room.Empty
                Pictures = []
                PriceLists = []
            }

    let loadGalleryCmd roomId =
         Cmd.OfAsync.either id (galleryApi.Load roomId) PicturesLoaded (ErrorHandling.unpack ShowError "Nie udało się pobrać zdjęć")

    let buildPriceLists (resort: Resort, room: Room) =
        let scopes = resort.PriceListTemplates |> List.map (fun pt -> pt.Id, pt.Scopes) |> Map.ofList
        [ for pl in room.PriceLists do
            for sc in scopes.[pl.TemplateId] do
                pl, sc
        ] |> List.sortBy (fun (_, sc) -> sc.From)

    let init(resort: Resort, room: Room): Model * Cmd<Msg> =
        { Resort = resort; Room = room; Pictures = []; PriceLists = buildPriceLists (resort, room) },
        loadGalleryCmd room.Id

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | PicturesLoaded pictures ->
            { model with Pictures = pictures }, Cmd.none
        | Close
        | ShowError _ -> failwith "Should be handled on upper level"

    let carousel roomId (pictures: string list) =
        div [ Id "pictures"; Class "carousel slide"; Data ("ride", "carousel"); Style [ Width "500px"; Margin "auto" ] ] [
            if pictures.IsEmpty then
                div [] [ str "Nie dodano jeszcze żadnych zdjęć" ]
            else
                ol [ Class "carousel-indicators" ] [
                    li [ Data("target", "#pictures"); Data("slide-to", "0"); Class "active"] []
                    for i in 1..pictures.Length - 1 do
                        li [ Data("target", "#pictures"); Data("slide-to", i.ToString()) ] []
                ]
                div [ Class "carousel-inner"; Role "listbox" ] [
                    div [ Class "item active" ] [ img [ Src <| sprintf "/gallery/room-%d/%s" roomId pictures.Head ]]
                    for pic in pictures.Tail do
                        div [ Class "item" ] [ img [ Src <| sprintf "/gallery/room-%d/%s" roomId pic ]]
                ]
                a [ Class "left carousel-control"; Href "#pictures"; Role "button"; Data ("slide", "prev") ] [
                    span [ Class "icon-prev"; AriaHidden true ] []
                    span [ Class "sr-only" ] [ str "poprzedni" ]
                ]
                a [ Class "right carousel-control"; Href "#pictures"; Role "button"; Data ("slide", "next") ] [
                    span [ Class "icon-next"; AriaHidden true ] []
                    span [ Class "sr-only" ] [ str "następny" ]
                ]
        ]

    let yearsLabel (age: int) = if age = 1 then "roku" else "lat"

    let daysLabel (days: int) = if days = 1 then "dzień" else "dni"

    let priceListSection shortTime (pl: PriceList) = 
        table [ Class "table" ] [
            tbody [] [
                tr [] [
                    th [ Style [ TextAlign TextAlignOptions.Right ] ] []
                    th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| sprintf "Ponad %d %s" shortTime (daysLabel shortTime) ]
                    th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| sprintf "Do %d %s" shortTime (daysLabel shortTime) ]
                    td [] []
                ]
                tr [] [
                    th [ Style [ TextAlign TextAlignOptions.Right; Width "200px" ] ] [ str "Dorośli:" ]
                    td [ Style [ TextAlign TextAlignOptions.Right; Width "120px" ] ] [ str <| Derived.ToString pl.LongTimePrice ]
                    td [ Style [ TextAlign TextAlignOptions.Right; Width "120px" ] ] [ str <| Derived.ToString pl.ShortTimePrice ]
                ]
                for ag in pl.AgeGroupPricings do
                    tr [] [
                        th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| sprintf "Dzieci do %d %s:" ag.AgeTo (yearsLabel ag.AgeTo) ]
                        td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Derived.ToString ag.LongTimePrice]
                        td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Derived.ToString ag.ShortTimePrice]
                    ]
                tr [] [
                    th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Niewykorzystane miejsce:" ]
                    td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Derived.ToString pl.LongEmptySpacePrice ]
                    td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Derived.ToString pl.ShortEmptySpacePrice ]
                ]
            ]
        ]

    let priceListTabs (model: Model) =
        div [ Class "nav-tabs-custom" ] [
            ul [Class "nav nav-tabs"] [
                li [ Class "active" ] [                        
                    a [ Href "#priceListStandard"; Data("toggle", "tab"); AriaExpanded true ] [
                        b [] [ str "Cennik podstawowy" ]
                    ]                        
                ]
                for pl, sc in model.PriceLists do
                    li [] [                        
                        a [ Href <| sprintf "#priceList-%d" pl.TemplateId; Data("toggle", "tab") ] [
                            b [] [ str <| sprintf "%s - %s" (sc.From.ToString("dd.MM.yyyy")) (sc.To.ToString("dd.MM.yyyy")) ]
                        ]                        
                    ]
            ]
            div [ Class "tab-content" ] [
                div [ Class "tab-pane active"; Id "priceListStandard" ] [
                    priceListSection model.Resort.ShortTime {
                        TemplateId = 0
                        LongTimePrice = Overwritten model.Room.LongTimePrice
                        ShortTimePrice= Overwritten model.Room.ShortTimePrice
                        LongEmptySpacePrice = model.Room.LongEmptySpacePrice
                        ShortEmptySpacePrice = model.Room.ShortEmptySpacePrice
                        AgeGroupPricings = model.Room.AgeGroupPricings
                    }
                ]
                for pl, _ in model.PriceLists do
                    div [ Class "tab-pane"; Id <| sprintf "priceList-%d" pl.TemplateId ] [
                        priceListSection model.Resort.ShortTime pl
                    ]                    
            ]
        ]

    let view (model : Model) (dispatch : Msg -> unit) =
        React.ofList [
            section [ Class "content-header" ] [
                h1 [] [ str model.Room.Name ]                
            ]            
            section [ Class "content" ] [
                div [ Class "box" ] [
                    div [ Class "box-header with-border" ] [                    
                        div [Class "box-tools" ] [                    
                            Controls.simpleButton [] "" " Zamknij" "fa fa-times" dispatch Close
                        ]
                        div [ Class "nav-tabs-custom" ] [
                            ul [Class "nav nav-tabs"] [
                                li [ Class "active" ] [                        
                                    a [ Href "#roomDescription"; Data("toggle", "tab"); AriaExpanded true ] [
                                        b [] [ str "Informacje" ]
                                    ]                        
                                ]
                                li [] [                        
                                    a [ Href "#roomGallery"; Data("toggle", "tab"); AriaExpanded false ] [
                                        b [] [ str "Zdjęcia" ]
                                    ]                        
                                ]
                            ]
                            div [ Class "tab-content" ] [
                                div [ Class "tab-pane active"; Id "roomDescription" ] [
                                    table [ Class "table" ] [
                                        tbody [] [
                                            tr [] [
                                                th [ Style [ TextAlign TextAlignOptions.Right; Width "200px" ] ] [ str "Liczba osób:" ]
                                                td [ ColSpan 3 ] [ str ([ model.Room.Capacity; model.Room.AdditionalCapacity ] |> List.filter ((<>) 0) |> List.map string |> String.concat " + ") ]
                                            ]
                                            tr [] [
                                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Opis:" ]
                                                td [ ColSpan 3; DangerouslySetInnerHTML { __html = model.Room.DetailedDescription } ] []
                                            ]
                                        ]
                                    ]
                                    priceListTabs model
                                ]
                                div [ Class "tab-pane"; Id "roomGallery" ] [
                                    carousel model.Room.Id model.Pictures
                                ]
                            ]
                        ]
                        
                    ]
                ]
                
            ]
        ]

module ChildAgeEditor =

    type Model =
        {
            EditedChildAge  : string
            Children        : int list
        }
        static member Empty = { EditedChildAge = ""; Children = [] }
        static member FromChildList childList = { EditedChildAge = ""; Children = childList }

    type Msg =
        | AddChildAge
        | RemoveChildAge of int
        | EditedChildAgeChanged of string

    let ageLabel age =
        if age = 0 then "poniżej 1 roku"
        elif age = 1 then "1 rok"
        elif age < 5 then sprintf "%d lata" age
        else sprintf "%d lat" age

    let view model dispatch =
        div [] [
            table [ Class "table" ] [
                tbody [] [                    
                    for i, age in model.Children |> List.mapi (fun i a -> i, a)  do
                        tr [] [
                            td [ Style [ PaddingLeft "0px" ] ] [ str (ageLabel age) ]
                            td [] [
                                Controls.actionButton [] "fa fa-times" dispatch (RemoveChildAge i)
                            ]
                        ]
                    tr [] [
                        td [ Style [ PaddingTop "0px"; PaddingLeft "0px" ] ] [
                            input [
                                Class "form-control";
                                Type "text";
                                OnChange (Event.str dispatch EditedChildAgeChanged)
                                Value model.EditedChildAge
                                MaxLength 2.0
                            ]
                        ]
                        td [ Style [ PaddingTop "0px" ] ] [
                            Controls.actionButton [] "fa fa-plus" dispatch AddChildAge 
                        ]
                    ]
                ]                
            ]
        ]
        

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | AddChildAge ->
            { model with
                EditedChildAge = ""
                Children = model.Children @ (Int32.TryParse model.EditedChildAge |> Option.FromTryParse |> Option.toList)
            }, Cmd.none
        | RemoveChildAge idx ->
            {model with Children = (model.Children |> List.take idx) @ (model.Children |> List.skip (idx + 1)) }, Cmd.none
        | EditedChildAgeChanged age ->
            { model with EditedChildAge = age }, Cmd.none


module AllocationEditor =

    type Model =
        {
            Id                          : int
            Adults                      : Derived<string>
            AdultsErrors                : string list
            Children                    : ChildAgeEditor.Model
            ChildrenErrors              : string list
            Arrangements                : Arrangement list
            ArrangementErrors           : string list
            Room                        : Room
            EditedArrangementTypeId     : int
            EditedArrangementQuantity   : string
            Resort                      : Resort
        }
        static member Empty =
            {
                Id                          = 0
                Adults                      = Derived.OfString ""
                AdultsErrors                = []
                Children                    = ChildAgeEditor.Model.Empty
                ChildrenErrors              = []
                Arrangements                = []
                ArrangementErrors           = []
                Room                        = Room.Empty
                EditedArrangementTypeId     = 0
                EditedArrangementQuantity   = ""
                Resort                      = Resort.Empty
            }
        static member FromAlloction (resort: Resort, rooms: Room list) (allocation: Allocation) =
            {
                    Id                          = allocation.Id
                    Room                        = rooms |> roomById allocation.RoomId
                    Adults                      = Derived.Map string allocation.Adults
                    AdultsErrors                = []
                    Children                    = ChildAgeEditor.Model.FromChildList(allocation.Children)
                    ChildrenErrors              = []
                    Arrangements                = allocation.Arrangements
                    ArrangementErrors           = []
                    EditedArrangementTypeId     = 0
                    EditedArrangementQuantity   = ""
                    Resort                      = resort

            }
        static member ToAllocation (model: Model) =
            {
                Id                  = model.Id
                RoomId              = model.Room.Id
                Adults              = model.Adults |> Derived.ParseInt
                Children            = model.Children.Children
                Arrangements        = model.Arrangements
                Price               = Uninitialised //model.Price
            }

    type Msg =
        | AdultsChanged of string
        | RoomChanged of int option
        | ChildAgeMsg of ChildAgeEditor.Msg
        | EditedArrangementChanged of int option
        | EditedArrangementQuantityChanged of string
        | AddArrangement
        | RemoveArrangement of Arrangement
        | RemoveAllocation
        | ReturnToRoomSelection


    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | AdultsChanged adults ->
            { model with Adults = Overwritten adults }, Cmd.none
        | ChildAgeMsg msg ->
            let chMdl, chCmd = ChildAgeEditor.update msg model.Children
            { model with Children = chMdl }, Cmd.map ChildAgeMsg chCmd
        | EditedArrangementChanged id ->
            { model with EditedArrangementTypeId = id |> Option.defaultValue 0 }, Cmd.none
        | EditedArrangementQuantityChanged quantity ->
            { model with EditedArrangementQuantity = quantity }, Cmd.none
        | AddArrangement ->
            { model with
                Arrangements = model.Arrangements @ [ {
                    Type = model.Resort.ArrangementTypes |> List.find (fun at -> at.Id = model.EditedArrangementTypeId)
                    Units = Int32.Parse model.EditedArrangementQuantity
                } ]
                EditedArrangementTypeId = 0
                EditedArrangementQuantity = ""
            }, Cmd.none
        | RemoveArrangement a ->
            { model with Arrangements = model.Arrangements |> List.filter ((<>) a) }, Cmd.none


    let arrangementEditor (model: Model) dispatch =        
        table [ Class "table" ] [
            tbody [] [
                tr [] [
                    th [ Style [ Width "70%"; PaddingLeft "0px" ]] [ str "Rodzaj" ]
                    th [] [ str "Ilość" ]
                    th [] [ ]
                ]
                for a in model.Arrangements do
                    tr [] [
                        td [ Style [ PaddingLeft "0px" ] ] [ str a.Type.Description ]
                        td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| a.Units.ToString() ]
                        td [] [ Controls.actionButton [] "fa fa-times" dispatch (RemoveArrangement a) ]
                    ]
                tr [] [
                    td [ Style [ PaddingLeft "0px" ] ] [
                        select [
                            Class "form-control"
                            OnChange (Event.int dispatch EditedArrangementChanged)
                            Value model.EditedArrangementTypeId 
                        ] [ option [ Value 0 ] [ str "" ]
                            for a in model.Room.Arrangements do
                                option [ Value a.Type.Id ] [ str a.Type.Description ]
                        ]
                    ]
                    td [] [
                        input [
                            Class "form-control"
                            Type "text"
                            OnChange (Event.str dispatch EditedArrangementQuantityChanged)
                            Value model.EditedArrangementQuantity
                            MaxLength 3.0
                        ]
                    ]
                    td [] [ Controls.actionButton [] "fa fa-plus" dispatch AddArrangement ]
                ]
            ]            
        ]
       
    let view (isMultiRoom: bool) (isFirst: bool) (model : Model) (dispatch : Msg -> unit) =
        React.ofList [

            div [ Class "box-header with-border" ] [
                h3 [ Class "box-title no-padding col-sm-10" ] [
                    div [ Class "box-body no-padding col-sm-1" ] [ b [] [ str <| model.Room.RoomType.SingularNominative + ":" ] ]
                    div [ Class "col-sm-6 no-padding " ] [ str model.Room.Name ]                    
                ]
                if isMultiRoom then
                    div [ Class "box-tools pull-right" ] [
                        Controls.simpleButton [] "" " Usuń" "fa fa-remove" dispatch RemoveAllocation
                    ]
                if isFirst then
                    div [ Class "box-tools pull-right" ] [
                        Controls.simpleButton [] ""
                            (if isMultiRoom then " Wybierz" else " Wybierz inny")
                            "fa fa-search"
                            dispatch ReturnToRoomSelection
                    ]
            ]

            div [ Class "box-body no-padding" ] [

                Forms.formGroup (not model.AdultsErrors.IsEmpty)
                <| Forms.inputWithLabel "col-sm-3" "col-sm-1" "adults" "Liczba dorosłych" 3.0                                           
                    (Derived.ToTag string "" model.Adults)
                    (Forms.errorsRight "col-sm-4" model.AdultsErrors)
                    (Event.str dispatch AdultsChanged)

                Forms.formGroup (not model.ChildrenErrors.IsEmpty)
                <| Forms.controlWithLabel "col-sm-3" "col-sm-2" "editedChildAge" "Dzieci"                                                
                    (Forms.errorsBelow model.ChildrenErrors)
                    (ChildAgeEditor.view model.Children (ChildAgeMsg >> dispatch))

                Forms.formGroup (not model.ArrangementErrors.IsEmpty)
                <| Forms.controlWithLabel "col-sm-3" "col-sm-4" "arrangements" "Dodatkowe wyposażenie"                                                
                    (Forms.errorsBelow model.ArrangementErrors)
                    (arrangementEditor model dispatch)
            ]
        ]

module ReservationDetails =

    type ViewType =
        | Editor
        | Summary

    
    type Model =
        {
            ViewType                    : ViewType
            Id                          : int
            FirstName                   : Derived<string>
            FirstNameErrors             : string list
            LastName                    : Derived<string>
            LastNameErrors              : string list
            Email                       : Derived<string>
            EmailErrors                 : string list
            Phone                       : Derived<string>
            PhoneErrors                 : string list
            Resort                      : Resort
            AllRooms                    : Room list
            BankAccount                 : BankAccount
            Orderer                     : OrdererData
            From                        : DateTime
            To                          : DateTime
            FromToErrors                : string list
            Arrival                     : string
            ArrivalErrors               : string list
            Price                       : Derived<decimal>
            PriceIsFixed                : bool
            Prepayment                  : Derived<decimal>
            PrepaymentIsFixed           : bool
            PrepaymentDeadline          : DateTime option
            Notes                       : string
            Allocations                 : AllocationEditor.Model list
            Messages                    : Message list
            NewMessage                  : string
            NewMessageErrors            : string list
            Secret                      : Guid

            TermsOfServiceConsent       : bool
            RodoConsent                 : bool

            Payments                    : Payment list
            BaseState                   : BaseState
            DetailedState               : Derived<DetailedState>

            IsMultiRoom                 : bool
        }
        static member Initial (viewType, secret) =
            {
                ViewType                    = viewType
                Id                          = 0
                Resort                      = Resort.Empty
                AllRooms                    = []
                BankAccount                 = BankAccount.Empty
                Orderer                     = OrdererData.Empty
                FirstName                   = Calculated ""
                FirstNameErrors             = []
                LastName                    = Calculated ""
                LastNameErrors              = []
                Email                       = Calculated ""
                EmailErrors                 = []
                Phone                       = Calculated ""
                PhoneErrors                 = []
                From                        = DateTime.Today
                To                          = DateTime.Today
                Arrival                     = ""
                ArrivalErrors               = []
                FromToErrors                = []
                Price                       = Calculated 0.0m
                PriceIsFixed                = false
                Prepayment                  = Calculated 0.0m
                PrepaymentIsFixed           = false
                PrepaymentDeadline          = None
                Notes                       = ""
                Allocations                 = []
                Messages                    = []
                NewMessage                  = ""
                NewMessageErrors            = []
                Secret                      = secret

                TermsOfServiceConsent       = false
                RodoConsent                 = false

                Payments                    = []
                BaseState                   = BaseState.New
                DetailedState               = Calculated DetailedState.New

                IsMultiRoom                 = true
            }

        member private this.UpdateInternalWith
                (updateReservation: Model -> Resort -> Room list -> Reservation -> Guid -> Model)
                (reservation: Reservation option, room: Room option, resort: Resort, allRooms: Room list, bankAccount: BankAccount, ordererData: OrdererData option, secret: Guid option) =
            let fromReservation =
                Option.map2 (updateReservation this resort allRooms)
                    (Option.map (recalcReservation(resort, allRooms, DateTime.Today)) reservation)
                    secret 
                    |> Option.defaultValue this
            let fromRoom =
                room
                |> Option.map (fun room ->
                    let allocation = fromReservation.Allocations |> List.tryHead |> Option.defaultValue AllocationEditor.Model.Empty
                    { fromReservation with Allocations = [ { allocation with Room = room } ] }) 
                |> Option.defaultValue fromReservation
            { fromRoom with
                Resort      = resort
                AllRooms    = allRooms 
                BankAccount = bankAccount
                Allocations = fromRoom.Allocations |> List.map (fun a -> { a with Resort = resort })
                Orderer     =
                    fromRoom.Orderer.OrdererId
                    |> Option.bind (fun id -> ordererData)
                    |> Option.defaultValue fromRoom.Orderer
            }
            
        member this.UpdateWith = this.UpdateInternalWith (fun (this: Model) resort allRooms (reservation: Reservation) secret ->
                    { this with
                        Id                          = reservation.Id
                        Resort                      = resort
                        AllRooms                    = allRooms
                        BankAccount                 = BankAccount.Empty
                        Orderer                     = reservation.Orderer
                        FirstName                   = Calculated reservation.Orderer.FirstName
                        FirstNameErrors             = []
                        LastName                    = Calculated reservation.Orderer.LastName
                        LastNameErrors              = []
                        Email                       = Calculated reservation.Orderer.Email
                        EmailErrors                 = []
                        Phone                       = Calculated reservation.Orderer.Phone
                        PhoneErrors                 = []
                        From                        = reservation.From
                        To                          = reservation.To
                        FromToErrors                = []
                        Arrival                     = reservation.DeclaredArrival |> Option.map (fun t -> t.ToString("HH:mm")) |> Option.defaultValue ""
                        ArrivalErrors               = []
                        Price                       = reservation.Price
                        PriceIsFixed                = reservation.PriceIsFixed
                        Prepayment                  = reservation.Prepayment
                        PrepaymentIsFixed           = reservation.PrepaymentIsFixed
                        PrepaymentDeadline          = Some reservation.PrepaymentDeadline
                        Notes                       = reservation.Notes
                        Messages                    = reservation.Messages
                        Allocations                 = reservation.Allocations |> List.map (AllocationEditor.Model.FromAlloction(resort, allRooms))
                        NewMessage                  = ""
                        NewMessageErrors            = []
                        Secret                      = secret

                        TermsOfServiceConsent       = reservation.Id <> 0
                        RodoConsent                 = reservation.Id <> 0

                        Payments                    = reservation.Payments
                        BaseState                   = reservation.BaseState
                        DetailedState               = Calculated reservation.DetailedState

                        IsMultiRoom                 = reservation.Allocations.Length > 1
                    })                    

        member this.RefreshUpdateWith = this.UpdateInternalWith (fun (this: Model) _ _ (reservation: Reservation) secret ->  
                    { this with
                        Price                       = reservation.Price
                        PriceIsFixed                = reservation.PriceIsFixed
                        Prepayment                  = reservation.Prepayment
                        PrepaymentIsFixed           = reservation.PrepaymentIsFixed
                        PrepaymentDeadline          = Some reservation.PrepaymentDeadline
                        Notes                       = reservation.Notes
                        Messages                    = reservation.Messages

                        Payments                    = reservation.Payments
                        BaseState                   = reservation.BaseState
                        DetailedState               = Calculated reservation.DetailedState
                    })                    

        static member UpdateOrdererData (orderer: OrdererData) (model: Model) =
            { model with
                Orderer     = orderer
                FirstName   = model.FirstName |> Derived.Recalc orderer.FirstName
                LastName    = model.LastName |> Derived.Recalc orderer.LastName
                Email       = model.Email |> Derived.Recalc orderer.Email
                Phone       = model.Phone |> Derived.Recalc orderer.Phone
            }
        static member ToReservation (model: Model): Reservation =
            {
                Id = model.Id
                ResortId = model.Resort.Id
                Orderer = {
                    OrdererId   =
                        if [ model.FirstName; model.LastName; model.Email; model.Phone ] |> List.exists Derived.IsOverwritten then
                            model.Orderer.OrdererId
                        else
                            None
                    FirstName   = model.FirstName.Value
                    LastName    = model.LastName.Value
                    Email       = model.Email.Value
                    Phone       = model.Phone.Value
                }
                From                = model.From
                To                  = model.To
                Allocations         = model.Allocations |> List.map AllocationEditor.Model.ToAllocation 
                Payments            = model.Payments
                Notes               = model.Notes
                Messages            = model.Messages
                Price               = model.Price
                PriceIsFixed        = model.PriceIsFixed
                Prepayment          = model.Prepayment
                PrepaymentIsFixed   = model.PrepaymentIsFixed
                PrepaymentDeadline  = model.PrepaymentDeadline |> Option.defaultValue DateTime.Today
                DeclaredArrival     = model.Arrival |> DateTime.TryParse |> Option.FromTryParse |> Option.map (fun d -> System.DateTime(d.Ticks, DateTimeKind.Utc))
                BaseState           = model.BaseState
                DetailedState       = model.DetailedState.Value
            }
        static member UpdateDerived (reservation: Reservation) (model: Model) =
            { model with
                Price               = reservation.Price
                Prepayment          = reservation.Prepayment
                PrepaymentDeadline  = Some reservation.PrepaymentDeadline
                BaseState           = reservation.BaseState
                DetailedState       = Calculated (if reservation.Id = 0 then DetailedState.New else reservation.DetailedState)
            }
        static member ToCalculationData (model: Model) =
            {
                From                = model.From
                To                  = model.To
                Allocations         = model.Allocations |> List.map AllocationEditor.Model.ToAllocation 
                Payments            = model.Payments
                Price               = model.Price
                Prepayment          = model.Prepayment
                PrepaymentDeadline  = model.PrepaymentDeadline |> Option.defaultValue DateTime.MaxValue
                BaseState           = model.BaseState
                DetailedState       = model.DetailedState
            }
        static member FromCalculationData (model: Model) (data: ReservationCalculationData) =
            { model with
                Price               = data.Price
                Prepayment          = data.Prepayment
                PrepaymentDeadline  = Some data.PrepaymentDeadline
                BaseState           = data.BaseState
                DetailedState       = if model.Id = 0 then Calculated DetailedState.New else data.DetailedState
            }
        static member Recalc (model: Model) =
            model
            |> Model.ToCalculationData
            |> calculateReservation (model.Resort, (model.Allocations |> List.map (fun a -> a.Room)), DateTime.Today)
            |> Model.FromCalculationData (model |> Model.UpdateOrdererData model.Orderer)
        static member RecalcP (model: Model) =
            Model.Recalc
                { model with
                    Price = if model.PrepaymentIsFixed then model.Price else model.Price |> Derived.Rewrap Calculated 
                    Prepayment = if model.PrepaymentIsFixed then model.Prepayment else model.Prepayment |> Derived.Rewrap Calculated 
                }
        static member Validate (model: Model) =
            [
                for i, a in model.Allocations |> List.indexed do
                    yield! Validation.checkIfDerivedValidInt "Liczba dorosłych" a.Adults |> Validation.forField (sprintf "Adults%d" i)
                yield! Validation.checkIfValidOrEmptyTime "Godzina przyjazdu" model.Arrival |> Validation.forField "Arrival"
            ]
        static member WithErrors (errors: (string * string) list) (model: Model) =
            {
                model with
                    FirstNameErrors     = errors |> getErrorsOf "FirstName"
                    LastNameErrors      = errors |> getErrorsOf "LastName"
                    EmailErrors         = errors |> getErrorsOf "Email"
                    PhoneErrors         = errors |> getErrorsOf "Phone"
                    FromToErrors        = (errors |> getErrorsOf "From") @ (errors |> getErrorsOf "To")
                    ArrivalErrors       = errors |> getErrorsOf "Arrival"
                    Allocations         = model.Allocations |> List.mapi (fun i a ->
                                { a with
                                    AdultsErrors        = errors |> getErrorsOf (sprintf "Adults%d" i)
                                    ChildrenErrors      = errors |> getErrorsOf (sprintf "Children%d" i)
                                    ArrangementErrors   = errors |> getErrorsOf (sprintf "ArrangementVariants%d" i)
                                })
                    NewMessageErrors    = errors |> getErrorsOf "NewMessage"
            }
        static member Update (model: Model) =
            let temp =
                { model with
                    Price = model.Price |> Derived.Rewrap Overwritten 
                    Prepayment = model.Prepayment |> Derived.Rewrap Overwritten 
                }
            if not <| String.IsNullOrEmpty model.NewMessage then
                { temp with
                    Messages = model.Messages @ [{ AddedAt = DateTime.Now; Party = Party.Guest;  Content = model.NewMessage }]
                    NewMessage = ""
                }
            else
                temp
        static member UpdateOccupancies (reservation: Reservation) (model: Model) =
            { model with AllRooms = model.AllRooms |> List.map (updateOccupancies reservation) }
        static member FixAllocations (model: Model) =
            { model with
                Allocations = model.Allocations |> List.map (fun a -> { a with Adults = Overwritten a.Adults.Value })
            }


    type Msg =
        | FirstNameChanged of string
        | LastNameChanged of string
        | EmailChanged of string
        | PhoneChanged of string
        | AdultsChanged of string
        | FromToChanged of DateTime * DateTime
        | ArrivalChanged of string
        | AllocationMsg of int * AllocationEditor.Msg
        | NewMessageChanged of string
        | RodoChanged of bool
        | TermsOfServiceChanged of bool
        | Save
        | SaveCompleted of int
        | ReturnToRoomSelection
        | SendMessage
        | SendMessageCompleted
        | ModifyReservation
        | CancelReservation
        | CancellationCompleted 
        | ShowError of string * ErrorInfo

    let saveReservationCmd (reservation, secret) =
        Cmd.OfAsync.either id (reservationApi.SaveReservation (reservation, secret)) SaveCompleted (ErrorHandling.unpack ShowError "Nie udało się zapisać zmian")

    let cancelReservationCmd (reservationId, content, secret) =
        Cmd.OfAsync.either id (reservationApi.CancelReservation (reservationId, content, secret)) (fun _ -> CancellationCompleted) (ErrorHandling.unpack ShowError "Nie udało się anulować rezerwacji")

    let sendMessageCmd (reservationId, content, secret) =
        Cmd.OfAsync.either id (reservationApi.SendMessage (reservationId, content, secret)) (fun _ -> SendMessageCompleted) (ErrorHandling.unpack ShowError "Nie udało się wysłać wiadomości")

    //let init(reservation: Reservation option, room: Room option, resort: Resort, allRooms: Room list, bankAccount: BankAccount, ordererData: OrdererData option, secret: Guid option): Model * Cmd<Msg> =
    //    Model.Empty(ViewType.Editor).UpdateWith(reservation, room, resort, allRooms, bankAccount, ordererData, secret), Cmd.none
    
    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | FirstNameChanged name ->
            { model with FirstName = Derived.OfString name } |> Model.Recalc, Cmd.none
        | LastNameChanged name ->
            { model with LastName = Derived.OfString name } |> Model.Recalc, Cmd.none
        | EmailChanged email ->
            { model with Email = Derived.OfString email } |> Model.Recalc, Cmd.none
        | PhoneChanged phone ->
            { model with Phone = Derived.OfString phone } |> Model.Recalc, Cmd.none
        | FromToChanged (dateFrom, dateTo) ->
            { model with From = dateFrom; To = dateTo } |> Model.RecalcP, Cmd.none
        | AllocationMsg (idx, AllocationEditor.Msg.RemoveAllocation)  ->
            let x, y = model.Allocations |> List.splitAt idx
            { model with Allocations = x @ (List.tail y) } |> Model.RecalcP, Cmd.none
        | AllocationMsg (_, AllocationEditor.Msg.ReturnToRoomSelection) ->
            model, Cmd.ofMsg ReturnToRoomSelection
        | AllocationMsg (idx, amsg) ->
            let amdl, acmd = AllocationEditor.update amsg model.Allocations.[idx]
            { model with
                Allocations = model.Allocations |> List.mapi (fun i a -> if i = idx then amdl else a)
            } |> Model.RecalcP,
            Cmd.map (fun msg -> AllocationMsg (idx, msg)) acmd
        | ArrivalChanged time ->
            { model with Arrival = time }, Cmd.none
        | NewMessageChanged cmt ->
            { model with NewMessage = cmt }, Cmd.none
        | RodoChanged flag ->
            { model with RodoConsent = flag }, Cmd.none
        | TermsOfServiceChanged flag ->
            { model with TermsOfServiceConsent = flag }, Cmd.none
        | Save ->
            let errors =
                model
                |> Validation.combine
                    Model.Validate
                    (Model.ToReservation >> validateReservation (model.Resort, model.Allocations |> List.map (fun a -> a.Room), DateTime.Today))
            let model = model |> Model.WithErrors errors
            if errors.IsEmpty then
                let model = model |> Model.Update
                let res = model |> Model.ToReservation
                model, saveReservationCmd (model |> Model.FixAllocations |> Model.ToReservation, model.Secret)
            else
                model, Cmd.none
        | SaveCompleted id ->
            if model.Id = 0 then
                Browser.Dom.window.location.href <- sprintf "make-reservation?id=%d&uid=%A" id model.Secret
            { model with Id = id; ViewType = ViewType.Summary }, Cmd.none
        | ModifyReservation ->
            { model with ViewType = ViewType.Editor }, Cmd.none
        | SendMessage ->
            model, sendMessageCmd (model.Id, model.NewMessage, model.Secret)
        | SendMessageCompleted ->
            model |> Model.Update, Cmd.none
        | CancelReservation ->
            model, cancelReservationCmd (model.Id, model.NewMessage, model.Secret)
        | CancellationCompleted ->
            { model with BaseState = BaseState.Cancelled } |> Model.Update |> Model.Recalc, Cmd.none
            
        
    let messageEditor (model: Model) dispatch =
        div [ Class "col-xs-8" ] [            
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        for cmt in model.Messages do
                            tr [] [
                                td [] [
                                    div [] [
                                        b [] [ str (match cmt.Party with
                                                    | Party.Guest -> "Ja"
                                                    | Party.Resort -> "Właściciel")
                                        ]
                                        span [ Class "pull-right" ] [ str <| cmt.AddedAt.ToString("dd.MM.yyyy") ]
                                    ]
                                    div [] [ str cmt.Content ]                                    
                                ]                                
                            ]                            
                    ]
                ]

                Forms.formGroup (not model.NewMessageErrors.IsEmpty)
                <| Forms.textareaWithLabel "col-sm-3" "col-sm-12" 3 "newMessage" "Nowa wiadomość:" 1024.0
                    model.NewMessage
                    (Forms.errorsBelow model.NewMessageErrors)
                    (Event.str dispatch NewMessageChanged)
            ]
        ]

    let editorView (model : Model) (dispatch : Msg -> unit) =
        React.ofList [

            input [ Hidden true; Id "prevent-subscriptions" ]

            div [ Class "box box-info" ] [
                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title col-sm-10 no-padding" ] [
                        div [ Class "box-body no-padding col-sm-1" ] [ b [] [ str "Obiekt:" ] ]
                        div [ Class "col-sm-6 no-padding " ] [ str model.Resort.Name ]                    
                    ]
                ]

                div [ Class "box-body no-padding" ] [
                    form [ Class "form-horizontal" ] [

                        Forms.formGroup (not model.FromToErrors.IsEmpty)
                        <| Forms.dateRangePickerWithLabel "col-sm-3" "col-sm-3" "fromTo" "Termin"
                            (model.From, model.To)
                            (Forms.errorsBelow model.FromToErrors)
                            dispatch FromToChanged
                        
                        Forms.formGroup (not model.ArrivalErrors.IsEmpty)
                        <| Forms.timeMaskedInputWithLabel "col-sm-3" "col-sm-3" "arrival" "Godzina przyjazdu"
                            model.Arrival
                            (Forms.errorsBelow model.ArrivalErrors)
                            dispatch ArrivalChanged

                        for i, a in model.Allocations |> List.indexed do
                            AllocationEditor.view model.IsMultiRoom (i = 0) a ((fun msg -> AllocationMsg (i, msg)) >> dispatch)                            
                    ]
                ]

                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Kalkulacja" ]
                ]

                div [ Class "box-body no-padding" ] [
                    table [ Class "table" ] [
                        tbody [] [
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right; Width "25%" ] ] [ str "Cena:" ]
                                td [] [ str <| Derived.ToString model.Price ]

                            ]
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Zaliczka:" ]
                                td [] [ str <| Derived.ToString model.Prepayment ]
                            ]
                        ]
                    ]
                ]

                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Zamawiający" ]
                ]

                div [ Class "box-body no-padding" ] [
                    form [ Class "form-horizontal" ] [
                        Forms.formGroup (not model.FirstNameErrors.IsEmpty || not model.LastNameErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-3" "firstName" "Imię" 50.0                                        
                            (Derived.ToTag id "" model.FirstName)
                            (Forms.errorsBelow model.FirstNameErrors)
                            (Event.str dispatch FirstNameChanged)
                            @ Forms.inputWithLabel "col-sm-2" "col-sm-3" "lastName" "Nazwisko" 50.0                                           
                            (Derived.ToTag id "" model.LastName)
                            (Forms.errorsBelow model.LastNameErrors)
                            (Event.str dispatch LastNameChanged)

                        Forms.formGroup (not model.EmailErrors.IsEmpty || not model.PhoneErrors.IsEmpty)
                        <| Forms.inputWithLabel "col-sm-3" "col-sm-3" "email" "Email" 120.0                                             
                            (Derived.ToTag id "" model.Email)
                            (Forms.errorsBelow model.EmailErrors)
                            (Event.str dispatch EmailChanged)
                            @ Forms.inputWithLabel "col-sm-2" "col-sm-3" "phone" "Telefon" 12.0                                              
                            (Derived.ToTag id "" model.Phone)
                            (Forms.errorsBelow model.PhoneErrors)
                            (Event.str dispatch PhoneChanged)
                    ]
                ]

                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Zgody" ]
                ]

                div [ Class "box-body no-padding" ] [
                    form [ Class "form-horizontal" ] [

                        Forms.formGroup false [
                            div [ Class "col-sm-1"; Style [ Width "30px"; MarginLeft "10px" ] ] [
                                Controls.checkbox "rodoConsent" [] "" model.RodoConsent dispatch RodoChanged
                            ]
                            div [ Class "col-sm-10" ] [
                                p [] [
                                    str "Wyrażam zgodę na przechowywanie i przetwarzanie moich danych osobowych
                                        przez serwis  Quatera.pl w celach związanych z obsługą niniejszej rezerwacji."
                                ]
                                p [] [
                                    str "Zgoda zostaje wyrażona zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady UE 2016/679
                                        z dn. 27.04.2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych 
                                        i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                                        (ogólne rozporządzenie o ochronie danych, zwane także RODO)."
                                ]
                                a [ Style [ Cursor "pointer" ]; Data("toggle", "modal"); Data("target", "#rodoStatementView") ] [
                                    str "Informacja o przetwarzaniu danych osobowych"
                                ]
                            ]                            
                        ]
                                               
                        Forms.formGroup false [
                            div [ Class "col-sm-1"; Style [ Width "30px"; MarginLeft "10px" ] ] [
                                Controls.checkbox "termsOfServiceConsent" [] "" model.TermsOfServiceConsent dispatch TermsOfServiceChanged
                            ]
                            div [ Class "col-sm-10" ] [
                                str "Zapoznałem się z "
                                a [ Style [ Cursor "pointer" ]; Data("toggle", "modal"); Data("target", "#termsOfServiceView") ] [
                                    str "regulaminem serwisu"
                                ]
                                str " i akceptuję jego treść."
                            ]
                        ]
                    ]
                ]

                div [ Class "box-footer" ] [
                    div [ Class "pull-right"] [
                        Controls.simpleButton [ Disabled (not model.RodoConsent || not model.TermsOfServiceConsent) ] "btn-primary" " Zapisz" "fa fa-save" dispatch Save
                        //str " "
                        //Controls.simpleButton [] "btn-default" " Wróć do wyszukiwania" "fa fa-search" dispatch ReturnToRoomSelection
                    ]                        
                ]
            ]

            Modals.info "rodoStatementView"
                "Informacja o przetwarzaniu danych osobowych"
                (React.ofList [
                    p [] [
                        str "Zgodnie z art. 13 rozporządzenia Parlamentu Europejskiego i Rady UE 2016/679 z dn. 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, zwane dalej RODO) informujemy, że administratorem Twoich danych jest Helix Jacek Hełka. z siedzibą we Wrocławiu przy ulicy ... Wrocław, e-mail: helix@quatera.pl, zwana dalej Administratorem."
                    ]
                    p [] [
                        str "Twoje dane osobowe przetwarzane będą w celu zorganizowania warsztatu. Podstawą prawną przetwarzania jest zgoda na przetwarzanie danych osobowych, czyli art. 6 ust 1 lit. a RODO."
                    ]
                    p [] [
                        str "Odbiorcami Twoich danych będą firmy prowadzące serwis IT systemów informatycznych używanych przez Administratora, biuro rachunkowe i firmy świadczące usługi pocztowe na rzecz Administratora oraz podmioty upoważnione na podstawie przepisów prawa. 
                             Dane będą przetwarzane najpóźniej jeden miesiąc od dnia zakończenia rezerwacji."
                    ]
                    p [] [
                        str "Posiadasz prawo dostępu do swoich danych, żądania sprostowania lub ograniczenia przetwarzania danych osobowych oraz prawo do cofnięcia zgody w dowolnym momencie. W przypadku cofnięcia zgody masz prawo do żądania usunięcia swoich danych osobowych 
                             Przysługuje Ci prawo wniesienia skargi do organu nadzorczego, którym jest Urząd Ochrony Danych Osobowych."
                    ]
                    p [] [
                        str "Twoje dane nie podlegają zautomatyzowanemu podejmowaniu decyzji, w tym profilowaniu."
                    ]
                ])
                    
            Modals.info "termsOfServiceView"
                "Regulamin serwisu"
                (div [] [
                    h3 [] [ str "I.  Postanowienia ogólne" ]
                    p [] [
                        str "Niniejszy Regulamin określa ogólne warunki, zasady oraz sposób korzystania z serwisu Quatera, przez Klientów zerjestrowanych w tym serwisie obiektów."
                    ]
                    p [] [
                        str "Przez "; b[] [ str "Obiekt" ]; str " rozumie się w niniejszym regulaminie dowolny hotel, pensjonat, hostel czy gospodarstwo agroturystyczne, zarejestrowany
                        w serwisie Quatera i zawierający pokoje, które można za pośrednictwem tego systemu rezerwować."
                    ]
                    p [] [
                        str "Przez "; b [] [str "Klienta"]; str " rozumie się w niniejszym regulaminie, osobą fizyczną, z którą Obiekt zawiera umowę o świadczenie usług lub sprzedaż produktów lub usług."
                    ]
                    p [] [
                        str "Rezerwacja za pomocą serwisu Quatera przebiega według wskazanych poniżej postanowień."
                    ]
                    p [] [
                        str "Rezerwacja dokonana przez serwis Quatera jest rezerwacją wstępną, którą Obiekt może odwołać w ciągu 24 godzin.
                            W przypadku, gdy właściciel obiektu zatwierdzi rezerwację, bądź zostanie ona zatwierdzona automatycznie,
                            zgodnie z konfiguracją obiektu, staje się ona wiążąca."

                    ]
                    h3 [] [ str "II. Dokonywanie Rezerwacji" ]
                    p [] [
                        str "Klient otrzymuje możliwość wyboru terminu pobytu jakim jest zainteresowany, liczby dorosłych oraz liczby dzieci, według których ma zamiar dokonać rezerwacji pobytu."
                    ]
                    p [] [
                        str "Klient może wybrać następnie wśród dostępnych we wskazanym terminie wolnych pokoi, które przedstawione są w formie listy Rezerwacji Online."
                    ]
                    p [] [
                        str "Po zapoznaniu się z opisem, wyposażeniem i ceną pokoju, Klient może wybrać dany pokój i przejść do kolejnego kroku procesu rezerwacji."
                    ]
                    p [] [
                        str "W drugim kroku dokonywania rezerwacji - formularzu, Klient wypełnia swoje dane osobowe oraz ewentualne uwagi do rezerwacji."
                    ]
                    p [] [
                        str "Po uzupełnieniu danych, w kroku trzecim, Klient może dokonać wpłaty zadatku za pomocą płatności elektronicznej - przelew transferowy,
                            bądź wykonując przelew tradycyjny wykorzystując dane rachunku bankowego obiektu, odostępnione na formularzu rezerwacji."
                    ]
                    p [] [
                        str "Transakcje przelewami transferowymi są realizowane za pośrednictwem systemu płatności online - BlueMedia."
                    ]
                    p [] [
                        str "Przy wybraniu płatności elektronicznej przelewem transferowym, Klient zostaje przekierowany na stronę umożliwiającą wpłatę zadatku, systemem płatności online BlueMedia.
                            Po przyjęciu wpłaty przez BlueMedia, Klient otrzymuje na wskazany przy dokonywaniu rezerwacji adres e-mail, automatyczne powiadomienie o potwierdzeniu wpłaty zadatku."
                    ]
                    h3 [] [ str "III. Odwołanie rezerwacji" ]
                    p [] [
                        str "W przypadku odwłania rezerwacji przez Klienta, wpłacony zadatek pozostaje w obiekcie.
                            Obiekt ma prawo do odrzucenia rezerwacji, w terminie do 24 godzin, od momentu zaksięgowania wpłaty zadatku. Wówczas, zadatek zostaje zwrócony Klientowi."
                    ]
                    h3 [] [ str "IV. Opłaty" ]
                    p [] [
                        str "Klient zobowiązany jest do wpłaty wskazanego przy dokonywaniu rezerwacji zadatku. Może tego dokonać za pomocą:"
                        ul [] [
                            li [] [ str "płatności elektronicznej - przelew transferowy" ]
                            li [] [ str "przelewu tradycyjnego na rachunek podany przez właściciela obiektu" ]
                        ]
                    ]
                    p [] [
                        str "Pozostała część opłaty za cały pobyt dopłacana jest przez Klienta po przyjeździe do obiektu."
                    ]
                    p [] [
                        str "Klient nie ponosi żadnych kosztów związanych z wykonaniem procesu Rezerwacji Online."
                    ]
                    h3 [] [ str "V. Polityka prywatności" ]
                    p [] [
                        str "W trakcie dokonywania Rezerwacji Online, Klient wyraża zgodę na umieszczenie swoich danych osobowych w bazie serwisu.
                            Klient ma prawo do dostępu do swoich danych oraz ich poprawiania i usuwania."
                    ]
                    p [] [
                        str "Dane Klienta będą wykorzystywane wyłącznie w celu umożliwienia przeprowadzenia prawidłowego procesu rezerwacji i nie będą udostępniane osobom trzecim, zgodnie z przepisami ustawy z dnia 28.09.1997 o ochronie danych osobowych."
                    ]
                    h3 [] [ str "VI. Odpowiedzialności" ]
                    p [] [
                        str "Klient odpowiada za prawidłowe wpisanie prawdziwych danych w formularzu rezerwacyjnym."
                    ]
                    p [] [
                        str "Obiekt nie bierze odpowiedzialności za błędnie wpisane terminy rezerwacji, czy dane osobowe."
                    ]
                    p [] [
                        str "Za prawidłową obsługę pieniężną zadatku, w aplikacji Rezerwacji Online odpowiada system płatności online BlueMedia.
                            Obiekt oraz firma będąca właścicielem serwisu Quatera, czyli Helix Jacek Hełka, nie ponoszą odpowiedzialności za niedostępność systemu, która mogła wyniknąć nie z ich winy lub z powodu wystąpienia innych czynników niezależnych."
                    ]
                    h3 [] [ str "VII. Akceptacja Regulaminu" ]
                    p [] [
                        str "Zaznaczenie w panelu rezerwacyjnym opcji przeczytania i zapoznania się z regulaminem Rezerwacji Online oznacza, że Klient rozumie i wyraża zgodę na punkty zapisane w tymże regulaminie."
                    ]
                    p [] [
                        str "Niezaznaczenie opcji akceptacji regulaminu skutkuje brakiem możliwości dokonania Rezerwacji Online."
                    ]
                ])
        ]

    let adultsLabel (adults: string) =
        Int32.TryParse adults
        |> Option.FromTryParse
        |> Option.map (fun num ->
            match num % 10 with
            | 1 when num = 1 -> "osoba dorosła"
            | 2 | 3 | 4 -> "osoby dorosłe"
            | _ -> "osób doroslych")
        |> Option.defaultValue ""

    let childrenLabel ageList =
        match ageList |> List.last with
        | 1 -> "rok"
        | 2 | 3 | 4 -> "lata"
        | _ -> "lat"

    let reservationInfo model =
        div [ Class "col-xs-6" ] [
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right; Width "40%" ] ] [ str "Obiekt:" ]
                            td [] [ str model.Resort.Name ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right; Width "40%" ] ] [ str "Adres:" ]
                            let a = model.Resort.Address
                            td [] [ str <| sprintf "%s %s, %s %s" a.Street a.Number a.ZipCode a.City ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Termin:" ]
                            td [] [ str <| model.From.ToString("dd.MM.yyyy") + " - " + model.To.ToString("dd.MM.yyyy") ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Godzina przyjazdu:" ]
                            td [] [ str model.Arrival ]
                        ]
                        for a in model.Allocations do
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| a.Room.RoomType.SingularNominative + ":" ]
                                td [] [ str a.Room.Name ]
                            ]
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Liczba gości:" ]
                                td [] [
                                    span [] [str <| sprintf "%s %s" (Derived.ToString a.Adults) (Derived.ToString (Derived.Map adultsLabel a.Adults)) ]
                                    if not a.Children.Children.IsEmpty then
                                        span [] [ str <| sprintf ", dzieci: %s %s" (a.Children.Children |> List.map string |> String.concat ", ") (childrenLabel a.Children.Children) ]
                                ]
                            ]
                            tr [] [
                                th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Dodatki:" ]
                                td [] [
                                    if a.Arrangements.IsEmpty then
                                        str "Nic nie wybrano"
                                    else
                                        for a in a.Arrangements do
                                            span [] [ str <| sprintf "%s (%d)" a.Type.Description a.Units ]
                                            br []
                                ]
                            ]
                    ]
                ]
            ]                
        ]

    let ordererInfo model =
        div [ Class "col-xs-6" ] [
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right; Width "30%" ] ] [ str "Zamawiający:" ]
                            td [] [ str <| model.FirstName.Value + " " + model.LastName.Value ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Telefon:" ]
                            td [] [ a [ Href ("callto:" + model.Phone.Value) ] [str model.Phone.Value ] ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Email:" ]
                            td [] [ a [Href ("mailto:" + model.Email.Value) ] [ str model.Email.Value ] ]
                        ]                        
                    ]
                ]
            ]
        ]

    let paymentInfo model =
        div [ Class "col-xs-6" ] [
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right; Width "40%" ] ] [ str "Cena:" ]
                            td [] [ str <| Decimal.format 2 model.Price.Value ]

                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Zaliczka:" ]
                            td [] [ b [] [ str <| Decimal.format 2 model.Prepayment.Value ] ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Termin wpłaty zaliczki:" ]
                            td [] [ str (model.PrepaymentDeadline |> Option.map (fun d -> d.ToString("dd.MM.yyyy")) |> Option.defaultValue "") ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Wpłacono:" ]
                            td [
                                model.Payments
                                |> List.map (fun p -> sprintf "%s - %s zł" (p.PaidAt.ToString("dd.MM.yyyy")) (Decimal.format 2 p.Amount))
                                |> String.concat "\n"
                                |> Title
                            ] [ str (model.Payments |> List.sumBy (fun p -> p.Amount) |>  Decimal.format 2) ]
                        ]
                    ]
                ]
            ]
        ]

    let bankAccountInfo model =
        div [ Class "col-xs-6" ] [
            div [ Class "table-responsive" ] [
                table [ Class "table" ] [
                    tbody [] [
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right; Width "30%" ] ] [ str "Nr rachunku:" ]
                            td [] [ str (formatAccountNumber model.BankAccount.AccountNumber) ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Bank:" ]
                            td [] [ str model.BankAccount.BankName ]
                        ]
                        tr [] [
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Właściciel:" ]
                            td [] [ str model.BankAccount.OwnerName ]
                        ]
                        tr [] [
                            let a = model.BankAccount.OwnerAddress
                            th [ Style [ TextAlign TextAlignOptions.Right ] ] [ str "Adres:" ]
                            td [] [ str (sprintf "%s %s, %s %s" a.Street a.Number a.ZipCode a.City) ]
                        ]
                    ]
                ]
            ]
        ]


    let summaryView (model : Model) (dispatch : Msg -> unit) =
        React.ofList [

            div [ Class "box box-info" ] [
                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [ str "Sprawdź i w razie potrzeby skoryguj dane rezerwacji" ]                        
                ]
               
                div [ Class "box-body no-padding" ] [

                    div [ Class "box-body no-padding" ] [
                        reservationInfo model
                        ordererInfo model
                    ]

                    div [ Class "box-header with-border" ] [
                        h3 [ Class "box-title" ] [
                            match model.DetailedState with
                            | Overwritten state 
                            | Calculated state ->
                                match state with
                                | DetailedState.New ->
                                    str "Po otrzymaniu informacji o zatwierdzeniu rezerwacji wpłać zaliczkę zgodnie z poniższymi danymi do przelewu"
                                | DetailedState.Confirmed ->
                                    str "Wpłać zaliczkę zgodnie z poniższymi danymi do przelewu"
                                | DetailedState.PrepaymentDelayed ->
                                    str "Możliwie jak najszybciej wpłać zaliczkę zgodnie z poniższymi danymi do przelewu"
                                | DetailedState.Paid ->
                                    str "Wprowadzono pełną opłatę za pobyt. Dziękujemy."
                                | DetailedState.Prepaid ->
                                    str "Zaakceptowano wpłaconą zaliczkę. Pozostałą kwotę możesz przekazać właścicielowi w dniu przyjazdu"
                                | DetailedState.Cancelled ->
                                    str "W związku z odwołaniem rezerwacji nie musisz dokonywać żadnych opłat"
                                | DetailedState.Refused ->
                                    str "W związku z odrzuceniem rezerwacji przez właściciela obiektu nie musisz dokonywać żadnych opłat"
                                | _ ->
                                    str "Informacja na temat płatności"
                            | _ ->
                                str "Informacja na temat płatności"
                        ]
                    ]

                    div [ Class "box-body no-padding" ] [
                        paymentInfo model
                        bankAccountInfo model
                    ]
                ]

                div [ Class "box-header with-border" ] [
                    h3 [ Class "box-title" ] [
                        if model.Messages.IsEmpty then
                            str "Skontaktuj się z właścicielem obiektu, jeśli potrzebujesz dodatkowych uzgodnień"
                        else
                            str "Korespondencja z właścicielem obiektu"
                    ]
                ]

                div [ Class "box-body no-padding" ] [
                    messageEditor model dispatch
                ]

                div [ Class "box-footer" ] [
                    Controls.simpleButton [] "btn-default" " Wyślij" "fa fa-envelope-o" dispatch SendMessage
                    div [ Class "pull-right"] [
                        Controls.simpleButton [] "btn-default" " Zmień dane" "fa fa-edit" dispatch ModifyReservation
                        str " "
                        Controls.simpleButton [] "btn-default" " Odwołaj" "fa fa-ban" dispatch CancelReservation
                    ]                        
                ]
            ]
        ]

    let view (model : Model) (dispatch : Msg -> unit) =
        React.ofList [
            section [ Class "content-header" ] [
                h1 [] [
                    str "Rezerwacja"
                    span [ Class "pull-right" ] [
                        Controls.label (getStateColor model.DetailedState) (getStateName model.DetailedState)
                    ]
                ]                
            ]            
            section [ Class "content" ] [
                match model.ViewType with
                | ViewType.Editor   -> editorView model dispatch
                | ViewType.Summary  -> summaryView model dispatch
            ]
        ]

module RoomSelection =

    type Msg =
        | FromToChanged of DateTime * DateTime
        | DaysChanged of string
        | AdultsChanged of string
        | MultiRoomChanged of bool
        | ChildAgeMsg of ChildAgeEditor.Msg
        | AddToReservation of Room * (DateTime * DateTime) list
        | ShowRoomDetails of Room
        | ShowError of string

    type Selection =
        | Raw of List<Room * List<DateTime * DateTime>>
        | Priced of (Room * Derived<Decimal> * Derived<Decimal> * List<DateTime * DateTime>) list
        member this.IsEmpty =
            match this with
            | Raw list -> list.IsEmpty
            | Priced list -> list.IsEmpty

    type Model =
        {
            From            : DateTime
            FromErrors      : string list
            To              : DateTime
            ToErrors        : string list
            Days            : Derived<string>
            DaysErrors      : string list
            Adults          : string
            Children        : ChildAgeEditor.Model
            Resort          : Resort
            AllRooms        : Room list
            IsMultiRoom     : bool
            SelectedRooms   : Selection
            ManyAllocations : bool
            AllocatedAdults : int
            RemainingAdults : int option
        }
        static member Empty =
            {
                From            = DateTime.Today
                FromErrors      = []
                To              = DateTime.Today
                ToErrors        = []
                Days            = Calculated ""
                DaysErrors      = []
                Adults          = ""
                Children        = ChildAgeEditor.Model.Empty
                Resort          = Resort.Empty
                AllRooms        = []
                IsMultiRoom     = false
                SelectedRooms   = Raw []
                ManyAllocations = false
                AllocatedAdults = 0
                RemainingAdults = None
            }
        member this.RoomTypes =
            this.AllRooms |> List.map (fun r -> r.RoomType.PluralGenitive) |> List.distinct
        member this.UpdateWith(resort, rooms, isMultiRoom) =
            { this with
                Resort          = resort
                AllRooms        = rooms
                SelectedRooms   = Raw (rooms |> List.map (fun r -> r, []))
                IsMultiRoom     = isMultiRoom |> Option.defaultValue this.IsMultiRoom
            } 
        static member Recalc (model: Model) =
            let days = model.Days |> Derived.Recalc (Math.Round(model.To.Subtract(model.From).TotalDays).ToString())
            let daysI = Int32.Parse days.Value
            let errors = validateFilter (model.Resort, DateTime.Today, model.From, model.To, daysI)
            let adultsOpt = model.Adults |> Int32.TryParse |> Option.FromTryParse
            { model with
                Days = days
                SelectedRooms =
                    match adultsOpt with
                    | Some adults when model.From <> model.To && not model.IsMultiRoom ->
                        model.AllRooms
                        |> filterAndPriceRooms (model.Resort, model.From, model.To, daysI, adults, model.Children.Children) 
                        |> List.sortBy (fun (_, p, _, _) -> p)
                        |> Priced
                    | Some _
                    | None ->
                        model.AllRooms
                        |> filterRooms (model.Resort, model.From, model.To, daysI) 
                        |> List.sortBy (fun (r, _) -> r.LongTimePrice)
                        |> Raw
                FromErrors = errors |> Validation.getErrorsOf "From"
                ToErrors = errors |> Validation.getErrorsOf "To"
                DaysErrors = errors |> Validation.getErrorsOf "Days"
                RemainingAdults = adultsOpt |> Option.map (fun adults -> adults - model.AllocatedAdults)                    
            } 
        static member UpdateOccupancies (reservation: Reservation) (model: Model) =
            { model with AllRooms = model.AllRooms |> List.map (updateOccupancies reservation) }
                    

    let init(resort: Resort, rooms: Room list): Model * Cmd<Msg> =
        Model.Empty.UpdateWith(resort, rooms, None), Cmd.none

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | FromToChanged (dateFrom, dateTo) ->
            { model with From = dateFrom; To = dateTo} |> Model.Recalc, Cmd.none
        | DaysChanged days ->           
            { model with Days = Derived.OfString days } |> Model.Recalc, Cmd.none
        | AdultsChanged adults ->
            { model with Adults = adults } |> Model.Recalc, Cmd.none
        | MultiRoomChanged multi ->
            { model with IsMultiRoom = multi } |> Model.Recalc, Cmd.none
        | ChildAgeMsg msg ->
            let chmdl, chcmd = ChildAgeEditor.update msg model.Children
            { model with Children = chmdl } |> Model.Recalc, Cmd.map ChildAgeMsg chcmd

    let unallocatedPersonLabel count =
        if count = 0 then
            "Przydzielono zakwaterowanie dla wszystkich gości"
        elif count = 1 then
            "Pozostała jedna niezakwaterowana osoba"
        else
            match count % 10 with
            | 2 | 3 | 4 -> sprintf "Pozostały %d niezakwaterowane osoby" count
            | _ -> sprintf "Pozostało %d niezakwaterowanych osób" count

    let view (model : Model) (dispatch : Msg -> unit) =
        React.ofList [
            section [ Class "content-header" ] [
                h1 [] [
                    str model.Resort.Name
                ]                
            ]            
            section [ Class "content" ] [
                div [ Class "box box-info" ] [
                    div [ Class "box-header with-border" ] [
                        h3 [ Class "box-title" ] [ str "Określ termin i liczbę gości" ]                        
                    ]

                    form [ Class "form-horizontal"] [
                        div [ Class "box-body no-padding" ] [
                            Forms.formGroup false
                            <| Forms.dateRangePickerWithLabel "col-sm-1" "col-sm-3" "fromToFilter" "Termin"
                                (model.From, model.To)
                                (Forms.errorsBelow [])
                                dispatch FromToChanged
                             @ Forms.inputWithLabel "col-sm-1" "col-sm-1" "daysFilter" "Liczba dni" 3.0
                                (Derived.ToTag string "" model.Days)
                                (Forms.errorsBelow [])
                                (Event.str dispatch DaysChanged)
                             @ Forms.inputWithLabel "col-sm-1" "col-sm-1" "adultsFilter" "Dorośli" 3.0
                                (Value model.Adults)
                                (Forms.errorsBelow [])
                                (Event.str dispatch AdultsChanged)
                             @ Forms.controlWithLabel "col-sm-2" "col-sm-2" "childAgeEditor" "Dzieci (wiek)"                                                
                                (Forms.errorsBelow [])
                                (ChildAgeEditor.view model.Children (ChildAgeMsg >> dispatch))
                        ]
                    ]

                    div [ Class "box-body" ] [
                        Controls.checkbox "multiRoom"
                            [ if model.ManyAllocations then Disabled true ]
                            (sprintf "Wiele %s w jednej rezerwacji" (model.RoomTypes |> String.concat "/"))
                            model.IsMultiRoom dispatch MultiRoomChanged
                    ]

                    if model.IsMultiRoom then
                        div [ Class "box-body" ] [
                            b [] [ str (model.RemainingAdults |> Option.map unallocatedPersonLabel |> Option.defaultValue "") ]
                        ]

                    if not (List.isEmpty (model.FromErrors @ model.ToErrors @ model.DaysErrors)) then
                        div [ Class "box-body" ] [
                            for msg in model.FromErrors @ model.ToErrors @ model.DaysErrors do
                                div [Class "form-group has-error"] [
                                    label [ Class "control-label" ] [ str msg ]
                                ]
                        ]

                    div [Class "box-body" ] [
                        b [] [ str "Uwaga" ]
                        ul [] [
                            li [] [
                                str "Dostępność oznacza możliwość wynajęcia na podaną liczbę dni w podanym okresie, np. 2 dni w lipcu 2021 specyfikujemy jako Termin = 01.07.2021 - 31.07.2021, Liczba dni = 2"
                            ]
                            li [] [
                                str "W związku z mozliwością istnienia cenników sezonowych, wyliczone ceny mogą się zmieniać na przestrzeni kilku dni; w takim przypadku w tabelce pojawią się zakresy cen"
                            ]
                        ]
                    ]

                    if model.SelectedRooms.IsEmpty then

                        div [ Class "box-header with-border" ] [
                            h3 [ Class "box-title" ] [ str "Nie znaleziono wolnych terminów" ]                        
                        ]

                    else
                    
                        div [ Class "box-header with-border" ] [
                            h3 [ Class "box-title" ] [ str "Znaleziono" ]                        
                        ]

                        div [ Class "box-body no-padding" ] [
                            table [ Class "table" ] [
                                tbody [] [
                                    match model.SelectedRooms with
                                    | Raw rooms ->
                                        tr [] [
                                            th [] [ str "Nazwa" ]
                                            th [] [ str "Opis" ]
                                            th [] [ str "Max. liczba osób" ]
                                            th [] [ str "Cena os. za dobę" ]
                                            th [] [ str "Krótkoterm." ]
                                            th [] [ str "Dostępność" ]
                                            th [] []
                                        ]
                                        for rm, avail in rooms do
                                            tr [] [
                                                td [] [ str rm.Name ]
                                                td [] (Utils.getMultilineMarkup rm.ShortDescription)
                                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                                    str <| (rm.Capacity.ToString() + if rm.AdditionalCapacity <> 0 then " + " + rm.AdditionalCapacity.ToString() else "")
                                                ]
                                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Decimal.format 2 rm.LongTimePrice ]
                                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [ str <| Decimal.format 2 rm.ShortTimePrice ]
                                                td [] [
                                                    for df, dt in avail do
                                                        span [] [ str <| sprintf "%s - %s" (df.ToString("dd.MM.yyyy")) (dt.ToString("dd.MM.yyyy")) ]
                                                        br []
                                                ]
                                                td [ Style [ Width "50px" ] ] [
                                                    Controls.actionButton [ Title "Opis, zdjęcia" ] "fa fa-image" dispatch (ShowRoomDetails rm)
                                                    if model.IsMultiRoom && not (String.IsNullOrEmpty model.Adults) && model.From <> model.To then
                                                        str " "
                                                        Controls.actionButton [ Title "Rezerwuj" ] "fa fa-plus" dispatch (AddToReservation (rm, avail))
                                                ]

                                            ]

                                    | Priced rooms ->
                                        tr [] [
                                            th [] [ str "Nazwa" ]
                                            th [] [ str "Opis" ]
                                            th [] [ str "Cena" ]
                                            th [] [ str "Dostępność" ]
                                            th [] [ str "" ]
                                        ]
                                        for rm, minp, maxp, avail in rooms do
                                            tr [] [
                                                td [] [ str rm.Name ]
                                                td [] (Utils.getMultilineMarkup rm.ShortDescription)
                                                td [ Style [ TextAlign TextAlignOptions.Right ] ] [
                                                    if minp <> maxp then
                                                        str <| sprintf "%s - %s" (Derived.ToString minp) (Derived.ToString maxp)
                                                    else
                                                        str <| Derived.ToString minp
                                                ]
                                                td [] [
                                                    for df, dt in avail do
                                                        span [] [ str <| sprintf "%s - %s" (df.ToString("dd.MM.yyyy")) (dt.ToString("dd.MM.yyyy")) ]
                                                        br []
                                                ]
                                                td [ Style [ Width "50px" ] ] [
                                                    Controls.actionButton [ Title "Opis, zdjęcia" ] "fa fa-image" dispatch (ShowRoomDetails rm)
                                                    str " "
                                                    Controls.actionButton [ Title "Rezerwuj" ] "fa fa-plus" dispatch (AddToReservation (rm, avail))
                                                ]
                                            ]
                                ]

                            ]
                        ]
                ]
            ]
        ]

module Content =

    type LoadType =
        | Initial
        | Reload

    type Msg =
        | ReservationLoaded of (Reservation * Resort * Room list * BankAccount * OrdererData option) * LoadType
        | RoomLoaded of Room * Resort * Room list * BankAccount * OrdererData option
        | ResortLoaded of Resort * Room list * BankAccount * OrdererData option
        | ShowReservation
        | ShowRooms
        | ReservationMsg of ReservationDetails.Msg
        | RoomSelectionMsg of RoomSelection.Msg
        | RoomDetailsMsg of RoomDetails.Msg
        | ReloadReservation
        | ShowError of string * ErrorInfo
        | ClearError

    [<RequireQualifiedAccess>]
    type ViewType =
        | Reservation
        | Rooms
        | RoomDetails

    type Model =
        {
            ReservationParams   : ReservationParams
            ViewType            : ViewType
            ReservationModel    : ReservationDetails.Model
            RoomSelectionModel  : RoomSelection.Model
            RoomDetailsModel    : RoomDetails.Model
            ErrorMessage        : string
        }
        static member FromParams p =
            {
                ReservationParams   = p
                ViewType            = if p.IdentifiesRoom then ViewType.Reservation else ViewType.Rooms
                ReservationModel    = ReservationDetails.Model.Initial
                                        (if p.IdentifiesReservation then
                                            ReservationDetails.Summary, Guid.Empty
                                         else
                                            ReservationDetails.Editor, Guid.NewGuid())
                RoomSelectionModel  = RoomSelection.Model.Empty
                RoomDetailsModel    = RoomDetails.Model.Empty
                ErrorMessage        = ""
            }
        static member UpdateSelection (model: Model) =
            try
                let reservation = model.ReservationModel |> ReservationDetails.Model.ToReservation
                { model with
                    RoomSelectionModel =
                        { model.RoomSelectionModel with
                            AllocatedAdults =
                                reservation.Allocations
                                |> List.map (fun a -> a.Adults |> Derived.DefaultValue 0)
                                |> List.sum
                            ManyAllocations = reservation.Allocations.Length > 1
                        }
                        |> RoomSelection.Model.UpdateOccupancies reservation
                        |> RoomSelection.Model.Recalc
                    ReservationModel =
                        { model.ReservationModel with IsMultiRoom = model.RoomSelectionModel.IsMultiRoom }
                        |> ReservationDetails.Model.UpdateOccupancies reservation
                }
            with ex ->
                JS.console.error(sprintf "Ignored intentionally to allow room selection: %A" ex)
                model

    let getReservationCmd (reservationId, secret, loadType) =
        Cmd.OfAsync.either id
            (reservationApi.GetReservation (reservationId, secret))
            ((fun r -> r, loadType) >> ReservationLoaded)
            (ErrorHandling.unpack ShowError "Nie udało się wczytać danych rezerwacji.")

    let getRoomCmd roomId =
        Cmd.OfAsync.either id (reservationApi.GetRoom roomId) RoomLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać danych pokoju.")

    let getResortCmd resortId =
        Cmd.OfAsync.either id (reservationApi.GetResort resortId) ResortLoaded (ErrorHandling.unpack ShowError "Nie udało się wczytać danych obiektu.")

    let getResortByPartnerKeyCmd (partner, key) =
        Cmd.OfAsync.either id (reservationApi.GetResortByPartnerKey (partner, key)) ResortLoaded (ErrorHandling.unpack ShowError (sprintf "Nie udało się odczytać przyporządkowania do ośrodka z '%s'." partner))

    let init(p: ReservationParams): Model * Cmd<Msg> =
        Model.FromParams p,
        match p with
        | ReservationId (id, secret) -> getReservationCmd(id, secret, Initial)
        | RoomId id -> getRoomCmd id
        | ResortId id -> getResortCmd id
        | PartnerKey (partner, key) -> getResortByPartnerKeyCmd (partner, key)

    let mapReservationMsg = function ReservationDetails.ShowError (m, e) -> ShowError (m, e) | m -> ReservationMsg m

    let mapRoomDetailsMsg = function RoomDetails.ShowError (m, e) -> ShowError (m, e) | m -> RoomDetailsMsg m

    let addAllocation (model: Model, room: Room, availabilities: (DateTime * DateTime) list) =
        let dateRangeAssigned = model.ReservationModel.To.Subtract(model.ReservationModel.From).TotalDays <> 0.0
        let rsFrom = availabilities |> List.head |> fst
        let rsTo = rsFrom.AddDays (Double.Parse model.RoomSelectionModel.Days.Value)
        let adults = Math.Min(room.Capacity, model.RoomSelectionModel.RemainingAdults |> Option.defaultValue room.Capacity)
        { model with
            ReservationModel = {
                model.ReservationModel with
                    From        = if not dateRangeAssigned then rsFrom else model.ReservationModel.From
                    To          = if not dateRangeAssigned then rsTo else model.ReservationModel.To
                    Allocations =
                        if model.RoomSelectionModel.IsMultiRoom then
                            model.ReservationModel.Allocations @
                            [ { AllocationEditor.Model.Empty with
                                    Resort      = model.RoomSelectionModel.Resort
                                    Room        = room
                                    Adults      = Calculated (string adults)
                                    Children    = ChildAgeEditor.Model.Empty
                            } ]
                        else
                            [ { AllocationEditor.Model.Empty with
                                    Resort      = model.RoomSelectionModel.Resort
                                    Room        = room
                                    Adults      = Calculated model.RoomSelectionModel.Adults
                                    Children    = model.RoomSelectionModel.Children
                            } ]
            } |> ReservationDetails.Model.Recalc
        }

    let update (msg: Msg) (model: Model): Model * Cmd<Msg> =
        match msg with
        | ReservationLoaded ((reservation, resort, allRooms, bankAccount, ordererData), loadType) ->
            let secret =
                match model.ReservationParams with
                | ReservationId (_, secret) -> Some secret
                | _ -> None
            let allCalcRooms = allRooms |> List.map (recalcRoom resort)
            match loadType with
            | Initial ->
                { model with
                    ReservationModel    =
                        model.ReservationModel.UpdateWith (Some reservation, None, resort, allCalcRooms, bankAccount, ordererData, secret)
                        |> ReservationDetails.Model.Recalc
                    RoomSelectionModel  =
                        model.RoomSelectionModel.UpdateWith(resort, allCalcRooms, Some (reservation.Allocations.Length > 1))
                        |> RoomSelection.Model.Recalc
                } |> Model.UpdateSelection, Cmd.none
            | Reload ->
                { model with
                    ReservationModel    =
                        model.ReservationModel.RefreshUpdateWith (Some reservation, None, resort, allCalcRooms, bankAccount, ordererData, secret)
                        |> ReservationDetails.Model.Recalc
                    RoomSelectionModel  =
                        model.RoomSelectionModel.UpdateWith(resort, allCalcRooms, None)
                        |> RoomSelection.Model.Recalc
                } |> Model.UpdateSelection, Cmd.none
        | RoomLoaded (room, resort, allRooms, bankAccount, ordererData) ->
            let calcRoom = room |> recalcRoom resort
            let allCalcRooms = allRooms |> List.map (recalcRoom resort)
            { model with
                ReservationModel    = model.ReservationModel.RefreshUpdateWith (None, Some calcRoom, resort, allCalcRooms, bankAccount, ordererData, None)
                RoomSelectionModel  = model.RoomSelectionModel.UpdateWith (resort, allCalcRooms, None) |> RoomSelection.Model.Recalc
            } |> Model.UpdateSelection, Cmd.none
        | ResortLoaded (resort, allRooms, bankAccount, ordererData) ->
            let allCalcRooms = allRooms |> List.map (recalcRoom resort)
            { model with
                ReservationModel    = model.ReservationModel.RefreshUpdateWith (None, None, resort, allCalcRooms, bankAccount, ordererData, None)
                RoomSelectionModel  = model.RoomSelectionModel.UpdateWith (resort, allCalcRooms, None) |> RoomSelection.Model.Recalc
            } |> Model.UpdateSelection, Cmd.none
        | ShowReservation ->
            { model with ViewType = ViewType.Reservation }, Cmd.none
        | ShowRooms ->
            { model with ViewType = ViewType.Rooms }, Cmd.none
        | RoomSelectionMsg (RoomSelection.Msg.ShowRoomDetails room) ->
            let dmdl, dcmd = RoomDetails.init (model.RoomSelectionModel.Resort, room)
            { model with
                ViewType = ViewType.RoomDetails
                RoomDetailsModel = dmdl
            }, Cmd.map mapRoomDetailsMsg dcmd
        | ReloadReservation ->
            match model.ReservationParams with
            | ReservationId (id, secret) -> model, getReservationCmd (id, secret, Reload)
            | RoomId id -> model, getRoomCmd id
            | ResortId id -> model, getResortCmd id
            | PartnerKey (partner, key) -> model, getResortByPartnerKeyCmd (partner, key)
        | ReservationMsg ReservationDetails.Msg.ReturnToRoomSelection ->
            { model with ViewType = ViewType.Rooms }, Cmd.none
        | ReservationMsg msg ->
            let rmdl, rcmd = ReservationDetails.update msg model.ReservationModel
            { model with ReservationModel = rmdl } |> Model.UpdateSelection, Cmd.map mapReservationMsg rcmd
        | RoomSelectionMsg (RoomSelection.Msg.AddToReservation (room, availabilities)) ->
            addAllocation (model, room, availabilities) |> Model.UpdateSelection, Cmd.ofMsg ShowReservation
        | RoomSelectionMsg msg ->
            let smdl, scmd = RoomSelection.update msg model.RoomSelectionModel
            { model with RoomSelectionModel = smdl } |> Model.UpdateSelection, Cmd.map RoomSelectionMsg scmd
        | RoomDetailsMsg RoomDetails.Close ->
            { model with ViewType = ViewType.Rooms }, Cmd.none
        | RoomDetailsMsg msg ->
            let dmdl, dcmd = RoomDetails.update msg model.RoomDetailsModel
            { model with RoomDetailsModel = dmdl }, Cmd.map mapRoomDetailsMsg dcmd
        | ShowError (m, e) ->
            match e with
            | { errorType = ErrorType.Other; errorMessage = msg } ->
                { model with ErrorMessage = sprintf "%s %s" m msg }, Cmd.none
            | { errorType = ErrorType.SessionExpired } ->
#if DEBUG
                Browser.Dom.window.location.href <- "/owner-auth"                 
#else
                Browser.Dom.window.location.reload()                
#endif
                { model with ErrorMessage = sprintf "%s Sesja wygasła. Za chwilę nastąpi przekierowanie do strony logowania." m }, Cmd.none

        | ClearError ->
            { model with ErrorMessage = "" }, Cmd.none

    let view (model : Model) (dispatch : Msg -> unit) =
        div [ Class "content-wrapper" ] [        
                div [
                    yield Class "alert alert-danger alert-dismissible" 
                    if System.String.IsNullOrEmpty model.ErrorMessage then
                        yield Style [ Display DisplayOptions.None ]
                    yield Role "alert"
                ] [
                    button [ Type "button"; Class "close"; OnClick (Event.none dispatch ClearError) ] [ str "x" ]
                    h4 [] [
                        i [ Class "icon fa fa-ban" ] []
                        str "UWAGA!"
                    ]
                    str model.ErrorMessage
                ]
                match model.ViewType with
                | ViewType.Reservation ->
                    ReservationDetails.view model.ReservationModel (ReservationMsg >> dispatch)
                | ViewType.Rooms ->
                    RoomSelection.view model.RoomSelectionModel (RoomSelectionMsg >> dispatch)
                | ViewType.RoomDetails ->
                    RoomDetails.view model.RoomDetailsModel (RoomDetailsMsg >> dispatch)
        ]

    let subscribe _ =
        let sub dispatch =
            Browser.Dom.window.setInterval (
                (fun _ ->
                    if Browser.Dom.document.getElementById("prevent-subscriptions") = null then
                        dispatch ReloadReservation
                ),
                1000*60) |> ignore
        Cmd.ofSub sub

type Msg =
    | HeaderMsg of Header.Msg
    | SidebarMsg of Sidebar.Msg
    | ContentMsg of Content.Msg
    | ShowError of string * ErrorInfo
    
type Model =
    {
        Header  : Header.Model
        Sidebar : Sidebar.Model
        Content : Content.Model
    }
    
let mapHeaderMsg = function Header.ShowError (m, e) -> ShowError (m, e) | m -> HeaderMsg m
let mapSidebarMsg = function Sidebar.ShowError (m, e) -> ShowError (m, e) | m -> SidebarMsg m
    
let init(p: ReservationParams): Model * Cmd<Msg> =
    let mhModel, mhCmd = Header.init ()
    let sbModel, sbCmd = Sidebar.init p
    let ctModel, ctCmd = Content.init p
    {
        Header = mhModel
        Sidebar = sbModel
        Content = ctModel
    }, Cmd.batch [ Cmd.map mapHeaderMsg mhCmd; Cmd.map mapSidebarMsg sbCmd; Cmd.map ContentMsg ctCmd ]


let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | ShowError (m, e) ->
        model, Cmd.ofMsg (ContentMsg (Content.ShowError (m, e)))
    | HeaderMsg mhMsg ->
        let mhModel, mhCmd = Header.update mhMsg model.Header
        { model with Header = mhModel }, Cmd.map mapHeaderMsg mhCmd
    | SidebarMsg sbMsg ->
        let ctModel, ctCmd =
            match sbMsg with
            | Sidebar.ShowReservation ->
                Content.update Content.ShowReservation model.Content
            | Sidebar.ShowRooms ->
                Content.update Content.ShowRooms model.Content
            | _ -> model.Content, Cmd.none
        let sbModel, sbCmd = Sidebar.update sbMsg model.Sidebar
        { model with Sidebar = sbModel; Content = ctModel },
        Cmd.batch [
            Cmd.map mapSidebarMsg sbCmd
            Cmd.map ContentMsg ctCmd
        ]
    | ContentMsg cnMsg ->
        let mdl, cmd = 
            if cnMsg = Content.ShowReservation then
                let sbMdl, sbMsg = Sidebar.update Sidebar.ShowReservation model.Sidebar
                { model with Sidebar = sbMdl }, Cmd.map SidebarMsg sbMsg
            else
                model, Cmd.none
        let cnModel, cnCmd = Content.update cnMsg model.Content
        { mdl with Content = cnModel }, Cmd.batch [ Cmd.map ContentMsg cnCmd; cmd ]

let footer =
    footer [ Class "main-footer" ] [
        div [Class "pull-right hidden-xs" ] [
            b [] [ str "Wersja" ]; str " 1.0.0"
        ]
        strong [] [ str "Copyright "; i [ Class "fa fa-copyright" ] []; str " 2021 Helix " ]        
        str " Wszystkie prawa zastrzeżone."
    ]
    
let view (model : Model) (dispatch : Msg -> unit) =
    React.ofList [
        Header.view model.Header (HeaderMsg >> dispatch)
        Sidebar.view model.Sidebar (SidebarMsg >> dispatch)
        Content.view model.Content (ContentMsg >> dispatch)
        footer
    ]

let subscribe m =
    Cmd.map ContentMsg (Content.subscribe m)
