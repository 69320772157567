module Pages

open Elmish.UrlParser
open Elmish

open Commons
open Fable.Core
open System


[<RequireQualifiedAccess>]
type Page =
    | Home
    | OwnerPanel of OwnerPanel.Parameters
    | MakeReservation of MakeReservation.ReservationParams
    | NotFound
    | Error500
    | OwnerAuthentication of Authentication.AuthParams option
    | OrdererAuthentication of Authentication.AuthParams option
    static member FromReservationParams id uid roomId resortId partner key =
        Page.MakeReservation
        <|  match id, uid, roomId, resortId, partner, key with
            | Some id, Some uid, _, _, _, _ -> MakeReservation.ReservationId (id, uid)
            | Some _, None, _, _, _, _ -> failwith "Reservation id must be always specified with uid"
            | None, _, Some id, _, _, _ -> MakeReservation.RoomId id
            | None, _, None, Some id, _, _ -> MakeReservation.ResortId id
            | None, _, None, None, Some partner, Some key -> MakeReservation.PartnerKey (partner, key)
            | None, _, None, None, Some _, None -> failwith "Partner reference must be always specified with key"
            | None, _, None, None, None, _ -> failwith "Either reservation id or room id or at least resort id or partner reference should be provided."

    static member FromAuthenticationParams basePage returnUrl activation passwordReset  = 
        basePage
        <|  match returnUrl, activation, passwordReset with
            | Some url, _, _ -> Some (Authentication.AuthParams.FullReturnUrl url)
            | None, Some code, None -> Some (Authentication.AuthParams.Activation code)
            | None, None, Some code -> Some (Authentication.AuthParams.PasswordReset code)
            | None, Some _, Some _ -> failwith "Either activation or password reset code can be used, but not both of them."
            | None, None, None -> None
    static member FromOwnerPanelParams reservationId partner key =
        Page.OwnerPanel
        <|  match reservationId, partner, key with
            | Some reservationId, _, _ -> OwnerPanel.ReservationId reservationId
            | _, Some partner, Some key -> OwnerPanel.PartnerKey (partner, key)
            | None, None, None -> OwnerPanel.Empty
            | _ -> failwith "Inconsistent parameter list"

type Model =
    | HomeModel of SidebarCommons.Model
    | OwnerPanelModel of OwnerPanel.Model
    | MakeReservationModel of MakeReservation.Model
    | NotFoundModel
    | Error500Model
    | OwnerAuthenticationModel of Authentication.Model
    | OrdererAuthenticationModel of Authentication.Model    


type Msg =
    | HomeMsg of SidebarCommons.Msg
    | OwnerPanelMsg of OwnerPanel.Msg
    | MakeReservationMsg of MakeReservation.Msg
    | AuthenticationMsg of Authentication.Msg

let toPath =
    function
    | Page.Home -> "/"
    | Page.MakeReservation _ -> "make-reservation"
    | Page.OwnerAuthentication _ -> "/owner-auth"
    | Page.OwnerPanel _ -> "/owner-panel"
    | Page.OrdererAuthentication _ -> "/orderer-auth"
    | Page.NotFound -> "/400.html"
    | Page.Error500 -> "/500.html"

/// The URL is turned into a Result.
let pageParser : Parser<Page -> Page,_> =
    oneOf
        [ map Page.Home (s "")
          map Page.Home (s "index.html")
          map Page.FromOwnerPanelParams (s "owner-panel" <?> intParam "reservation" <?> stringParam "partner" <?> stringParam "key")
          map Page.FromReservationParams (s "make-reservation" <?> intParam "id" <?> customParam "uid" (Option.map Guid.Parse) <?> intParam "room" <?> intParam "resort" <?> stringParam "partner" <?> stringParam "key")
          map (Page.FromAuthenticationParams Page.OwnerAuthentication) (s "owner-auth" <?> stringParam "ReturnUrl" <?> customParam "activate" (Option.map Guid.Parse) <?> customParam "reset-password" (Option.map Guid.Parse)) 
          map (Page.FromAuthenticationParams Page.OrdererAuthentication) (s "orderer-auth" <?> stringParam "ReturnUrl" <?> customParam "activate" (Option.map Guid.Parse) <?> customParam "reset-password" (Option.map Guid.Parse))
          map Page.NotFound (s "400.html")
          map Page.Error500 (s "500.html")
        ]

let urlParser location = parsePath pageParser location

let urlUpdate (result: Page option) (model: Model) =
    match result with
    | None
    
    | Some Page.Home ->
        let sbMdl, sbCmd = HomePage.init()
        HomeModel sbMdl, Cmd.map HomeMsg sbCmd

    | Some Page.NotFound ->
        NotFoundModel, Cmd.none

    | Some (Page.OwnerAuthentication activationCode) ->
        let m, cmd = Authentication.ownerInit activationCode
        OwnerAuthenticationModel m, Cmd.map AuthenticationMsg cmd

    | Some (Page.OrdererAuthentication activationCode) ->
        let m, cmd = Authentication.ordererInit activationCode
        OrdererAuthenticationModel m, Cmd.map AuthenticationMsg cmd

    | Some (Page.OwnerPanel parameters)->
        let subModel, cmd = OwnerPanel.init parameters
        OwnerPanelModel subModel, Cmd.map OwnerPanelMsg cmd

    | Some (Page.MakeReservation p) ->
        let submodel, cmd = MakeReservation.init p
        MakeReservationModel submodel, Cmd.map MakeReservationMsg cmd

    | Some Page.Error500 ->
        Error500Model, Cmd.none

let init page =
    let model, _ = OwnerPanel.init OwnerPanel.Parameters.Empty
    urlUpdate page (OwnerPanelModel model)

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | OwnerPanelMsg submsg, OwnerPanelModel submodel ->
        let newModel, newCmd = OwnerPanel.update submsg submodel
        OwnerPanelModel newModel, Cmd.map OwnerPanelMsg newCmd
    | OwnerPanelMsg _, _ 
    | _, OwnerPanelModel _ ->
        failwithf "Pages.update failed: msg = %A, model = %A" msg model
        model, Cmd.none
    | MakeReservationMsg submsg, MakeReservationModel submodel ->
        let newModel, newCmd = MakeReservation.update submsg submodel
        MakeReservationModel newModel, Cmd.map MakeReservationMsg newCmd
    | AuthenticationMsg submsg, OwnerAuthenticationModel submodel ->
        let newModel, newCmd = Authentication.ownerUpdate submsg submodel
        OwnerAuthenticationModel newModel, Cmd.map AuthenticationMsg newCmd
    | AuthenticationMsg submsg, OrdererAuthenticationModel submodel ->
        let newModel, newCmd = Authentication.ordererUpdate submsg submodel
        OrdererAuthenticationModel newModel, Cmd.map AuthenticationMsg newCmd
    | HomeMsg msg, HomeModel mdl ->
        let sbMdl, sbCmd = HomePage.update msg mdl
        HomeModel sbMdl, Cmd.map HomeMsg sbCmd
    | _, HomeModel _ 
    | _, NotFoundModel
    | _, Error500Model ->
        model, Cmd.none


let view model dispatch =
    match model with
    | HomeModel m ->
        HomePage.view m (HomeMsg >> dispatch)
    | NotFoundModel 
    | Error500Model ->
        React.ofList []
    | MakeReservationModel model ->
        MakeReservation.view model (MakeReservationMsg >> dispatch)
    | OwnerAuthenticationModel submodel ->
        Authentication.view submodel (AuthenticationMsg >> dispatch)
    | OrdererAuthenticationModel submodel ->
        Authentication.view submodel (AuthenticationMsg >> dispatch)
    | OwnerPanelModel submodel ->
        OwnerPanel.view submodel (OwnerPanelMsg >> dispatch)

let subscribe model =
    match  model with
    | OwnerPanelModel model ->
        Cmd.map OwnerPanelMsg (OwnerPanel.subscribe model)
    | MakeReservationModel model ->
        Cmd.map MakeReservationMsg (MakeReservation.subscribe model)
    | _ ->
        Cmd.none